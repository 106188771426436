import React from 'react';
import { TextOnly } from './Text';

interface SignupLinkLedProps {
  linkState: string;
  linkType: string;
  startDate: Date;
  endDate: Date;
}

export const SignupLinkLed = ({
  linkState,
  linkType,
  startDate,
  endDate,
}: SignupLinkLedProps) => {
  const signupLinkState = getState(linkState, linkType, startDate, endDate);

  return (
    // @ts-ignore
    <span className="c-status-led" title={`${TextOnly(linkType) || ''}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`c-status-led__light c-status-led__light--${signupLinkState.ledState}`}
      >
        <circle cx="8" cy="8" r="8" />
      </svg>
      <span className="c-status-led__label">{signupLinkState.state}</span>
    </span>
  );
};

const LED_STATES = {
  active: 'active',
  error: 'error',
  inactive: 'inactive',
  warning: 'warning',
};

function getState(
  linkState: string,
  linkType: string,
  startDate: Date,
  endDate: Date
) {
  let signupLinkState = {
    state: '',
    ledState: '',
  };

  startDate = new Date(startDate);
  endDate = new Date(endDate);

  if (linkState === 'ACTIVE') {
    if (linkType === 'STANDARD') {
      signupLinkState.state = 'ACTIVE';
      signupLinkState.ledState = LED_STATES.active;
    } else {
      let currentDate = new Date();
      let timeTilStart = currentDate.getTime() - startDate.getTime();
      let timeTilEnd = currentDate.getTime() - endDate.getTime();

      if (timeTilStart < 0) {
        signupLinkState.state = TextOnly('PENDING');
        signupLinkState.ledState = LED_STATES.warning;
      } else if (timeTilStart > 0 && timeTilEnd < 0) {
        signupLinkState.state = TextOnly('ACTIVE');
        signupLinkState.ledState = LED_STATES.active;
      } else {
        signupLinkState.state = TextOnly('INACTIVE');
        signupLinkState.ledState = LED_STATES.error;
      }
    }
  } else {
    signupLinkState.state = TextOnly('INACTIVE');
    signupLinkState.ledState = LED_STATES.inactive;
  }

  return signupLinkState;
}
