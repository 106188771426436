import React, { useState } from "react";
import { LangDictKey, Text, TextOnly } from "../components/Text";
import { Dialog } from "@reach/dialog";
import Header from "../components/Header";
import { ChildProps } from "../types";

import D1PromoImage from "../assets/images/ownerVerificationPromo.png";
import logoPlus from "../assets/images/aa-logo-plus.svg";

export default function VehicleOwnerVerificationPromo(props: ChildProps) {
  const [modalImage, setModalImage] = useState("");
  const [modalTitle, setModalTitle] = useState("vehicleOwnerVerification" as LangDictKey);

  function handleImageClick(
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) {
    let target = event?.target as Element;

    setModalImage(D1PromoImage);
    setModalTitle("vehicleOwnerVerification" as LangDictKey);
  }

  function handleRouteToPlus() {
    props.history.push(`/upgradeToPlus/comparePlans`);
  }

  return (
    <>
      {props.isAuthenticated && (
        <>
          <Header title={TextOnly('vehicleOwnerVerification')} />

          <div className="l-full-width">
            <div className="c-box c-box--promo-plus">
              <h2 className="c-box__title">
                <Text tid="upgradeToStandardPlus" />
              </h2>
              <div className="l-flex-wrap u-margin-none">
                <p className="u-margin-none">
                  <Text tid="upgradeToStandardPlusD1Description" />
                </p>
                <button
                  className="c-box__cta-btn c-btn"
                  onClick={handleRouteToPlus}
                >
                  <Text tid="upgradeNow" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="l-view-layout__promo u-margin-top-xlarge">
        <div className="l-container-sm u-margin-none">
          <div className="c-card u-margin-right-none">
            <div className="c-logo u-margin-top-large">
              <img src={logoPlus} className="c-logo__image" alt="logo" />
            </div>
            <h2 className="c-card__title u-padding-top-large">
              <Text tid="upgradeForOwnerVerification" />
            </h2>
          </div>
        </div>
        <div className="l-container-med-plus promo-image-shadow u-margin-none">
          <img
            id="vehicleOwnerVerification"
            className="promo-image"
            src={D1PromoImage}
            onClick={handleImageClick}
            alt={TextOnly("shopActions")}
          />
        </div>
      </div>
    </>
  );
}
