import React, { useEffect, useRef, useState } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Loading } from '../../../components/Loading';
import { ORDER_STATUS_OPTIONS } from '../CrmConstants';
import AddEditCustomerForm from '../ManageCustomers/AddEditCustomerForm';
import AddEditVehicleForm from '../ManageVehicles/AddEditVehicleForm';
import { crmCreateOrder, crmUpdateOrder } from '../../../libs/db-lib';
import AddEditEventForm from '../Calendar/AddEditEventForm';
import { convertOrderNumToDisplay, formatOrderRelatedEvents } from '../../../libs/utils';
import { useScreenSize } from '../../../context/ScreenSize';
import ConfirmReadyForPickUpNotification from '../../../components/ConfirmReadyForPickUpNotification/ConfirmReadyForPickUpNotification';
import ToolTip from '../../../components/ToolTip';

const AddEditOrderForm: React.FC<any> = (props) => {

  const {
    currentShop,
    shopCustomers,
    handleCloseModal,
    isLoading,
    orderData,
    onSubmit,
    customerID,
    setNewOrderInfo,
    isSingleCustomer,
    user,
    setMissingAttOrderForm,
  } = props;

  const techTypeahead = useRef<any>();
  const customerTypeahead = useRef<any>();
  const vehicleTypeahead = useRef<any>();

  const { windowWidth } = useScreenSize();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const [orderTitle, setOrderTitle] = useState<string>(orderData?.title ?? '');
  const [orderStatus, setOrderStatus] = useState<string>(orderData?.status ?? ORDER_STATUS_OPTIONS[0].value);
  const [statusColor, setStatusColor] = useState<string>('');
  const [description, setDescription] = useState<string>(orderData?.description ?? '');
  const [newCustomerInfo, setNewCustomerInfo] = useState<any>(null);
  const [newVehicleInfo, setNewVehicleInfo] = useState<any>(null);
  const [newEventInfo, setNewEventInfo] = useState<any>(null);
  const [isAddingEvent, setIsAddingEvent] = useState<boolean>(false);
  const [showConfirmNotificationModal, setShowConfirmNotificationModal] = useState<boolean>(false);
  const [sendNotification, setSendNotification] = useState<boolean>(false);

  const [shopTechnicians, setShopTechnicians] = useState<any[]>([]);
  const [customerVehicles, setCustomerVehicles] = useState<any[]>([]);

  const customersList = shopCustomers?.map((cust: any) => {
    const currentCust = {
      id: cust.customerID,
      label: `${cust.firstName} ${cust.lastName}`,
      vehicles: cust?.vehicles || []
    }
    return currentCust;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    // sorting alphabetical order by first name
    return (
      labelA < labelB ? -1
      : labelA > labelB ? 1
      : 0
    );
  });
  customersList?.unshift({
    id: 'newCustomer',
    label: `+ ${TextOnly('createNewCustomer')}`
  });


  const displayEvents: any[] = formatOrderRelatedEvents(orderData?.events);

  const [selectedCustomer, setSelectedCustomer] = useState<any>(
    orderData?.customerID ?
      [
        customersList.find((cust: any) => {
          return cust.id === orderData.customerID
        })
      ]
    : customerID ?
    [
      customersList.find((cust: any) => {
        return cust.id === customerID
      })
    ]
    : []);

  const [selectedVehicle, setSelectedVehicle] = useState<any>(
    orderData?.yearMakeModel ?
      [{
        id: orderData.vehicleID,
        label: orderData.yearMakeModel
      }]
    : []
  );
  const [selectedTechnician, setSelectedTechnician] = useState<any>(
      orderData?.tech
      ? orderData?.technicianID
      : null);

  const [customerInputValue, setCustomerInputValue] = useState<string>('');
  const [vehicleInputValue, setVehicleInputValue] = useState<string>('');
  const [techInputValue, setTechInputValue] = useState<string>('');

  const [isCustTypeaheadOpen, setIsCustTypeaheadOpen] = useState<boolean>(false);
  const [isVehicleTypeaheadOpen, setIsVehicleTypeaheadOpen] = useState<boolean>(false);
  const [isTechTypeaheadOpen, setIsTechTypeaheadOpen] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [missingAttCustomerForm, setMissingAttCustomerForm] = useState<string[]>([]);
  const [missingAttVehicleForm, setMissingAttVehicleForm] = useState<string[]>([]);

  const validateSubmit = () => {
    const hasRequiredCustomerFields = selectedCustomer[0]?.id === 'newCustomer' ? newCustomerInfo?.isValidated
      : !!selectedCustomer[0]?.id;
    const hasRequiredVehicleFields = selectedVehicle[0]?.id === 'newVehicle' ? newVehicleInfo?.isValidated
      : !!selectedVehicle[0]?.id;
    const hasRequiredEventFields = !!Object.keys(newEventInfo ?? {});

    // Making sure all required values are valid
    if (orderStatus &&
        hasRequiredCustomerFields &&
        hasRequiredVehicleFields &&
        hasRequiredEventFields &&
        // If this component is within create event, no order title is needed
        (setNewOrderInfo || orderTitle.trim().length)
      ) {
      const techNotChanged = orderData?.technicianID?.length
        ? orderData.technicianID === selectedTechnician
        : !selectedTechnician?.length
      // Making sure that at least one attribute changed
      if (
        orderData?.title === orderTitle &&
        orderData?.status === orderStatus &&
        orderData?.description === description &&
        orderData?.customerID === selectedCustomer[0]?.id &&
        orderData?.vehicleID === selectedVehicle[0]?.id &&
        techNotChanged &&
        !isAddingEvent
      ) {
        // Nothing has changed
        return false;
      } else {
        return true;
      }
    } else {
      //At least one value is not valid
      return false;
    }
  };

  const handleCloseConfirmNotification = async () => {
    document.querySelector('.c-modal.send-notification-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowConfirmNotificationModal(false);
    }, 350);
  };

  const handleSubmit = async () => {
    setIsLoadingSubmit(true);
    const response: any = !orderData
      ? await crmCreateOrder(
          currentShop.shopID,
          orderTitle,
          description,
          orderStatus,
          newCustomerInfo ?? { customerID: selectedCustomer[0].id },
          newVehicleInfo ?? { vehicleID: selectedVehicle[0].id },
          selectedTechnician ?? '',
          newEventInfo,
        )
      : await crmUpdateOrder(
          currentShop.shopID,
          orderTitle,
          description,
          orderStatus,
          newCustomerInfo ?? { customerID: selectedCustomer[0].id },
          newVehicleInfo ?? { vehicleID: selectedVehicle[0].id },
          selectedTechnician ?? '',
          orderData.orderID,
          newEventInfo,
          sendNotification,
        );
    setIsLoadingSubmit(false);
    if (!response.error) {
      toast.success(TextOnly(!orderData ? 'newOrderCreated' : 'orderUpdated'), { containerId: 'standard' });
      handleCloseModal();
      onSubmit();
    } else {
      toast.error(`${TextOnly('error')}: ${response.message ?? response.error}`, { containerId: 'standard' });
    }
  };

  const getShopTechnicians = () => {
    const techs = currentShop.shopUsers
    ?.filter((tech: any) => tech.shopUserState === 'ACTIVE')
    .map((technicianAux: any)=>{
      const technician: { id: string, label: string } = {
        id: technicianAux.userID,
        label: `${technicianAux?.firstName} ${technicianAux?.lastName} (${technicianAux?.userName})`,
      };
      return technician;
    }).sort((a: any, b: any) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();
      // sorting alphabetical order by first name
      return (
        labelA < labelB ? -1
        : labelA > labelB ? 1
        : 0
      );
    });
    setShopTechnicians(techs);
  }

  const getCustomerVehicleList = () => {
    if (selectedCustomer[0]?.vehicles) {
      const vehicles = selectedCustomer[0].vehicles?.map((vehicleAux: any) => {
        const vehicle: { id: string, label: string } = {
          id: vehicleAux.vehicleID,
          label: vehicleAux.licensePlate
            ? `${vehicleAux.year} ${vehicleAux.make} ${vehicleAux.model} (${vehicleAux.licensePlate})`
            : `${vehicleAux.year} ${vehicleAux.make} ${vehicleAux.model}`
        };
        return vehicle;
      }).sort((a: any, b: any) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        // sorting descending order by year
        return (
          labelA > labelB ? -1
          : labelA < labelB ? 1
          : 0
        );
      });
      vehicles.unshift({
        id: 'newVehicle',
        label: `+ ${TextOnly('createNewVehicle')}`
      });
      setCustomerVehicles(vehicles);
    }
  }

  const closeAddingEvent = () => {
    setIsAddingEvent(false);
    setNewEventInfo(null);
  }

  useEffect(()=>{
    const fetchAndSetData = async() => {
      setIsLoadingData(true);
      await Promise.all([ //wait for all functions to be completed
        getShopTechnicians(),
        getCustomerVehicleList(),
      ]); // Run functions concurrently
      setIsLoadingData(false);
    }
    fetchAndSetData();
  }, []);

  useEffect(() => {
    const colorIndex: number = ORDER_STATUS_OPTIONS.findIndex((status: any) => {
      return status.value === orderStatus;
    });
    setStatusColor(`status-color-${colorIndex}`);
  }, [orderStatus]);

  // This is for if this form is a subcomponent of add/edit events form
  useEffect(() => {
    if (setNewOrderInfo) {
      const newOrderObj: any = {
        validateSubmit: validateSubmit,
        status: orderStatus,
        technicianID: selectedTechnician ?? '',
      }
      // If there is a customer/vehicle ID, the new info object is not needed
      if (selectedCustomer[0]?.id && selectedCustomer[0]?.id !== 'newCustomer') {
        newOrderObj.customerID = selectedCustomer[0].id;
      } else {
        newOrderObj.customerDetails = newCustomerInfo;
      }
      if (selectedVehicle[0]?.id && selectedVehicle[0]?.id !== 'newVehicle') {
        newOrderObj.vehicleID = selectedVehicle[0].id;
      } else {
        newOrderObj.vehicleDetails = newVehicleInfo;
      }

      setNewOrderInfo(newOrderObj);
    }

    const missingFieldsArray: string[] = [];
    if(!setNewOrderInfo && !orderTitle.trim().length) missingFieldsArray.push(TextOnly('orderTitle'));
    if(!selectedCustomer[0]?.id) missingFieldsArray.push(TextOnly('customer'));
    if(!selectedVehicle[0]?.id) missingFieldsArray.push(TextOnly('vehicle'));
    setMissingFields(missingFieldsArray);
    setMissingAttOrderForm?.([
      ...missingFieldsArray,
      ...missingAttCustomerForm,
      ...missingAttVehicleForm,
    ])
  }, [
    orderTitle,
    orderStatus,
    description,
    newCustomerInfo,
    newVehicleInfo,
    selectedCustomer,
    selectedVehicle,
    selectedTechnician,
    newEventInfo,
    isAddingEvent,
    missingAttCustomerForm,
    missingAttVehicleForm,
  ]);

  useEffect(()=>{
    if (selectedCustomer[0]?.label?.includes(TextOnly('createNewCustomer'))) {
      setSelectedVehicle([{
        id: 'newVehicle',
        label: `+ ${TextOnly('createNewVehicle')}`,
      }]);
    } else if (!selectedCustomer.length) {
      setSelectedVehicle([]);
    } else {
      const vehicleList:any[] = [];
      selectedCustomer[0]?.vehicles?.forEach((vehicle: any)=>{
        vehicleList.push({
          id: vehicle.vehicleID,
          label: vehicle.licensePlate
            ? `${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.licensePlate})`
            : `${vehicle.year} ${vehicle.make} ${vehicle.model}`
        });
      });
      vehicleList?.unshift({
        id: 'newVehicle',
        label: `+ ${TextOnly('createNewVehicle')}`
      });
      setCustomerVehicles(vehicleList);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (setNewOrderInfo) {
      setIsLoadingSubmit(isLoading);
    }
  }, [isLoading]);


  return (
    <div className={setNewOrderInfo && 'u-margin-top-large'}>
      <div className={setNewOrderInfo ? 'hide-element' : 'h1 c-modal__heading'}>
        {orderData ? `${TextOnly('order')} #${convertOrderNumToDisplay(orderData.orderNumber)}`
        : TextOnly('newOrder')}
      </div>
      {(isLoadingData || (orderData && customersList?.length === 0)) ? <Loading /> :
        <div className="u-margin-center u-margin-top u-padding-bottom-80 l-container-med">
          {/* {
            (orderData?.status === 'completed' ||
            orderData?.status === 'canceled') ?
            (!orderData?.readyForPickUpNotified) ?
              <div className={`c-field`}>
                <LoaderButton
                  text={TextOnly('notifyIsReadyForPickUp')}
                  onClick={()=>handleNotifyCustomerReadyForPickUp()}
                />
              </div>
              :
              <div className={`c-field`}>
                <label className={`c-field__label text-bold`}>
                  <Text tid="readyForPickUpNotified" />
                </label>
              </div>
            : null
          } */}

          <div className={`${windowWidth > 767 ? 'flex-row' : ''} u-width-100-percent`}>

            {/* TITLE */}
            <div className={`c-field u-flex-expand ${setNewOrderInfo ? 'hide-element' : ''}`}>
              <label className={`c-field__label`} htmlFor="orderTitle">
                <span className="u-text-error">*</span> <Text tid="orderTitle" />:
              </label>
              <input
                type="text"
                id="orderTitle"
                className={`c-input ${(orderTitle.length > 0 && !orderTitle.trim().length) ? 'c-input__error' :''}`}
                placeholder={`${TextOnly('orderTitle')}`}
                value={orderTitle}
                onChange={(event) => setOrderTitle(event.target.value)}
                disabled={isLoadingSubmit}
              />
            </div>

            {/* STATUS */}
            <div className="c-field">
              <label className={`c-field__label`}>
                <span className="u-text-error">*</span> <Text tid="orderStatus" />:
              </label>
              <div className={`c-select--status`}>
                <i className={`fa-solid fa-circle ${statusColor} u-padding-right`}></i>
                <select
                  value={orderStatus}
                  onChange={(event) =>{
                    if (event.target.value === 'readyForPickup') {
                      setShowConfirmNotificationModal(true);
                    } else {
                      setSendNotification(false);
                    }
                    setOrderStatus(event.target.value)
                  }}
                  disabled={isLoadingSubmit}
                >
                  <option value="" disabled>
                    {TextOnly('orderStatus')}
                  </option>
                  {
                    ORDER_STATUS_OPTIONS.map((status:any) => {
                      if (!!orderData) {
                        return (
                          <option
                            key={status.value}
                            value={status.value}
                          >
                            {status.label}
                          </option>
                        )
                      } else if (status.value !== 'canceled') {
                        return (
                          <option
                            key={status.value}
                            value={status.value}
                          >
                            {status.label}
                          </option>
                        )
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <ConfirmReadyForPickUpNotification
              isOpen={showConfirmNotificationModal}
              onSubmit={(val: boolean)=>{
                setSendNotification(val);
                handleCloseConfirmNotification();
              }}
            />
          </div>

          {/* DESCRIPTION */}
          <div className={`c-field ${setNewOrderInfo ? 'hide-element' : ''}`}>
            <label className={`c-field__label`} htmlFor="description">
              <Text tid='description' />:
            </label>
            <textarea
              id="description"
              className={`c-input c-textarea`}
              placeholder={`${TextOnly('description')}`}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              disabled={isLoadingSubmit}
            />
          </div>

          {/* TECHNICIAN */}
          <div className={`c-field ${windowWidth > 767 ? 'u-width-50-percent' : ''}`}>
            <label className={`c-field__label`} htmlFor="technician">
              <Text tid="technician" />:
            </label>
            <Typeahead
              ref={techTypeahead}
              id={'Shop-Technician'}
              labelKey='label'
              onInputChange={(newInput: any)=>{
                setTechInputValue(newInput);
              }}
              options={shopTechnicians}
              onChange={(technician:any) => {
                setSelectedTechnician(technician[0]?.id);
                if (technician[0]) setIsTechTypeaheadOpen(false);
              }}
              placeholder={TextOnly('selectTechnician')}
              selected={
                (selectedTechnician && selectedTechnician.length > 0) ?
                  shopTechnicians.find((tech: any) => tech.id === selectedTechnician) ?
                  [shopTechnicians.find((tech: any) => tech.id === selectedTechnician)]
                  : []
                : []
              }
              filterBy={(option: any) => option?.label?.toLowerCase().includes(techInputValue.toLowerCase())}
              renderMenuItemChildren={(option: any, props: any, index) => {
                return (
                  <div className="typeahead-menu-item-children">
                    {option?.label}
                  </div>
                )
              }}
              open={isTechTypeaheadOpen}
              onBlur={()=>{
                setIsTechTypeaheadOpen(false);
                if (!selectedTechnician) {
                  techTypeahead.current.clear();
                  setTechInputValue('');
                }
              }}
              onFocus={()=>setIsTechTypeaheadOpen(true)}
              disabled={isLoadingSubmit}
            />
          </div>

          <div className={windowWidth > 767 ? 'flex-row flex-space-between l-flex-gap' : 'u-margin-top-xlarge'}>

            {/* CUSTOMER INFO */}
            <div className={windowWidth > 767 ? "u-width-50-percent" : ''}>
              <h2>
                <Text tid="customerInfo" />
              </h2>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor="customer">
                  <span className="u-text-error">*</span> <Text tid="customer" />:
                </label>
                <Typeahead
                  ref={customerTypeahead}
                  disabled={isLoadingSubmit || !!customerID || !!isSingleCustomer}
                  labelKey={'label'}
                  onInputChange={(newInput)=>{
                    setCustomerInputValue(newInput);
                  }}
                  id={'Shop-Customer'}
                  options={customersList}
                  onChange={(shopCustomer:any) => {
                    setSelectedCustomer(shopCustomer);
                    if (shopCustomer[0]) {
                      setIsCustTypeaheadOpen(false);
                      setCustomerInputValue('');
                      setSelectedVehicle([]);
                      setVehicleInputValue('');
                      if (shopCustomer[0]?.id !== 'newCustomer') {
                        setNewCustomerInfo(null);
                      }
                    }
                  }}
                  placeholder={TextOnly('customer')}
                  selected={selectedCustomer}
                  filterBy={(option: any) => {
                    if (option?.label?.toLowerCase().includes(customerInputValue?.toLowerCase())) {
                      return true;
                    }
                    return option?.label?.includes(TextOnly('createNewCustomer'));
                  }}
                  renderMenuItemChildren={(option: any, props: any, index) => {
                    return (
                      <div className="typeahead-menu-item-children">
                        {option.label}
                      </div>
                    )
                  }}
                  open={isCustTypeaheadOpen}
                  onBlur={()=>{
                    setIsCustTypeaheadOpen(false);
                    if (!selectedCustomer[0]) {
                      customerTypeahead.current.clear();
                      setCustomerInputValue('');
                    }
                  }}
                  onFocus={()=>setIsCustTypeaheadOpen(true)}
                />
                {selectedCustomer[0]?.id === 'newCustomer' && (
                  <AddEditCustomerForm
                    currentShop={currentShop}
                    setIsLoading={setIsLoadingSubmit}
                    isLoading={isLoadingSubmit}
                    setNewCustomerInfo={setNewCustomerInfo}
                    setMissingAttCustomerForm={setMissingAttCustomerForm}
                  />
                )}
              </div>
            </div>

            {/* VEHICLE INFO */}
            <div className={windowWidth > 767 ? "u-width-50-percent" : 'u-margin-top-xlarge'}>
              <h2>
                <Text tid="vehicleInfo" />
              </h2>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor="vehicle">
                  <span className="u-text-error">*</span> <Text tid="vehicle" />:
                </label>
                <Typeahead
                  ref={vehicleTypeahead}
                  id='Customer-Vehicle'
                  labelKey='label'
                  onInputChange={(newInput)=>{
                    setVehicleInputValue(newInput);
                  }}
                  options={customerVehicles || []}
                  onChange={(vehicle:any) => {
                    setSelectedVehicle(vehicle);
                    if (vehicle[0]) {
                      setIsVehicleTypeaheadOpen(false);
                      setVehicleInputValue('');
                      if (vehicle[0]?.id !== 'newVehicle') {
                        setNewVehicleInfo(null);
                      }
                    }
                  }}
                  placeholder={TextOnly('vehicle')}
                  selected={selectedVehicle}
                  filterBy={(option: any) => {
                    if (option?.label?.toLowerCase().includes(vehicleInputValue.toLowerCase())) {
                      return true;
                    }
                    return option?.label?.includes(TextOnly('createNewVehicle'));
                  }}
                  disabled={isLoadingSubmit || !selectedCustomer.length || selectedCustomer[0]?.id === 'newCustomer'}
                  renderMenuItemChildren={(option: any, props: any, index) => {
                    return (
                      <div className="typeahead-menu-item-children" >
                        {option?.label}
                      </div>
                    )
                  }}
                  open={isVehicleTypeaheadOpen}
                  onBlur={()=>{
                    setIsVehicleTypeaheadOpen(false);
                    if (!selectedVehicle[0]) {
                      vehicleTypeahead.current.clear('');
                      setVehicleInputValue('');
                    }
                  }}
                  onFocus={()=>setIsVehicleTypeaheadOpen(true)}
                />
                {selectedVehicle[0]?.id === 'newVehicle' && (
                  <AddEditVehicleForm
                    currentShop={currentShop}
                    setIsLoading={setIsLoadingSubmit}
                    isLoading={isLoadingSubmit}
                    setNewVehicleInfo={setNewVehicleInfo}
                    setMissingAttVehicleForm={setMissingAttVehicleForm}
                  />
                )}
              </div>
            </div>
          </div>

          {!setNewOrderInfo && (
            <>
              {/* APPOINTMENTS */}
              <div className={`c-field__label u-margin-top-${windowWidth > 767 ? 'large' : 'xlarge'}`}>
                <Text tid='relatedAppointments' />
              </div>
              <div className='u-margin-top'>
                {displayEvents}
              </div>
              {isAddingEvent && (
                <div className='c-box u-position-relative'>
                  <button
                    className="c-btn-icon c-box--close-btn"
                    onClick={closeAddingEvent}
                    disabled={isLoadingSubmit}
                  >
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fal fa-times" />
                    </div>
                  </button>
                  <AddEditEventForm
                    currentShop={currentShop}
                    isLoading={isLoadingSubmit}
                    setIsLoading={setIsLoadingSubmit}
                    isAppointment={true}
                    setNewEventInfo={setNewEventInfo}
                    user={user}
                  />
                </div>
              )}
              <div className={isAddingEvent ? 'hide-element' : "clickable"}>
                <a
                  onClick={()=>setIsAddingEvent(true)}
                >
                  <i className="c-btn__icon fal fa-plus"></i> <Text tid='appointment' />
                </a>
              </div>

              {/* SUBMIT BUTTON */}
              <div className="u-margin-top-large c-field display-flex justifycontent-center align-items-center flex-flow-column">
                <ToolTip
                  text={(
                    !validateSubmit() &&
                    (
                      missingFields.length ||
                      missingAttCustomerForm.length ||
                      missingAttVehicleForm.length
                    )
                  ) ? `${TextOnly('missingIncorrectFields')} (${[
                    ...missingFields,
                    ...missingAttCustomerForm,
                    ...missingAttVehicleForm,
                  ].join(', ')})` : ''}
                >
                  <LoaderButton
                    text={TextOnly(orderData ? 'saveChanges' :'createOrder')}
                    onClick={() => handleSubmit()}
                    isLoading={isLoadingSubmit}
                    loadingText={ TextOnly(orderData ? 'savingChanges' : 'loading')}
                    disabled={(isLoadingSubmit) || (!validateSubmit())}
                  />
                </ToolTip>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
};

export default AddEditOrderForm;
