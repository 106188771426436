import React from 'react';
import { Text, TextOnly } from './Text';
import HelpTooltip from '../components/HelpTooltip';
import { useScreenSize } from '../context/ScreenSize';

interface PriceBreakdownProps {
  config: any;
  shop?: any;
  renewalTier: string;
  subscriptionType: 'AutoAuth' | 'CRM';
  numberOems?: number;
  signup?: boolean;
  crmSignup?: boolean;
  crmFreeTrial?: boolean;
}

const PriceBreakdown: React.FC<PriceBreakdownProps> = (props) => {
  const { isMobile, windowWidth } = useScreenSize();
  const isSmallScreen = isMobile || windowWidth < 400;
  const isMediumScreen = windowWidth >= 400 && windowWidth < 600;

  const { shop, renewalTier, signup, config, numberOems, subscriptionType, crmSignup, crmFreeTrial } = props;
  const prices = config.prices;
  const renewalShopType = renewalTier?.slice(9).toLowerCase();
  const isDowngrading = shop ? shop.downgradeToStandardPending : false;

  const unitPriceText = isMediumScreen ? 'price' : 'unitPrice';
  const quantityText = isMediumScreen ? 'qty' : 'quantity';

  // NEXT RENEWAL PERIOD:

  // Subscription base price
  const subBasePrice = prices[`${renewalShopType}Shop`];
  const crmBasePrice = prices.crmBasicMonthly;

  // OEM values and prices
  const baseOems = 1;
  const numOems = shop ? (shop.oemIDs ? shop.oemIDs.length : 0)
  - (shop.removedOemIDs ? shop.removedOemIDs.length : 0) : numberOems;
  const oemAddons = numOems && (numOems - baseOems) > 0 ? numOems - baseOems : 0;
  const perOemRate = prices.oemRegionAddon;
  const oemUserUpcharge = prices.oemRegionUserUpcharge;

  // User values and prices
  const baseUsers = config.shopBaseValues[`${renewalShopType}BaseUsers`];
  const userAddons = shop && renewalShopType !== 'standard'
    ? shop.shopMaxUsers - baseUsers
    : 0;
  let perUserRate = 0;
  if (subscriptionType === 'AutoAuth' && renewalShopType !== 'standard') {
    perUserRate = oemAddons
      ? prices[`${renewalShopType}UserAddon`] + (oemUserUpcharge * oemAddons)
      : prices[`${renewalShopType}UserAddon`];
  } else if (subscriptionType === 'CRM') {
    perUserRate = prices.crmUserUpcharge;
  }

  // Cert values and prices
  const baseCerts = config.shopBaseValues[`${renewalShopType}BaseTechCerts`] || 0;
  const certAddons = shop && isDowngrading ?
    (shop.shopMaxTechCerts - config.shopBaseValues.plusBaseTechCerts) || 0
    : shop && (shop.shopMaxTechCerts - baseCerts) || 0;
  const perCertRate = prices[`${renewalShopType}TechCertAddon`] || 0;

  // Grand total
  const grandTotal = subBasePrice + (userAddons * perUserRate) + (oemAddons * perOemRate) + (certAddons * perCertRate);
  const crmGrandTotal = crmBasePrice + (userAddons * perUserRate);

  return (
    <>
    { subscriptionType === 'AutoAuth' ?
      <div className={'c-box u-padding-bottom-medium'}>
        <div className={userAddons > 0 ? 'pb-grid' : 'pb-grid-no-users'}>
          <div className='pb-price pb-col-heading'>
            <Text tid={unitPriceText}/>
          </div>
          <div className='pb-qty pb-col-heading'>
            <Text tid={quantityText}/>
          </div>
          <div className='pb-total pb-col-heading'>
          <Text tid="total"/>
          </div>

          <div className="pb-sub u-font-weight-bold u-text-action-blue-700">
            {renewalTier} <Text tid="subscription"/>
          </div>
          <div className="pb-subTotal">
            ${subBasePrice?.toFixed(2)}
          </div>

          <div className="pb-oems pb-grid-section">
            <div className="pb-section-title u-font-weight-bold u-text-action-blue-700">
              <Text tid="activeOems"/>
            </div>
            <div className="pb-section-included">
              <Text tid="included"/>
            </div>
            <div className="pb-section-price1">
              <Text tid="freeCaps"/>
            </div>
            <div className="pb-section-qty1">
              {isSmallScreen && 'x '}{baseOems}
            </div>
            <div className="pb-section-total1">
              <Text tid="freeCaps"/>
            </div>
            <div className={!oemAddons ? 'hide-element' : "pb-section-added"}>
              <Text tid="added"/>
            </div>
            <div className={!oemAddons ? 'hide-element' : "pb-section-price2"}>
              ${perOemRate?.toFixed(2)}
            </div>
            <div className={!oemAddons ? 'hide-element' : "pb-section-qty2"}>
              {isSmallScreen && 'x '}{oemAddons}
            </div>
            <div className={!oemAddons ? 'hide-element' : 'pb-section-total2'}>
              ${(perOemRate * oemAddons)?.toFixed(2)}
            </div>
          </div>

          <div className={userAddons === 0 ? 'hide-element' : "pb-usrs pb-grid-section"}>
            <div className="pb-section-title u-font-weight-bold u-text-action-blue-700 l-display-inline-flex l-flex-align-center">
              <div className="right-padding-10">
                <Text tid="shopUsersTools"/>
              </div>
              { !isSmallScreen && (
                <HelpTooltip
                  label={TextOnly('userUpchargeExplain', {
                    base: prices[`${renewalShopType}UserAddon`],
                    upcharge: oemUserUpcharge
                  })}>
                  <i className={!userAddons || !oemAddons ? 'hide-element' : "c-btn-icon fal fa-info-circle"}
                  />
                </HelpTooltip>
              )}
            </div>
            <div className="pb-section-included">
              <Text tid="included"/>
            </div>
            <div className="pb-section-price1">
              <Text tid="freeCaps"/>
            </div>
            <div className="pb-section-qty1">
              {isSmallScreen && 'x '}{baseUsers}
            </div>
            <div className='pb-section-total1'>
              <Text tid="freeCaps"/>
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-added"}>
              <Text tid="added"/>
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-price2"}>
              ${perUserRate?.toFixed(2)}
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-qty2"}>
              {isSmallScreen && 'x '}{userAddons}
            </div>
            <div className={!userAddons ? 'hide-element' : 'pb-section-total2'}>
              ${(perUserRate * userAddons)?.toFixed(2)}
            </div>
          </div>

          <div className={!baseCerts && !certAddons ? "hide-element" : "pb-certs pb-grid-section"}>
            <div className="pb-section-title u-font-weight-bold u-text-action-blue-700 l-display-inline-flex l-flex-align-center">
              <div className="right-padding-10">
                <Text tid="techCerts"/>
              </div>
            </div>
            <div className={!baseCerts ? "hide-element" : "pb-section-included"}>
              <Text tid="included"/>
            </div>
            <div className={!baseCerts ? "hide-element" : "pb-section-price1"}>
              <Text tid="freeCaps"/>
            </div>
            <div className={!baseCerts ? "hide-element" : "pb-section-qty1"}>
              {isSmallScreen && 'x '}{baseCerts}
            </div>
            <div className={!baseCerts ? "hide-element" : 'pb-section-total1'}>
              <Text tid="freeCaps"/>
            </div>
            <div className={!certAddons ? 'hide-element' : "pb-section-added"}>
              <Text tid="added"/>
            </div>
            <div className={!certAddons ? 'hide-element' : "pb-section-price2"}>
              ${perCertRate?.toFixed(2)}
            </div>
            <div className={!certAddons ? 'hide-element' : "pb-section-qty2"}>
              {isSmallScreen && 'x '}{certAddons}
            </div>
            <div className={!certAddons ? 'hide-element' : 'pb-section-total2'}>
              ${(perCertRate * certAddons)?.toFixed(2)}
            </div>
          </div>

          <div className='pb-line'>
            ------------
          </div>

          <div className="pb-renewal u-font-weight-bold u-text-action-blue-700">
            <Text tid={signup ? "subscriptionPrice" : "renewalPrice"}/>
          </div>
          <div className='pb-renewalTotal u-font-weight-bold'>
            ${grandTotal?.toFixed(2)}/{TextOnly('year')}
          </div>
        </div>
      </div>
    : '' }
    { subscriptionType === 'CRM' || crmSignup === true ?
      <div className={'c-box u-padding-bottom-medium'}>
        <div className={userAddons > 0 ? 'pb-grid' : 'pb-grid-no-users'}>
          <div className='pb-price pb-col-heading'>
            <Text tid={unitPriceText}/>
          </div>
          <div className='pb-qty pb-col-heading'>
            <Text tid={quantityText}/>
          </div>
          <div className='pb-total pb-col-heading'>
          <Text tid="total"/>
          </div>

          <div className="pb-sub u-font-weight-bold u-text-action-blue-700">
            PLUS CRM <Text tid="subscription"/>
          </div>
          <div className="pb-subTotal">
            ${crmBasePrice?.toFixed(2)}
          </div>

          <div className={!userAddons ? 'hide-element' : "pb-usrs pb-grid-section"}>
            <div className="pb-section-title u-font-weight-bold u-text-action-blue-700 l-display-inline-flex l-flex-align-center">
              <div className="right-padding-10">
                <Text tid="shopUsers"/>
              </div>
              { !isSmallScreen && (
                <HelpTooltip
                  label={TextOnly('crmUserUpchargeExplain', { upcharge: `${perUserRate}` })}
                >
                  <i className={"c-btn-icon fal fa-info-circle"}
                  />
                </HelpTooltip>
              )}
            </div>
            <div className="pb-section-included">
              <Text tid="included"/>
            </div>
            <div className="pb-section-price1">
              <Text tid="freeCaps"/>
            </div>
            <div className="pb-section-qty1">
              {baseUsers}
            </div>
            <div className="pb-section-total1">
              <Text tid="freeCaps"/>
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-added"}>
              <Text tid="added"/>
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-price2"}>
              ${perUserRate?.toFixed(2)}
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-qty2"}>
              {userAddons}
            </div>
            <div className={!userAddons ? 'hide-element' : "pb-section-total2"}>
              ${(perUserRate * userAddons)?.toFixed(2)}
            </div>
          </div>

          <div className="pb-line">
            ------------
          </div>

          <div className="pb-renewal u-font-weight-bold u-text-action-blue-700">
            <Text tid={signup ? "subscriptionPrice" : "renewalPrice"}/>
          </div>
          <div className="pb-renewalTotal u-font-weight-bold">
            ${crmGrandTotal?.toFixed(2)}/{TextOnly('month')}
          </div>
        </div>
        { crmFreeTrial ?
            <div className="u-text-warning u-text-center u-padding-top-small u-font-weight-bold">
              <Text tid={"chargedAfterFreeTrial"} />
            </div>
          : ''
        }
      </div>
    : '' }
    </>
  );
}

export default PriceBreakdown;