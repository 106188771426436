import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import { TextOnly } from '../../../components/Text';
import { Loading } from '../../../components/Loading';
import { toast } from 'react-toastify';
import {
  crmGetShopOrders,
  crmGetShopCustomers,
  crmUpdateOrderStatus,
  crmGetShopCustomerOrders
 } from '../../../libs/db-lib';
import OrderTable from './OrderTable';
import LoaderButton from '../../../components/LoaderButton';
import AddEditOrderForm from './AddEditOrderForm';
import { Dialog } from '@reach/dialog';
import KanbanBoard from './KanbanBoard';
import { convertOrderNumToDisplay } from '../../../libs/utils';
import { formatDateTime2Lines } from '../../../libs/utils';
import KanbanCard from './KanbanCard';
import { useScreenSize } from '../../../context/ScreenSize';
import ConfirmReadyForPickUpNotification from '../../../components/ConfirmReadyForPickUpNotification/ConfirmReadyForPickUpNotification';


const ManageOrders: React.FC<any> = (props) => {
  const { currentShop, user, customer } = props;

  const { windowWidth } = useScreenSize();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [displayOrders, setDisplayOrders] = useState<any[]>([]);
  const [currentTabView, setCurrentTabView] = useState<string>(!customer ? 'kanban' : 'list');
  const [customers, setCustomers] = useState<any[]>(customer ? [customer] : []);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [showConfirmNotificationModal, setShowConfirmNotificationModal] = useState<boolean>(false);
  const [orderID, setOrderID] = useState<string | null>(null);
  const [shopID, setShopID] = useState<string | null>(null);
  const [newStatus, setNewStatus] = useState<string | null>(null);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.create-order-dialog')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);
  };

  const handleCloseConfirmNotification = async () => {
    document.querySelector('.c-modal.send-notification-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowConfirmNotificationModal(false);
      setOrderID(null);
      setShopID(null);
      setNewStatus(null);
    }, 350);
  };

  const getAllCustomersOrders = async () => {
    const ordersResponse: any = !!customer ?
      await crmGetShopCustomerOrders(currentShop.shopID, customer.customerID)
      : await crmGetShopOrders(currentShop.shopID);
    if (!ordersResponse?.error) {
      setOrders(ordersResponse);
    } else {
      toast.error(`${TextOnly('error')}: ${ordersResponse.error}`, {
        containerId: 'standard',
      });
    }
  };

  const getAllShopCustomers = async () => {
    if (!customer) {
      const customersResponse: any = await crmGetShopCustomers(currentShop.shopID);
      if (!customersResponse?.error) {
        setCustomers(customersResponse);
      } else {
        toast.error(`${TextOnly('error')}: ${customersResponse.error}`, {
          containerId: 'standard',
        });
      }
    }
  };

  const handleOrderStatusChange = async (shopID: string, orderID: string, newStatus: string) => {
    const changedOrders = orders.map((order) => {
      if (order.orderID === orderID) {
        order.status = newStatus;
      }
      return order;
    });
    setOrders(changedOrders);
    if (newStatus === "readyForPickup") {
      setShowConfirmNotificationModal(true);
      setOrderID(orderID);
      setShopID(shopID);
      setNewStatus(newStatus);
    } else {
      await crmUpdateOrderStatus(shopID, orderID, newStatus, false);
    }
  }

  const fetchManageOrderData = async () => {
    getAllShopCustomers();
    await getAllCustomersOrders();
    setIsLoading(false);
  }

  useEffect(() => {
    fetchManageOrderData();
  }, []);

  useEffect(() => {
    const orderDisplayData = orders?.map((order: any) => {
      const displayCreatedOn = formatDateTime2Lines(order?.createdOn);
      const displayNum = convertOrderNumToDisplay(order?.orderNumber);
      order.yearMakeModel = `${order.vehicle?.year} ${order.vehicle?.make} ${order.vehicle?.model}`;
      order.customerName = `${order.customer?.firstName} ${order.customer?.lastName}`;
      order.customerNameShortened = `${order.customer?.firstName?.charAt(0)}. ${order.customer?.lastName}`;
      order.tech = currentShop?.shopUsers?.find((user: any) => user.userID === order?.technicianID);
      order.displayStatus = TextOnly(order?.status);
      order.filterValue = [
        order.title,
        order.displayStatus,
        order.customerName,
        order.tech?.firstName,
        order.tech?.lastName,
        order.yearMakeModel,
        displayNum,
        order.description,
        displayCreatedOn.date,
        displayCreatedOn.time,
      ].join(' ').toLocaleLowerCase();
      return order;
    });
    setDisplayOrders(orderDisplayData);
  }, [customers, orders]);

  return (
    <div className="u-padding-bottom">
      { !customer &&
        <Header title={TextOnly('manageOrders')} />
      }
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex-space-between">
            <LoaderButton
              text={TextOnly('createOrder')}
              icon={<i className="c-btn__icon fal fa-plus" />}
              onClick={() => setShowModal(true)}
            />
            {windowWidth > 767 && !customer && (
              <div className="c-tabset crm-tabset u-margin-right u-margin-bottom-none">
                <span
                  className={`c-tab__item ${
                    currentTabView === 'kanban' ? 'c-tab__item--active' : ''
                  }`}
                  onClick={() => setCurrentTabView('kanban')}
                >
                  <i className="fa fa-columns" aria-hidden="true"></i>
                </span>
                <span
                  className={`c-tab__item ${
                    currentTabView === 'list' ? 'c-tab__item--active' : ''
                  }`}
                  onClick={() => setCurrentTabView('list')}
                >
                  <i className="fa fa-list-ul" aria-hidden="true"></i>
                </span>
              </div>
            )}
          </div>

          {windowWidth > 767 && !customer ? (
            <div className="u-margin-top-xlarge">
              {currentTabView === 'kanban' && (
                <KanbanBoard
                  handleOrderChange={fetchManageOrderData}
                  handleOrderStatusChange={handleOrderStatusChange}
                  orders={displayOrders}
                  currentShop={currentShop}
                  shopCustomers={customers}
                  user={user}
                />
              )}
              {currentTabView === 'list' && (
                <OrderTable
                  handleOrderChange={fetchManageOrderData}
                  orders={displayOrders}
                  currentShop={currentShop}
                  user={user}
                  shopCustomers={customers}
                />
              )}
            </div>
          ) : customer ? (
            <div className='u-margin-top-large u-margin-bottom-large l-container-med'>
              {orders
                .sort(
                  (orderA, orderB) => orderB.orderNumber - orderA.orderNumber
                )
                .map(order => (
                  <KanbanCard
                    key={order.orderID}
                    data={order}
                    currentShop={currentShop}
                    shopCustomers={[customer]}
                    onChange={fetchManageOrderData}
                    isSingleCustomer={true}
                    user={user}
                    isAccordion={true}
                  />
                ))
              }
            </div>
          ) : (
            <div className='u-margin-top-large u-margin-bottom-large l-container-med'>
              <KanbanBoard
                handleOrderChange={fetchManageOrderData}
                handleOrderStatusChange={handleOrderStatusChange}
                orders={displayOrders}
                currentShop={currentShop}
                shopCustomers={customers}
                user={user}
              />
            </div>
          )}

          <Dialog
            isOpen={showModal}
            onDismiss={handleCancelModal}
            className="c-modal-slider create-order-dialog"
            id="addEditOrder"
            aria-label={TextOnly('createOrder')}
          >
            <button
              className="c-btn-icon c-modal-slider__close"
              onClick={handleCancelModal}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <AddEditOrderForm
              currentShop={currentShop}
              shopCustomers={customers}
              setIsLoading={setIsLoadingModal}
              isLoading={isLoadingModal}
              handleCloseModal={handleCancelModal}
              onSubmit={fetchManageOrderData}
              customerID={customer?.customerID}
              isSingleCustomer={!!customer}
              user={user}
            />
          </Dialog>
        </>
      )}
      <ConfirmReadyForPickUpNotification
        isOpen={showConfirmNotificationModal}
        onSubmit={(val: boolean)=> {
          crmUpdateOrderStatus(shopID, orderID, newStatus, val);
          handleCloseConfirmNotification();
        }}
      />
    </div>
  );
};

export default ManageOrders;
