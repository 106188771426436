import React, { useState, useEffect } from 'react';
import {
  createThread, getThreadList,
} from '../../libs/db-lib';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import LoaderButton from '../../components/LoaderButton';
import Header from '../../components/Header';
import { ThreadComponent } from './Thread';
import { DropSort } from './DropSort';
import { Thread, Tag, THREAD_TITLE_MIN_LENGTH, THREAD_TITLE_MAX_LENGTH, THREAD_BODY_MIN_LENGTH, THREAD_BODY_MAX_LENGTH } from './types';
import { toast } from 'react-toastify';
import { TextOnly } from '../../components/Text';
import './ThreadForm.css';
import './MenuBar.css';
import './MessageBoard.css';
import HelpTooltip from '../../components/HelpTooltip';
import NotifyUser from './NotifyUser';
import OffensiveContentModal from './OffensiveContentModal';
import { en } from '../../lang/en';
import { useScreenSize } from '../../context/ScreenSize';

const MessageBoard: React.FC<any> = (props) => {
  const history = useHistory();
  const { windowWidth } = useScreenSize();
  const shopID = props.currentShop.shopID;
  const { userVotes, showNotifyModal, setShowNotifyModal, allTags, featuredThreadList } = props;
  const errorReturn = (
    <>
      <Header title={TextOnly('messageBoard')} />
      <p>{TextOnly('errorTryAgain')}</p>
    </>
  )


  const {
    mainNotedThreadList,
    setMainNotedThreadList,
    lekNoted,
    setLekNoted,
    mainNewestThreadList,
    setMainNewestThreadList,
    lekNewest,
    setLekNewest,
    mainRatedThreadList,
    setMainRatedThreadList,
    lekRated,
    setLekRated,
  } = props.threadFeat;

  const {
    refetchThreadList,
    setRefetchThreadList,
    threadList,
    setThreadList,
    threadQuery,
    setThreadQuery,
    lekQuery,
    setLekQuery,
    isLoadingThread,
    setIsLoadingThread,
    isSyncing,
    setIsSyncing,
    setIsDeleting,
    searchThread,
    setSearchThread,
    filteredThreads,
    setFilteredThreads,
  } = props.stateVar;

  // #region State Variables
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [filteredThreadsSub, setFilteredThreadsSub] = useState<Thread[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [threadTitle, setThreadTitle] = useState<string>('');
  const [threadBody, setThreadBody] = useState<string>('');
  const [threadTags, setThreadTags] = useState<Tag[]>([]);
  const [showOffensiveModal, setShowOffensiveModal] = useState<boolean>(false);
  const [lekSearch, setLekSearch] = useState<string | null>(null);
  // #endregion

  // #region functions
  // #endregion

  // #region UseEffect
  //! filteredTags
  useEffect(() => {
    if (selectedTags.length === 0) {
      setFilteredThreadsSub(filteredThreads);
    } else {
      const newfilteredThreads = filteredThreads.filter((thread: Thread) =>
        thread.tags.some((tag: Tag) => selectedTags.includes(tag.tagID))
      );
      setFilteredThreadsSub(newfilteredThreads);
    }
  }, [selectedTags, filteredThreads]);
  //! filteredSearch
  useEffect(() => {
    if (searchThread.length === 0) {
      setFilteredThreads(threadList);
    }
  }, [searchThread, threadList]);
  // #endregion

  // #region handle functions
  const isTag = (obj: any) =>{
    return (
      typeof obj === 'object' &&
      'tagID' in obj && typeof(obj.tagID) === 'string' && obj.tagID.length > 0 &&
      'body' in obj && typeof(obj.body) === 'string' && obj.body.length > 0 &&
      'name' in obj && typeof(obj.name) === 'string' && obj.name.length > 0
    );
  }
  const validateTags = (tagsList: any) => {
    if(!Array.isArray(tagsList)) return false
    else if(tagsList.length === 0) return false
    else if (!tagsList.every(isTag)) return false
    else return true
  }
  const validateTitle = (title: any) =>{
    return (typeof(title) === 'string' && title.length > THREAD_TITLE_MIN_LENGTH && title.length <= THREAD_TITLE_MAX_LENGTH);
  }
  const validateBody = (body: any) =>{
    return (typeof(body) === 'string' && body.length > THREAD_BODY_MIN_LENGTH && body.length <= THREAD_BODY_MAX_LENGTH);
  }
  const handleCreateThread = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const title = threadTitle;
    const tags = threadTags;
    const body = threadBody;
    if(!validateTags(tags) || !validateTitle(title) || !validateBody(body)) return
    setLoadingCreate(true);
    const response = await createThread(title, tags, body, shopID);
    if (!response?.error) {
      setShowForm(false);
      toast.success(
        TextOnly('threadCreated'),
        {
          containerId: 'standard',
        }
      );
      handleClearForm();
      setThreadList([response, ...threadList]);
      setMainNewestThreadList([response, ...mainNewestThreadList])
      setMainRatedThreadList([response, ...mainRatedThreadList])
    } else {
      if(response.error==='offensiveContent'){
        setShowOffensiveModal(true);
      } else {
        toast.error(
          `${TextOnly('error')}: ${response.error}`,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
    setLoadingCreate(false);
  };
  const handleClearForm = () => {
    setThreadTitle('');
    setThreadBody('');
    setThreadTags([]);
    setShowForm(false);
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value.length > THREAD_TITLE_MAX_LENGTH) return
    setThreadTitle(event.target.value);
  };
  const handleBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if(event.target.value.length > THREAD_BODY_MAX_LENGTH) return
    setThreadBody(event.target.value);
  };
  const handleThreadTagChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tag: Tag
  ) => {
    const tagId = tag.tagID;
    const tagName = tag.name;
    const tagbody = tag.body;
    const isChecked = event.target.checked;
    if (isChecked) {
      setThreadTags([
        ...threadTags,
        { tagID: tagId, name: tagName, body: tagbody },
      ]);
    } else {
      setThreadTags(threadTags.filter((tag) => tag.tagID !== tagId));
    }
  };
  const handleTagSelect = (tagID: string) => {
    if (selectedTags.indexOf(tagID) !== -1) {
      setSelectedTags(selectedTags.filter((id) => id !== tagID));
    } else {
      setSelectedTags([...selectedTags, tagID]);
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchThread(event.target.value);
  };
  const clearSearch = () => {
    setSearchThread('');
  };
  const handleSync = () => {
    setIsSyncing(true);
    setThreadList([]);
    clearSearch();
    setLekNoted(null);
    setLekNewest(null);
    setLekRated(null);
    setLekSearch(null);
    setMainNotedThreadList([]);
    setMainNewestThreadList([]);
    setMainRatedThreadList([]);
    setRefetchThreadList((prevState: any) => !prevState);
  };
  const handleDeepSearch = async () => {
    setIsLoadingThread(true);
    setSelectedTags([]);
    const response = await getThreadList(lekSearch, threadQuery, searchThread, shopID);
    setFilteredThreads(response.threads);
    setLekSearch(response.lastEvaluatedKey)
    if(response.shrunkResult) {
      toast.error(
        `${TextOnly('error')}: ${TextOnly('shrunkResultSearch')}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoadingThread(false);
  }
  // #endregion

  if (allTags === undefined ||
      featuredThreadList === undefined ){ //Still loading or state updating
    return (
      <>
        <Header title={TextOnly('messageBoard')} />
        <Loading />
      </>
    );
  }
  else if (allTags === null ||
           featuredThreadList === null){
    return errorReturn;
  }
  else return (
    <div className={`${windowWidth < 650 ? 'smaller-screen-padding' :''}`}>
      <Header title={TextOnly('messageBoard')} />
      <div
        className='mb-wrapper flex-space-between flexwrap-wrap'
      >
        <LoaderButton
          type="submit"
          isLoading={false}
          disabled={false}
          text={`${TextOnly(showForm ? 'cancelThread' : 'createThread')}`}
          loadingText={TextOnly('loading')}
          onClick={() => setShowForm((prev) => !prev)}
          className='white-space-nowrap margintop-15px'
        />
        <div className='display-flex flexwrap-wrap'>
          <button
            className='c-btn marginright-20px margintop-15px'
            onClick={()=>history.push(`/messageBoard/userName/${props.user.userName}`)}
          >
            {TextOnly('myInteraction')}
          </button>
        </div>
      </div>
      <div className={`form-container ${showForm ? 'show' : 'hide'}`}>
        { loadingCreate ?
          <div className='max-height-150px'>
            <Loading />
          </div> :
          <form className="thread-form" onSubmit={handleCreateThread}>
            <div
              className='mb-wrapper justifycontent-center height-80px'
            >
              <label
                htmlFor="title"
                className='white-space-nowrap u-padding-none margin-none'
              >
                {TextOnly('threadTitle')}:
              </label>
              <input
                className='marginleft-20px'
                type="text"
                id="title"
                name="title"
                value={threadTitle}
                onChange={handleTitleChange}
                placeholder={TextOnly('startThread')}
                required
              />
            </div>
            <div
              className='mb-wrapper justifycontent-center'
            >
              <label
                htmlFor="title"
                className='white-space-nowrap u-padding-none margin-none'
              >
                {TextOnly('description')}{': '}
              </label>
              <textarea
                className="text-area-detail marginleft-20px"
                id="body"
                name="body"
                value={threadBody}
                onChange={handleBodyChange}
                placeholder={TextOnly('threadDescription')}
                rows={5}
                cols={40}
                required
              />
            </div>
            <label className="mb-label">{TextOnly('threadTags')}:</label>
            <div className="form-group">
              {allTags?.map((tag: Tag) => (
                <div key={tag.tagID}>
                  <label className="tag-checkbox mb-label u-cursor-pointer">
                    <input
                      type="checkbox"
                      className='u-cursor-pointer'
                      id={tag.name}
                      name={tag.name}
                      value={tag.tagID}
                      checked={threadTags.some(
                        (selTag) => selTag.tagID === tag.tagID
                      )}
                      onChange={(event)=>handleThreadTagChange(event, tag)}
                    />
                    {tag.name}
                    <HelpTooltip
                      label={TextOnly(tag.body as keyof typeof en)}
                    >
                      <div className='questionmark-tooltip'>
                        <span
                          className="c-btn__icon fal fa-question"
                        />
                      </div>
                    </HelpTooltip>
                  </label>
                </div>
              ))}
            </div>
            <LoaderButton
              type="submit"
              isLoading={loadingCreate}
              disabled={loadingCreate || !validateTags(threadTags) || !validateTitle(threadTitle) || !validateBody(threadBody)}
              text={TextOnly('createThread')}
              loadingText={TextOnly('loading')}
            />
          </form>
        }
      </div>
      <div
        className='mb-wrapper filter-wrapper'
      >
        <LoaderButton
          type="submit"
          isLoading={false}
          disabled={loadingCreate}
          text={TextOnly(showTags ? 'close' : 'filterByTags')}
          loadingText={TextOnly('loading')}
          onClick={() => setShowTags((prev) => !prev)}
          className='white-space-nowrap marginright-20px margintop-15px'
        />
        <span
          className={`selected-tags margintop-15px ${selectedTags.length > 0 ? 'marginright-20px' : 'display-none'}`}
        >
          {selectedTags.length === 0 ? (
            <span className='color-gray-placehodler'>
              {TextOnly('tagsSelected')+'...'}
            </span>
          ) : (
            selectedTags
              .map((tagID) => {
                const tag = allTags.find((t: Tag) => t.tagID === tagID);
                return tag?.name ?? null;
              })
              .join(', ') + '.'
          )}
        </span>
        <button
          className={`textwrap-nowrap ${
            selectedTags.length > 0 ? 'c-btn margintop-15px' : 'clear-btn'
          }`}
          onClick={() => setSelectedTags([])}
        >
          {selectedTags.length > 0 ? TextOnly('clearTags') : ''}
        </button>
      </div>
      <div className={`form-container ${showTags ? 'show' : ''}`}>
        <div className="thread-form" onSubmit={() => null}>
          <label className="mb-label">{TextOnly('filterByTags')}:</label>
          <div className="form-group">
            {allTags?.map((tag: Tag) => (
              <label
                className="tag-checkbox mb-label u-cursor-pointer"
                key={tag.tagID}
              >
                <input
                  type="checkbox"
                  className='u-cursor-pointer'
                  id={tag.name}
                  name={tag.name}
                  value={tag.tagID}
                  checked={selectedTags.some((tagID) => tagID === tag.tagID)}
                  onChange={() => handleTagSelect(tag.tagID)}
                />
                {tag.name}
                <HelpTooltip
                  label={TextOnly(tag.body as keyof typeof en)}
                >
                  <div className='questionmark-tooltip'>
                    <span
                      className="c-btn__icon fal fa-question"
                    />
                  </div>
                </HelpTooltip>
              </label>
            ))}
          </div>
        </div>
      </div>
      {featuredThreadList.length > 0 && (
        <div className='padding-15px paddingbottom-0'>
          {featuredThreadList?.map((thread: Thread) => (
            <ThreadComponent
              key={thread.threadID}
              thread={thread}
              user={props.user}
              userVote={userVotes[thread.threadID]}
              userRole={props.user.application.MESSAGE_BOARD.mbRole}
              setIsDeleting={setIsDeleting}
              currentShop={props.currentShop}
            />
          ))}
        </div>
      )}
      <hr className='margintop-15px' />
      <div className='padding-15px'>
        <form onSubmit={(e)=>{
          e.preventDefault();
          handleDeepSearch();
        }}>
          <div className='display-flex'>
            <div>
              <LoaderButton
                text={TextOnly('search')}
                className='marginright-20px'
                type='submit'
                disabled={isLoadingThread || !searchThread}
              />
            </div>
            <div className='wrapper-string-input'>
              <input
                className={`string-input ${searchThread ? 'marginright-20px' : ''}`}
                type="text"
                id="search"
                name="search"
                value={searchThread}
                onChange={handleSearch}
                placeholder={TextOnly('searchThread')}
              />
            </div>
          </div>
        </form>
        <div className={searchThread ? 'u-margin-top-small' : 'margin-bottom--30px'} >
          <LoaderButton
            text={searchThread ? `${TextOnly('clearSearch')}` : ''}
            className={`textwrap-nowrap ${searchThread ? '' : 'clear-btn'}`}
            onClick={() => clearSearch()}
            disabled={isLoadingThread}
          />
        </div>
      </div>
      <div className='padding-15px'>
        <div className='display-flex alignitems-baseline'>
          <DropSort
            threadQuery={threadQuery}
            setThreadQuery={setThreadQuery}
            lekQuery={lekQuery}
            setLekQuery={setLekQuery}
            lekRated={lekRated}
            lekNewest={lekNewest}
            setThreadList={setThreadList}
            mainRatedThreadList={mainRatedThreadList}
            mainNewestThreadList={mainNewestThreadList}
            setRefetchThreadList={setRefetchThreadList}
          />
          <LoaderButton
            type="submit"
            isLoading={isLoadingThread && isSyncing}
            disabled={isLoadingThread}
            text={TextOnly('refresh')}
            loadingText={TextOnly('loading')}
            onClick={() => handleSync()}
            className='white-space-nowrap'
          />
        </div>
        {filteredThreadsSub?.map((thread: Thread) => (
          <ThreadComponent
            key={thread.threadID}
            thread={thread}
            user={props.user}
            userVote={userVotes[thread.threadID]}
            userRole={props.user.application.MESSAGE_BOARD.mbRole}
            setIsDeleting={setIsDeleting}
            currentShop={props.currentShop}
          />
        ))}
        <div className='display-flex-justify-content-center'>
          <button
            className="c-btn load-more-btn"
            disabled={isLoadingThread || !lekQuery || (searchThread && !lekSearch)}
            onClick={() => setRefetchThreadList((prevState: any) => !prevState)}
          >
            <div className="c-btn__inner">
              {isLoadingThread && !isSyncing && (
                <span>
                  <i className="fal fa-spinner-third spinning u-margin-right-small" />
                </span>
              )}
              {
                (lekQuery || (searchThread && lekSearch)) ?
                  isLoadingThread && !isSyncing ?
                    TextOnly('loading')
                    : TextOnly('loadMore')
                : TextOnly('noMoreThreads')
              }
            </div>
          </button>
        </div>
        {isLoadingThread && <Loading />}
      </div>
      <NotifyUser showModal={showNotifyModal} setShowModal={setShowNotifyModal} user={props.user}/>
      <OffensiveContentModal {...{showOffensiveModal, setShowOffensiveModal}}/>
    </div>
  );
};

export default MessageBoard;
