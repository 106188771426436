import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import { createDirectoryInterface } from '../../libs/db-lib';
import FieldRow from '../../components/FieldRow';
import LoaderButton from '../../components/LoaderButton';
import FileUpload from '../../components/FileUpload';
import { idValidate } from '../../libs/utils';
import ReactTable from 'react-table';
import { Text, TextOnly } from '../../components/Text';
import 'react-table/react-table.css';

export default class AddDirectoryName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adfsDomain: '',
      authorizationPath: '',
      caCert: '',
      caCertFile: '',
      clientID: '',
      clientSecret: '',
      directoryID: '',
      directoryName: '',
      directoryType: 'ADFS',
      radioGroup: 'textInput',
      redirectUrl: '',
      serverUrl: '',
      showModal: false,
      tenantID: '',
      tokenIssuerName: '',
      tokenPath: '',
      currentShop: props.currentShop,
    };
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    const data = this.state;

    const commonParamsOk =
      data.directoryName &&
      data.directoryID &&
      data.directoryID.length <= 30 &&
      idValidate(data.directoryID) &&
      data.tokenIssuerName;

    const additionalParamsOk =
      data.clientID && data.clientSecret && data.serverUrl;

    const adfsParamsOk =
      data.redirectUrl &&
      data.adfsDomain &&
      data.authorizationPath &&
      data.tokenPath &&
      data.caCert;

    const azureParamsOk = data.tenantID;

    let valid = false;
    switch (data.directoryType) {
      case 'ADFS':
        valid = commonParamsOk && additionalParamsOk && adfsParamsOk;
        break;
      case 'OKTA':
        valid = commonParamsOk && additionalParamsOk;
        break;
      case 'AZURE':
        valid = commonParamsOk && additionalParamsOk && azureParamsOk;
        break;
      default:
        valid = false;
    }

    return valid;
  }

  handleCancel = () => {
    document.querySelector('#addDirectory')?.classList.add('closed');
    setTimeout(() => {
      this.props.onDismiss();
    }, 350);
  };

  handleChange = (event) => {
    if (event.target.id === 'radioGroup') {
      this.setState({ caCert: '', caCertFile: '' });
    }
    if (event.target.id === 'directoryType' && event.target.value === 'ADFS') {
      this.setState({ radioGroup: 'textInput' });
    }
    if (event.target.id === 'directoryName') {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({
        [event.target.id]: event.target.value.trim(),
      });
    }
  };

  handleOnDrop(files) {
    this.setState({
      caCertFile: files[0].name,
    });
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsText = reader.result;
      this.setState({
        caCert: fileAsText,
      });
    };

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsText(files[0]);
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    let data = this.state;
    let additionalParams;

    switch (data.directoryType) {
      case 'ADFS':
        additionalParams = {
          clientID: data.clientID,
          clientSecret: data.clientSecret,
          adfsDomain: data.adfsDomain,
          redirectUrl: data.redirectUrl,
          authorizationPath: data.authorizationPath,
          tokenPath: data.tokenPath,
          serverUrl: data.serverUrl,
          caCert: data.caCert,
        };
        break;
      case 'OKTA':
        additionalParams = {
          clientID: data.clientID,
          clientSecret: data.clientSecret,
          serverUrl: data.serverUrl,
        };
        break;
      case 'AZURE':
        additionalParams = {
          clientID: data.clientID,
          clientSecret: data.clientSecret,
          serverUrl: data.serverUrl,
          tenantID: data.tenantID,
        };
        break;
      default:
        throw new Error("Can't submit form. No directory type was set.");
    }

    let result = await createDirectoryInterface(
      data.directoryID,
      data.currentShop.shopID,
      data.directoryType,
      data.directoryName,
      data.tokenIssuerName,
      additionalParams
    );
    if (result?.error) {
      this.props.handleShowAlert(TextOnly('error'), result.error, true);
    } else {
      let newDirectoryRec = {
        directoryID: data.directoryID,
        shopID: data.currentShop.shopID,
        friendlyName: data.directoryName,
        directoryType: data.directoryType,
        tokenIssuerName: data.tokenIssuerName,
        directoryState: 'ACTIVE',
        configParams: {
          clientID: data.clientID,
          clientSecret: data.clientSecret,
          adfsDomain: data.adfsDomain,
          redirectUrl: data.redirectUrl,
          authorizationPath: data.authorizationPath,
          tokenPath: data.tokenPath,
          serverUrl: data.serverUrl,
          tenantID: data.tenantID,
          caCert: data.caCert,
        },
      };

      this.props.handleAddDirectoryInterface(newDirectoryRec);
      this.props.onDismiss();
    }
  };

  render() {
    const { isOpen } = this.props;

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <Dialog
        isOpen={isOpen}
        onDismiss={this.handleCancel}
        className="c-modal-slider"
        id="addDirectory"
        aria-label={TextOnly('addDirectory')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={this.handleCancel}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1>
          <Text tid="addDirectory" />
        </h1>
        {this.state.currentShop && this.state.currentShop.shopName !== '' ? (
          <div className="c-modal__body l-container-sm">
            <form onSubmit={this.handleSubmit}>
              <div className="c-field">
                <FieldRow
                  title={TextOnly('directoryName')}
                  id="directoryName"
                  placeholder={TextOnly('directoryName')}
                  value={this.state.directoryName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="c-field">
                <FieldRow
                  title={TextOnly('directoryId')}
                  id="directoryID"
                  placeholder={TextOnly('directoryId')}
                  value={this.state.directoryID}
                  errorCondition={
                    !(this.state.directoryID.length <= 30) ||
                    !idValidate(this.state.directoryID)
                  }
                  maxLength="30"
                  errorMsg="ID must be less than 30 characters and contain only letters and numbers."
                  onChange={this.handleChange}
                />
              </div>
              <div className="c-field">
                <div className="c-select">
                  <select
                    id="directoryType"
                    value={this.state.directoryType}
                    onChange={this.handleChange}
                  >
                    <option key="1" value="ADFS">
                      ADFS
                    </option>
                    <option key="2" value="OKTA">
                      OKTA
                    </option>
                    <option key="3" value="AZURE">
                      AZURE
                    </option>
                  </select>
                </div>
              </div>
              <div className="c-field">
                <FieldRow
                  title={TextOnly('tokenIssuerName')}
                  id="tokenIssuerName"
                  placeholder={TextOnly('tokenIssuerName')}
                  maxLength={100}
                  value={this.state.tokenIssuerName}
                  onChange={this.handleChange}
                />
              </div>

              <div>
                <h3>
                  <Text tid="additionalFields" />
                </h3>
                <div className="c-field">
                  <FieldRow
                    title={TextOnly('clientId')}
                    id="clientID"
                    placeholder={TextOnly('clientId')}
                    value={this.state.clientID}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="c-field">
                  <FieldRow
                    title={TextOnly('clientSecret')}
                    id="clientSecret"
                    placeholder={TextOnly('clientSecret')}
                    value={this.state.clientSecret}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="c-field">
                  <FieldRow
                    title={TextOnly('serverUrl')}
                    id="serverUrl"
                    placeholder={TextOnly('serverUrl')}
                    value={this.state.serverUrl}
                    onChange={this.handleChange}
                  />
                  {this.state.directoryType === 'AZURE' && (
                    <div className="c-field">
                      <FieldRow
                        id="tenantID"
                        title="Tenant ID"
                        placeholder="Tenant ID"
                        value={this.state.tenantID}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </div>
                {this.state.directoryType === 'ADFS' ? (
                  <div>
                    <div className="c-field">
                      <FieldRow
                        title={TextOnly('adfsDomain')}
                        id="adfsDomain"
                        placeholder={TextOnly('adfsDomain')}
                        value={this.state.adfsDomain}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="c-field">
                      <FieldRow
                        title={TextOnly('redirectUrl')}
                        id="redirectUrl"
                        placeholder={TextOnly('redirectUrl')}
                        value={this.state.redirectUrl}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="c-field">
                      <FieldRow
                        title={TextOnly('authorizationPath')}
                        id="authorizationPath"
                        placeholder={TextOnly('authorizationPath')}
                        value={this.state.authorizationPath}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="c-field">
                      <FieldRow
                        title={TextOnly('tokenPath')}
                        id="tokenPath"
                        placeholder={TextOnly('tokenPath')}
                        value={this.state.tokenPath}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="c-field">
                      <label className="c-field__label">
                        <Text tid="caCert" />
                      </label>
                      <div controlId="radioSelect" onChange={this.handleChange}>
                        <input
                          type="radio"
                          id="radioGroup"
                          name="radioGroup"
                          value="textInput"
                          defaultChecked
                        />
                        <Text tid="textInput" />
                        <br />
                        <input
                          type="radio"
                          id="radioGroup"
                          name="radioGroup"
                          value="fileUpload"
                        />
                        <Text tid="fileUpload" />
                      </div>
                    </div>
                    {this.state.radioGroup === 'textInput' ? (
                      <div className="c-field">
                        <textarea
                          id="caCert"
                          rows={5}
                          placeholder={TextOnly('caCert')}
                          value={this.state.caCert}
                          onChange={this.handleChange}
                        />
                      </div>
                    ) : (
                      <div className="c-field">
                        <label className="c-field__label">
                          <Text tid="file" />{' '}
                        </label>
                        <span
                          className={
                            !this.state.caCertFile ? 'redText' : 'greenText'
                          }
                        >
                          {this.state.caCertFile || TextOnly('noneSelected')}
                        </span>
                        <FileUpload
                          handleOnDrop={this.handleOnDrop.bind(this)}
                          description="Upload directory items"
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>

              <br />
              <LoaderButton
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('add')}
                loadingText={TextOnly('addingDirectory')}
              />
            </form>
          </div>
        ) : null}
      </Dialog>
    );
  }
}
