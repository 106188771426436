import React from "react";
import { Dialog } from "@reach/dialog";
import { TextOnly } from "../../components/Text";
import LoaderButton from "../../components/LoaderButton";

const OffensiveContentModal = (
  {
    showOffensiveModal,
    setShowOffensiveModal
  } : {
    showOffensiveModal: boolean;
    setShowOffensiveModal: Function;
  }) =>(
  <Dialog
    isOpen={showOffensiveModal}
    className="c-modal"
    aria-label="offensiveModal"
  >
    <h1 className='margin-2rem'>{TextOnly('offensiveContent')}</h1>
    <div
      className="c-modal__body display-flex-justify-content-center"
    >
      <LoaderButton
        type="submit"
        isLoading={false}
        disabled={false}
        text={TextOnly('confirm')}
        loadingText=''
        onClick={() => {
          setShowOffensiveModal(false);
        }}
      />
    </div>
  </Dialog>
)


export default OffensiveContentModal;