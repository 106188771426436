import React from 'react';

const FieldRow = (props) => {
  return (
    <div className="c-field">
      <label htmlFor={props.id} className="c-field__label">
        {props.title}
      </label>
      <input
        className={`c-input ${props.errorCondition ? 'error' : ''}`}
        type="text"
        id={props.id}
        maxLength={props.maxLength || '50'}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.readOnly}
      />
      {props.errorCondition ? (
        <div className="c-field__error">{props.errorMsg}</div>
      ) : null}
    </div>
  );
};

export default FieldRow;
