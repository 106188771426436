import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { createUser } from '../libs/db-lib';
import { Text } from '../components/Text';

export default class UserCreate extends Component {
  constructor(props) {
    super(props);

    // Create User Record
    if (props.history.location.state && props.history.location.state.userName) {
      let userData = props.history.location.state;
      createUser(
        userData.userName,
        userData.firstName,
        userData.lastName,
        userData.email
      );
      this.props.userHasAuthenticated(true);
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.state = {
      isLoading: false,
      creatingUser: false,
    };
  }

  renderLander() {
    return (
      <div>
        <div>
          <div>
            <Text tid="welcome" />
          </div>
          <div>
            <Text tid="click" />{' '}
            <Link to="/myActivity">
              <Text tid="here" />
            </Link>{' '}
            <Text tid="toGetStarted" />
          </div>
        </div>
      </div>
    );
  }

  renderNotAuthenticated() {
    return (
      <div>
        <div>
          <Text tid="problemAuthenticating" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.isAuthenticated
          ? this.renderLander()
          : this.renderNotAuthenticated}
      </div>
    );
  }
}
