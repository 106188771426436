import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';
import { requestInvite, getSystemConfig } from '../libs/db-lib';
import { nameValidate, isValidEmail } from '../libs/utils';
import AlertModal from '../components/AlertModal';
import { Text, TextOnly } from '../components/Text';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { NavLink } from 'react-router-dom';

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isVerified: false,
      firstName: '',
      lastName: '',
      email: '',
      betaCode: '',
      verifyFirstName: false,
      verifyLastName: false,
      verifyEmail: false,
      verifyBetaCode: false,
      recaptcha: '',
      reloadRecaptcha: false,
      config: {},
      showModal: false,
    };
  }

  async componentDidMount() {
    let config = await getSystemConfig();
    this.setState({ config: config });

    // Refresh recaptcha token every 2 minutes
    setInterval(() => {
      this.setState({
        reloadRecaptcha: !this.state.reloadRecaptcha,
      });
    }, 120 * 1000);
  }

  validateForm() {
    let namesValid =
      nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);

    return (
      this.state.email.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      (!this.state.config.checkBetaCode || this.state.betaCode.length > 0) &&
      this.state.isVerified &&
      namesValid &&
      emailValid
    );
  }

  activateFirstNameVerify() {
    this.setState({ verifyFirstName: true });
  }

  activateLastNameVerify() {
    this.setState({ verifyLastName: true });
  }

  activateEmailVerify() {
    this.setState({ verifyEmail: true });
  }

  activateBetaCodeVerify() {
    this.setState({ verifyBetaCode: true });
  }

  verifyCaptcha(token) {
    if (token) {
      this.setState({
        isVerified: true,
        recaptcha: token,
      });
    }
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (event.target.id === 'email') {
      this.activateEmailVerify();
    }
    if (event.target.id === 'betaCode') {
      this.activateBetaCodeVerify();
    }
    if (event.target.id === 'firstName') {
      this.activateFirstNameVerify();
    }
    if (event.target.id === 'lastName') {
      this.activateLastNameVerify();
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.state.isVerified) {
      this.setState({ isLoading: true });
      let err = await requestInvite(
        this.state.email,
        this.state.firstName,
        this.state.lastName,
        window.localStorage.getItem('rcml-lang'),
        'OWNER',
        this.state.betaCode,
        this.state.recaptcha
      );
      if (err) {
        this.setState({
          alertMessage: err,
          showModal: true,
          isLoading: false,
        });
      } else {
        this.setState({
          alertMessage: TextOnly('inviteCodeSubmitted'),
          showModal: true,
          isLoading: false,
        });
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        alertMessage: TextOnly('pleaseProveHuman'),
        showModal: true,
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <div className="l-container-med">
        {'checkBetaCode' in this.state.config ? (
          <form onSubmit={this.handleSubmit}>
            <h2 className="u-text-center">
              <Text tid="requestInviteCode" />
            </h2>
            <div className="c-field">
              <label htmlFor="firstName" className="c-field__label">
                <Text tid="firstName" />
              </label>
              <input
                className={`c-input ${
                  this.state.verifyFirstName &&
                  !nameValidate(this.state.firstName)
                    ? 'error'
                    : ''
                }`}
                maxLength="50"
                id="firstName"
                type="text"
                placeholder={TextOnly('firstName')}
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              {this.state.verifyFirstName &&
              !nameValidate(this.state.firstName) ? (
                <div className="c-field__error">
                  <Text tid="firstNameHint" />
                </div>
              ) : null}
            </div>
            <div className="c-field">
              <label htmlFor="lastName" className="c-field__label">
                <Text tid="lastName" />
              </label>
              <input
                className={`c-input ${
                  this.state.verifyLastName &&
                  !nameValidate(this.state.lastName)
                    ? 'error'
                    : ''
                }`}
                maxLength="50"
                id="lastName"
                type="text"
                placeholder={TextOnly('lastName')}
                value={this.state.lastName}
                onChange={this.handleChange}
              />
              {this.state.verifyLastName &&
              !nameValidate(this.state.lastName) ? (
                <div className="c-field__error">
                  <Text tid="lastNameHint" />
                </div>
              ) : null}
            </div>
            <div className="c-field">
              <label htmlFor="email" className="c-field__label">
                <Text tid="email" />
              </label>
              <input
                className={
                  this.state.verifyEmail && !isValidEmail(this.state.email)
                    ? 'error'
                    : ''
                }
                maxLength="100"
                id="email"
                type="text"
                placeholder={TextOnly('email')}
                value={this.state.email}
                onChange={this.handleChange}
              />
              {this.state.verifyEmail && !isValidEmail(this.state.email) ? (
                <div className="c-field__error">
                  <Text tid="emailHint" />
                </div>
              ) : null}
            </div>
            {this.state.config.checkBetaCode ? (
              <div className="c-field">
                <label htmlFor="betaCode" className="c-field__label">
                  <Text tid="betaCode" />
                </label>
                <input
                  className={
                    this.state.verifyBetaCode &&
                    this.state.betaCode.length === 0
                      ? 'error'
                      : ''
                  }
                  maxLength="20"
                  id="betaCode"
                  type="text"
                  placeholder={TextOnly('betaCode')}
                  value={this.state.betaCode}
                  onChange={this.handleChange}
                />
                {this.state.verifyBetaCode &&
                this.state.betaCode.length === 0 ? (
                  <div className="c-field__error">
                    <Text tid="betaCodeCannotBeBlank" />
                  </div>
                ) : null}
              </div>
            ) : null}
            <LoaderButton
              type="submit"
              id="submit-button"
              className="c-btn-full"
              disabled={!this.validateForm()}
              isLoading={this.state.isLoading}
              text={TextOnly('requestInviteCode')}
              loadingText={TextOnly('requestingCode')}
            />
            <div className="u-text-center u-margin-top">
              <NavLink to="/signup" className="c-btn-link">
                <Text tid="backToRegister" />
              </NavLink>
            </div>
            <div className="captcha-container">
              <GoogleReCaptcha
                onVerify={this.verifyCaptcha.bind(this)}
                key={this.state.reloadRecaptcha}
              />
            </div>
          </form>
        ) : null}
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />
      </div>
    );
  }
}
