import React from 'react';
import { Text } from '../../../components/Text';
import RemoveVehicle from './RemoveVehicle';

const VehicleList: React.FC<any> = (props) => {
  const {
    allVehicles,
    currentShop,
    customer,
    onSubmit,
  } = props;
  const shopID = currentShop?.shopID;

  return (
    <div className="">
      <div>
        <span className="c-field__label"><Text tid='vehicles' /></span>
      </div>
      <div className="u-margin-top">
        { allVehicles?.map((vehicle: any) => (
            <div
              key={vehicle.vehicleID}
              className="display-flex u-margin-bottom"
            >
              <span>
                <RemoveVehicle
                  customer={customer}
                  shopID={shopID}
                  selectedVehicle={vehicle}
                  updateSingleCustomer={onSubmit}
                  isIcon={true}
                />
                {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
              </span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default VehicleList;
