import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import download from 'downloadjs';
import { updateDirectoryInterface } from '../../libs/db-lib';
import FieldRow from '../../components/FieldRow';
import LoaderButton from '../../components/LoaderButton';
import FileUpload from '../../components/FileUpload';
import { idValidate } from '../../libs/utils';
import ReactTable from 'react-table';
import { Text, TextOnly } from '../../components/Text';
import 'react-table/react-table.css';
import { Loading } from '../../components/Loading';

export default class EditSignupLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      alertTitle: '',
      adfsDomain: '',
      authorizationPath: '/adfs/oauth2/authorize',
      caCert: '',
      caCertFile: '',
      clientID: '',
      clientSecret: '',
      directoryID: '',
      directoryName: '',
      directoryState: '',
      directoryType: '',
      radioGroup: 'textInput',
      redirectUrl: '',
      serverUrl: '',
      tenantID: '',
      showModal: false,
      tokenIssuerName: '',
      tokenPath: '',
      currentShop: props.currentShop,
    };
  }

  async componentDidMount() {
    // Set initial state of fields
    let directoryRec = this.props.directoryRec;
    this.setState({
      directoryName: directoryRec.friendlyName,
      directoryState: directoryRec.directoryState,
      directoryType: directoryRec.directoryType,
      directoryID: directoryRec.directoryID,
      tokenIssuerName: directoryRec.tokenIssuerName,
    });

    let configParams = directoryRec.configParams;

    (() => {
      if (directoryRec.configParams) {
        this.setState({
          adfsDomain: configParams.adfsDomain,
          authorizationPath: configParams.authorizationPath,
          caCert: configParams.caCert,
          clientID: configParams.clientID,
          clientSecret: configParams.clientSecret,
          redirectUrl: configParams.redirectUrl,
          serverUrl: configParams.serverUrl,
          tenantID: configParams.tenantID,
          tokenPath: configParams.tokenPath,
        });
      }
    })();
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    const data = this.state;

    const commonParamsOk =
      data.directoryName &&
      data.directoryID &&
      data.directoryID.length <= 30 &&
      idValidate(data.directoryID) &&
      data.tokenIssuerName;

    const additionalParamsOk =
      data.clientID && data.clientSecret && data.serverUrl;

    const adfsParamsOk =
      data.redirectUrl &&
      data.adfsDomain &&
      data.authorizationPath &&
      data.tokenPath &&
      data.caCert;

    const azureParamsOk = data.tenantID;

    let valid = false;
    switch (data.directoryType) {
      case 'ADFS':
        valid = commonParamsOk && additionalParamsOk && adfsParamsOk;
        break;
      case 'OKTA':
        valid = commonParamsOk && additionalParamsOk;
        break;
      case 'AZURE':
        valid = commonParamsOk && additionalParamsOk && azureParamsOk;
        break;
      default:
        valid = false;
    }

    return valid;
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (event.target.id === 'directoryType') {
      this.setState({
        clientID: '',
        clientSecret: '',
        serverUrl: '',
        tenantID: '',
        adfsDomain: '',
        redirectUrl: '',
        authorizationPath: '',
        caCert: '',
      });
    }
  };

  handleOnDrop(files) {
    this.setState({
      caCertFile: files[0].name,
    });
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsText = reader.result;
      this.setState({
        caCert: fileAsText,
      });
    };

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsText(files[0]);
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let data = this.state;

    let result = await updateDirectoryInterface(
      data.directoryID,
      data.currentShop.shopID,
      data.directoryType,
      data.directoryState,
      data.directoryName,
      data.tokenIssuerName,
      {
        clientID: data.clientID,
        clientSecret: data.clientSecret,
        adfsDomain: data.adfsDomain,
        redirectUrl: data.redirectUrl,
        authorizationPath: data.authorizationPath,
        tokenPath: data.tokenPath,
        serverUrl: data.serverUrl,
        tenantID: data.tenantID,
        caCert: data.caCert,
      }
    );

    if (result?.error) {
      this.handleShowAlert(TextOnly('error'), result.error, true);
    } else {
      this.setState({
        isLoading: false,
      });
      let newDirectoryRec = {
        friendlyName: data.directoryName,
        directoryID: data.directoryID,
        directoryType: data.directoryType,
        directoryState: data.directoryState,
        tokenIssuerName: data.tokenIssuerName,
        configParams: {
          clientID: data.clientID,
          clientSecret: data.clientSecret,
          adfsDomain: data.adfsDomain,
          redirectUrl: data.redirectUrl,
          authorizationPath: data.authorizationPath,
          tokenPath: data.tokenPath,
          serverUrl: data.serverUrl,
          tenantID: data.tenantID,
          caCert: data.caCert,
        },
      };

      this.props.handleEditDirectoryInterface(newDirectoryRec);
      this.props.onDismiss();
    }
  };

  render() {
    const { isOpen, onDismiss } = this.props;

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <Dialog
        isOpen={isOpen}
        onDismiss={onDismiss}
        id="editDirectory"
        className="c-modal-slider"
        aria-label={TextOnly('editDirectory')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={onDismiss}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1>
          <Text tid="editDirectory" />
        </h1>
        {this.state.directoryID ? (
          <div className="c-modal__body l-container-sm">
            <form onSubmit={this.handleSubmit}>
              <div className="c-field">
                <label htmlFor="directoryName" className="c-field__label">
                  <Text tid="directoryName" />
                </label>
                <FieldRow
                  id="directoryName"
                  placeholder={TextOnly('directoryName')}
                  value={this.state.directoryName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="c-field">
                <label htmlFor="directoryID" className="c-field__label">
                  <Text tid="directoryId" />
                </label>
                <FieldRow
                  id="directoryID"
                  placeholder={TextOnly('directoryId')}
                  value={this.state.directoryID}
                  readOnly={true}
                  maxLength="30"
                  onChange={this.handleChange}
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="directoryType" />
                </label>
                <div className="c-select">
                  <select
                    id="directoryType"
                    value={this.state.directoryType}
                    onChange={this.handleChange}
                  >
                    <option key="1" value="ADFS">
                      ADFS
                    </option>
                    <option key="2" value="OKTA">
                      OKTA
                    </option>
                    <option key="3" value="AZURE">
                      AZURE
                    </option>
                  </select>
                </div>
              </div>

              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="directoryState" />
                </label>
                <div controlId="radioSelect" onChange={this.handleChange}>
                  <input
                    type="radio"
                    id="directoryState"
                    name="directoryState"
                    value="ACTIVE"
                    defaultChecked={this.state.directoryState === 'ACTIVE'}
                  />
                  <Text tid="ACTIVE" />
                  <br />
                  <input
                    type="radio"
                    id="directoryState"
                    name="directoryState"
                    value="DEACTIVATED"
                    defaultChecked={this.state.directoryState === 'DEACTIVATED'}
                  />
                  <Text tid="INACTIVE" />
                </div>
              </div>

              <div className="c-field">
                <label htmlFor="tokenIssuerName" className="c-field__label">
                  <Text tid="tokenIssuerName" />
                </label>
                <FieldRow
                  id="tokenIssuerName"
                  placeholder={TextOnly('tokenIssuerName')}
                  maxLength={100}
                  value={this.state.tokenIssuerName}
                  onChange={this.handleChange}
                />
              </div>

              <div>
                <h3>
                  <Text tid="additionalFields" />
                </h3>

                <div className="c-field">
                  <label htmlFor="clientID" className="c-field__label">
                    <Text tid="clientId" />
                  </label>
                  <FieldRow
                    id="clientID"
                    placeholder={TextOnly('clientId')}
                    value={this.state.clientID}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="c-field">
                  <label htmlFor="clientSecret" className="c-field__label">
                    <Text tid="clientSecret" />
                  </label>
                  <FieldRow
                    id="clientSecret"
                    placeholder={TextOnly('clientSecret')}
                    value={this.state.clientSecret}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="c-field">
                  <label htmlFor="serverUrl" className="c-field__label">
                    <Text tid="serverUrl" />
                  </label>
                  <FieldRow
                    id="serverUrl"
                    placeholder={TextOnly('serverUrl')}
                    value={this.state.serverUrl}
                    onChange={this.handleChange}
                  />
                </div>
                {this.state.directoryType === 'AZURE' && (
                  <div className="c-field">
                    <FieldRow
                      id="tenantID"
                      title="Tenant ID"
                      placeholder="Tenant ID"
                      value={this.state.tenantID}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
                {this.state.directoryType === 'ADFS' ? (
                  <div>
                    <div className="c-field">
                      <label htmlFor="adfsDomain" className="c-field__label">
                        <Text tid="adfsDomain" />
                      </label>
                      <FieldRow
                        id="adfsDomain"
                        placeholder={TextOnly('adfsDomain')}
                        value={this.state.adfsDomain}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="c-field">
                      <label htmlFor="redirectUrl" className="c-field__label">
                        <Text tid="redirectUrl" />
                      </label>
                      <FieldRow
                        id="redirectUrl"
                        placeholder={TextOnly('redirectUrl')}
                        value={this.state.redirectUrl}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="c-field">
                      <label
                        htmlFor="authorizationPath"
                        className="c-field__label"
                      >
                        <Text tid="authorizationPath" />
                      </label>
                      <FieldRow
                        id="authorizationPath"
                        placeholder={TextOnly('authorizationPath')}
                        value={this.state.authorizationPath}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="c-field">
                      <label htmlFor="tokenPath" className="c-field__label">
                        <Text tid="tokenPath" />
                      </label>
                      <FieldRow
                        id="tokenPath"
                        placeholder={TextOnly('tokenPath')}
                        value={this.state.tokenPath}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="c-field">
                      <label className="c-field__label">
                        <Text tid="caCert" />
                      </label>
                      <div controlId="radioSelect" onChange={this.handleChange}>
                        <input
                          type="radio"
                          id="radioGroup"
                          name="radioGroup"
                          value="textInput"
                          defaultChecked
                        />
                        <Text tid="textInput" />
                        <br />
                        <input
                          type="radio"
                          id="radioGroup"
                          name="radioGroup"
                          value="fileUpload"
                        />
                        <Text tid="fileUpload" />
                      </div>
                    </div>

                    {this.state.radioGroup === 'textInput' ? (
                      <>
                        <div className="c-field">
                          <textarea
                            id="caCert"
                            rows={5}
                            placeholder={TextOnly('caCert')}
                            value={this.state.caCert}
                            onChange={this.handleChange}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="c-field">
                        <label className="c-field__label">
                          <Text tid="file" />{' '}
                        </label>
                        <span
                          className={
                            !this.state.caCertFile ? 'redText' : 'greenText'
                          }
                        >
                          {this.state.caCertFile && (
                            <button className="c-btn-icon">
                              <div className="c-btn__inner">
                                <i className="c-btn__icon fal fa-upload" />
                              </div>
                            </button>
                          )}
                          {this.state.caCertFile || TextOnly('noneSelected')}
                        </span>
                        <FileUpload
                          handleOnDrop={this.handleOnDrop.bind(this)}
                          description="Upload directory items"
                        />
                      </div>
                    )}
                    <button
                      className="c-btn-icon"
                      disabled={!this.state.caCert}
                      onClick={(event) => {
                        event.preventDefault();
                        download(
                          this.state.caCert,
                          `${this.state.directoryID}-cert.crt`,
                          'application/x-x509-ca-cert'
                        );
                      }}
                    >
                      <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-download" />
                      </div>
                    </button>
                  </div>
                ) : null}
              </div>
              <br />
              <LoaderButton
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('update')}
                loadingText={TextOnly('updatingDirectory')}
              />
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </Dialog>
    );
  }
}
