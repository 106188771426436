import React, { useState } from 'react';
import { Text, TextOnly } from '../../../components/Text';
import HelpTooltip from '../../../components/HelpTooltip';
import BasicModal from '../../../components/BasicModal';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import { crmDeleteShopCustomer } from '../../../libs/db-lib';

const RemoveCustomer: React.FC<any> = ({
  customer,
  currentShop,
  onChange,
}) => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.remove-customer')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 250);
  };

  const handleDeleteCustomer = async () => {
    setRemoveLoading(true);
    const response = await crmDeleteShopCustomer(currentShop.shopID, customer.customerID, true)
    if (!response?.error) {
      toast.success(TextOnly('customerRemoved'),
        { containerId: 'standard' }
      );
      onChange({...customer, deleted: true});
    } else {
      toast.error(`${TextOnly('error')}: ${response.message ?? response.error}`, { containerId: 'standard' });
    }
    setRemoveLoading(false);
  }

  const modalBody = (handleCloseModal: ()=>void) => {
    return (
      <div>
        <div className='u-margin-left'><Text tid='confirmRemoveCustomer' /></div>
        <div>
          <div className='display-flex align-items-end'>
            <div className="u-text-right flex-basis-50 u-margin-right l-flex-end align-self-flex-start"><strong><span className="strong">{TextOnly('customer')}:</span></strong></div>
            <div className="u-text-left flex-basis-50 u-margin-left"><span>{customer?.firstName + ' ' + customer?.lastName}</span></div>
          </div>
        </div>
        <div>
          <div className='display-flex-justify-content-center align-items-center u-margin-top-large'>
            <LoaderButton
              text={TextOnly('cancel')}
              onClick={handleCloseModal}
              disabled={removeLoading}
              className="c-btn-outline"
            />&nbsp;
            <LoaderButton
              text={TextOnly('confirm')}
              onClick={handleDeleteCustomer}
              loadingText={TextOnly('removing')}
              isLoading={removeLoading}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <HelpTooltip label={TextOnly('removeCustomer')}>
        <button
          className="c-btn-icon-compact"
          onClick={() => setShowModal(true)}
        >
          <div className="c-btn__inner">
            <span className="c-btn__icon fal fa-trash-alt" />
          </div>
        </button>
      </HelpTooltip>
      <BasicModal
        isOpen={showModal}
        handleCancelModal={handleCancelModal}
        title={TextOnly("removeCustomer")}
        className="c-modal"
        isLoading={false}
        bodyContent={modalBody}
      />
    </div>
  );
};

export default RemoveCustomer;
