type TimeUnitStr = 'days' | 'weeks' | 'months' | 'years' | 'hours' | 'minutes' | 'seconds' |
                    'day' | 'week' | 'month' | 'year' | 'hour' | 'minute' | 'second';

export class DateTime {
  private date: Date;
  private isUTC: boolean;

  constructor(dateInput?: string | number | Date) {
    if (dateInput) {
      // This only works for dates with format "YYYY-MM-DD"
      if (typeof dateInput === 'string' && !!dateInput.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = dateInput.split('-').map((numStr) => Number(numStr));
        // Make a new Date object for the date string passed in set at midnight local time.
        this.date = new Date(year, month - 1, day, 0, 0, 0, 0); // months are 0-indexed.
      } else {
        // Make a new Date object for the date string passed in
        // If only a date string (e.g. '03/04/2024') is passed in, it will set the Date obj to midnight UTC time.
        this.date = new Date(dateInput);
      }
    } else {
      // date is current moment
      this.date = new Date();
    }
    this.isUTC = false;
  }

  // switches to UTC mode
  utc(): DateTime {
    this.isUTC = true;
    return this;
  }

  // Add time to the date (e.g., add(1, 'day'))
  add(value: number, unit: TimeUnitStr): DateTime {
    switch (unit) {
      case 'days':
      case 'day':
        if (this.isUTC) {
          this.date.setUTCDate(this.date.getUTCDate() + value);
        } else {
          this.date.setDate(this.date.getDate() + value);
        }
        break;
      case 'weeks':
      case 'week':
        if (this.isUTC) {
          this.date.setUTCDate(this.date.getUTCDate() + (value * 7));
        } else {
          this.date.setDate(this.date.getDate() + (value * 7));
        }
        break;
      case 'months':
      case 'month':
        if (this.isUTC) {
          this.date.setUTCMonth(this.date.getUTCMonth() + value);
        } else {
          this.date.setMonth(this.date.getMonth() + value);
        }
        break;
      case 'years':
      case 'year':
        if (this.isUTC) {
          this.date.setUTCFullYear(this.date.getUTCFullYear() + value);
        } else {
          this.date.setFullYear(this.date.getFullYear() + value);
        }
        break;
      case 'hours':
      case 'hour':
        if (this.isUTC) {
          this.date.setUTCHours(this.date.getUTCHours() + value);
        } else {
          this.date.setHours(this.date.getHours() + value);
        }
        break;
      case 'minutes':
      case 'minute':
        if (this.isUTC) {
          this.date.setUTCMinutes(this.date.getUTCMinutes() + value);
        } else {
          this.date.setMinutes(this.date.getMinutes() + value);
        }
        break;
      case 'seconds':
      case 'second':
        if (this.isUTC) {
          this.date.setUTCSeconds(this.date.getUTCSeconds() + value);
        } else {
          this.date.setSeconds(this.date.getSeconds() + value);
        }
        break;
    }
    return this;
  }

  // Subtract time from the date (e.g., subtract(1, 'days'))
  subtract(value: number, unit: TimeUnitStr): DateTime {
    return this.add(-value, unit);
  }

  // Format the date (UTC or local) into a string (e.g., 'YYYY-MM-DD')
  format(formatStr: string): string {
    const year = String(this.isUTC ? this.date.getUTCFullYear() : this.date.getFullYear());
    const month = String(this.isUTC ? this.date.getUTCMonth() + 1 : this.date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(this.isUTC ? this.date.getUTCDate() : this.date.getDate()).padStart(2, '0');
    const militaryHours = String(this.isUTC ? this.date.getUTCHours() : this.date.getHours()).padStart(2, '0');
    const twelveHours = String((this.isUTC ? this.date.getUTCHours() : this.date.getHours()) % 12 || 12).padStart(2, '0');
    const minutes = String(this.isUTC ? this.date.getUTCMinutes() : this.date.getMinutes()).padStart(2, '0');
    const seconds = String(this.isUTC ? this.date.getUTCSeconds() : this.date.getSeconds()).padStart(2, '0');

    // Replace format tokens with actual values
    // This is not a complete list of options, please add more when needed.
    return formatStr
      .replace('YYYY', year)
      .replace('MM', month)
      .replace('DD', day)
      .replace('HH', militaryHours)
      .replace('hh', twelveHours)
      .replace('mm', minutes)
      .replace('ss', seconds)
      .replace('A', Number(militaryHours) < 12 ? 'AM' : 'PM')
      .replace('a', Number(militaryHours) < 12 ? 'am' : 'pm');
  }

  // Get the underlying Date object
  toDate(): Date {
    return this.date;
  }

  // Return the year of the Date object
  year(): number {
    return this.date.getFullYear();
  }

  // Return an ISO string (UTC) representation
  toISOString(): string {
    return this.date.toISOString();
  }

  // Compare this date with another
  isBefore(other: DateTime): boolean {
    return this.date.getTime() < other.toDate().getTime();
  }

  isAfter(other: DateTime): boolean {
    return this.date.getTime() > other.toDate().getTime();
  }

  // Check if two dates are the same
  isSame(other: DateTime): boolean {
    return this.date.getTime() === other.toDate().getTime();
  }

  // Jump to the start of a unit (e.g., 'day', 'month', 'year')
  startOf(unit: 'day' | 'month' | 'year'): DateTime {
    switch (unit) {
      case 'day':
        if (this.isUTC) {
          this.date.setUTCHours(0, 0, 0, 0);
        } else {
          this.date.setHours(0, 0, 0, 0);
        }
        break;
      case 'month':
        if (this.isUTC) {
          this.date.setUTCDate(1);
          this.date.setUTCHours(0, 0, 0, 0);
        } else {
          this.date.setDate(1);
          this.date.setHours(0, 0, 0, 0);
        }
        break;
      case 'year':
        if (this.isUTC) {
          this.date.setUTCMonth(0, 1); // Months are 0-indexed
          this.date.setUTCHours(0, 0, 0, 0);
        } else {
          this.date.setMonth(0, 1); // Months are 0-indexed
          this.date.setHours(0, 0, 0, 0);
        }
        break;
    }
    return this;
  }

  // Jump to the end of a unit (e.g., 'day', 'month', 'year')
  endOf(unit: 'day' | 'month' | 'year'): DateTime {
    switch (unit) {
      case 'day':
        if (this.isUTC) {
          this.date.setUTCHours(23, 59, 59, 999);
        } else {
          this.date.setHours(23, 59, 59, 999);
        }
        break;
      case 'month':
        if (this.isUTC) {
          this.date.setUTCMonth(this.date.getUTCMonth() + 1, 0); // Last day of the current month (months are 0-indexed)
          this.date.setUTCHours(23, 59, 59, 999);
        } else {
          this.date.setMonth(this.date.getMonth() + 1, 0); // Last day of the current month (months are 0-indexed)
          this.date.setHours(23, 59, 59, 999);
        }
        break;
      case 'year':
        if (this.isUTC) {
          this.date.setUTCMonth(12, 0); // December 31 (months are 0-indexed)
          this.date.setUTCHours(23, 59, 59, 999);
        } else {
          this.date.setMonth(12, 0); // December 31 (months are 0-indexed)
          this.date.setHours(23, 59, 59, 999);
        }
        break;
    }
    return this;
  }
}