import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { getUserInteraction, mbStopEmailNotification, admoFunction, admoBlocking } from '../../libs/db-lib';
import Header from '../../components/Header';
import { Thread, Post } from './types';
import { toast } from 'react-toastify';
import { Text, TextOnly } from '../../components/Text';
import { formatDateTime } from '../../libs/utils';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../../components/LoaderButton';
import { ThreadComponent } from './Thread';
import { PostComponent } from './Post';
import { Loading } from '../../components/Loading';
import GoBackArrow from './GoBackArrow';
import './MessageBoard.css';
import HelpTooltip from '../../components/HelpTooltip';
import ConfirmModal from '../../components/ConfirmModal';

interface RouteParams {
  userName: string;
}

interface UserApp {
  userName: string;
  mbRole: string;
  stopEmailNotification?: boolean;
}

export const UserInteraction = (props: any) => {
  // #region Props variables
  const { user, userVotes, setIsDeleting, setUserVotes, currentShop  } = props;
  const shopID = currentShop.shopID;
  let { userName } = useParams<RouteParams>();
  if (!userName) {
    userName = user.userName
  };
  const userRole = user.application.MESSAGE_BOARD.mbRole;
  const isUser = userName === user.userName;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stopEN = !!queryParams.get('stopEN');

  // #endregion
  // #region State Variables
  const [userThreads, setUserThreads] = useState<Thread[]>([]);
  const [currentThread, setCurrentThread] = useState<Thread>();
  const [isLoadingInteraction, setIsLoadingInteraction] =
    useState<boolean>(true);
    const [userError, setUserError] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSEN, setIsLoadingSEN] = useState<boolean>(false);
  const [showModal, setShowModal] =
    useState<boolean>(false);
  const [showSENModal, setShowSENModal] =
    useState<boolean>(stopEN);
  const [userTable, setUserTable] = useState<string>(TextOnly('threads'));
  const [userPosts, setUserPosts] = useState<Post[]>([]);
  const [userApplication, setUserApplication] = useState<UserApp>();
  const [currentPost, setCurrentPost] = useState<Post>();
  const [userTableData, setUserTableData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  // #endregion

  const noContCol = [
    {
      Header: TextOnly('title'),
      accessor: 'title',
      isVisible: (userTable === TextOnly('threads')),
      id: 'title'
    },
    {
      Header: TextOnly('body'),
      accessor: 'body',
      isVisible: (userTable === TextOnly('comments')),
      id: 'body'
    },
  ]
  const tableCol = [
    {
      Header: TextOnly('title'),
      accessor: 'title',
      isVisible: (userTable === TextOnly('threads')),
      maxWidth: 800,
      id: 'title',
      Cell: (row: any) => {
        return (
          <h2>
            <Link
              to={`/messageBoard/thread/${row.original.threadID}`}
              className='content-link'
            >
              {row.original.title}
            </Link>
          </h2>
        );
      },
    },
    {
      Header: TextOnly('body'),
      accessor: 'body',
      isVisible: (userTable === TextOnly('comments')),
      maxWidth: 800,
      id: 'body',
      Cell: (row: any) => {
        return (
          <h2>
            <Link
              to={`/messageBoard/thread/${row.original.threadID}`}
              className='content-link'
            >
              {row.original.body !== "" ? row.original.body : '**'+TextOnly('contentDeleted')+'**'}
            </Link>
          </h2>
        );
      },
    },
    {
      Header: <span className={`fas fa-eye`} />,
      accessor: 'views',
      isVisible: (userTable === TextOnly('threads')),
      id: 'views',
      width: 50,
      Cell: ({ value }: { value: any }) => (
        <div className='ui-info'>{value}</div>
      ),
    },
    {
      Header: <span className={`fas fa-thumbs-up`} />,
      accessor: 'likes',
      id: 'likes',
      width: 50,
      Cell: ({ value }: { value: any }) => (
        <div className='ui-info'>{value}</div>
      ),
    },
    {
      Header: <span className={`fas fa-thumbs-down`} />,
      accessor: 'dislikes',
      id: 'dislikes',
      width: 50,
      Cell: ({ value }: { value: any }) => (
        <div className='ui-info'>{value}</div>
      ),
    },
    {
      Header: <span className={`fas fa-reply-all`} />,
      accessor: 'replies',
      id: 'replies',
      width: 50,
      Cell: ({ value }: { value: any }) => (
        <div className='ui-info'>{value}</div>
      ),
    },
    {
      Header: <span className={`fas fa-exclamation`} />,
      accessor: 'reports',
      id: 'reports',
      isVisible: userRole === 'ADMIN' || userRole === 'MODERATOR',
      width: 50,
      Cell: ({ value }: { value: any }) => (
        <div className='ui-info'>{value}</div>
      ),
    },
    {
      Header: <span className={`fas fa-calendar`} />,
      accessor: 'createdOn',
      id: 'createdOn',
      width: 120,
      Cell: ({ value }: { value: any }) => {
        const createdOn = formatDateTime(value);
        const [date, time] = createdOn.split(' at ');
        return (
          <div className='ui-info' title={time}>
            {date}
          </div>
        );
      },
    },
    {
      Header: <span className={`fa fa-gear`} />,
      accessor: 'createdOn',
      isVisible: userRole === 'ADMIN' || userRole === 'MODERATOR',
      id: 'gear',
      width: 30,
      Cell: (row:any) => {
        return (
          <span
            className={`u-cursor-pointer fa fa-gear`}
            onClick={()=>{
              setShowModal(true);
              if(userTable === TextOnly('threads')){
                setCurrentThread(row.original);
              } else{ //userTable===TextOnly('comments')
                setCurrentPost(row.original);
              }
            }}
          />
        );
      },
    },
  ];


  // #region Handle Functions
  const handleUpdate = (content:any) =>{
    if(content.contentID){ //if content === post
      const userPostsAux = [...userPosts];
      userPostsAux.map(post =>{
        if(post.postID===content.postID) post = content;
      });
      setUserPosts(userPostsAux);
      setCurrentPost(content);
    } else { //if content === thread
      const userThreadsAux = [...userThreads];
      userThreadsAux.map(thread =>{
        if(thread.threadID===content.threadID) {
          return content;
        };
        return thread;
      });
      setUserThreads(userThreadsAux);
      setCurrentThread(content);
    }
  }
  const handleAdmoFunction = async (functionType:string) => {
    setIsLoading(true)
    let contentID: any;
    let postID: any;
    let contentType: any;
    if(userTable===TextOnly('threads') && currentThread){
      contentID = currentThread.threadID;
      postID = undefined;
      contentType = 'thread';
    } else if(userTable===TextOnly('comments') && currentPost){
      contentID = currentPost.contentID;
      postID = currentPost.postID;
      contentType = 'post';
    } else {
      contentID = undefined;
      postID = undefined;
      contentType = undefined;
    }
    const response = await admoFunction(
      contentID,
      postID,
      contentType,
      functionType,
      shopID,
    );
    if (!response?.error) {
      handleUpdate(response.Attributes);
      const toastMessage = `Content ${
        functionType === 'BLOCK' ?
          response.Attributes.blocked ? 'blocked' : 'unblocked'
        : response.Attributes.locked ? 'locked' : 'unlocked'
      }`
      toast.success(toastMessage,
      {
        containerId: 'standard',
      });
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoading(false);
  }
  const handleAdmoBlocking = async (newMbRole:string) => {
    setIsLoading(true);
    const response = await admoBlocking(userName, newMbRole, shopID);
    if(!response?.error){
      const userAppAux:UserApp = {
        userName: userApplication!.userName,
        mbRole: userApplication!.mbRole,
      };
      userAppAux.mbRole = response === 'BLOCKED' ? 'MUTED' : 'READONLY';
      setUserApplication(userAppAux);
      toast.success(`User ${response}`,
      {
        containerId: 'standard',
      });
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoading(false);
    return response;
  }
  const handleSetTable = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    switch (event.target.value) {
      case TextOnly('threads'):
        setUserTableData(userThreads);
        setUserTable(TextOnly('threads'));
        break;

      case TextOnly('comments'):
        setUserTableData(userPosts);
        setUserTable(TextOnly('comments'));
        break;

      default:
        break;
    }
  };
  const handleStopEmailNotification = async (
    stopEmailNotification: boolean
  ) => {
    setIsLoadingSEN(true);
    const response = await mbStopEmailNotification(
      stopEmailNotification,
      shopID,
    );
    if (!response?.error) {
      setShowSENModal(false);
      userApplication!.stopEmailNotification = stopEmailNotification;
      setUserApplication(userApplication);
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoadingSEN(false);
  };
  // #endregion

  // #region useEffect
  const getInteraction = async () => {
    setIsLoadingInteraction(true);
    const response = await getUserInteraction(userName, shopID);
    if (!response?.error) {
      setUserThreads(response.userThreads);
      setUserPosts(response.userPosts);
      setUserTableData(response.userThreads);
      setUserTable(TextOnly('threads'));
      setUserApplication(response.userInfo);
    } else {
      setUserError(response.error);
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoadingInteraction(false);
  };
  useEffect(() => {
    getInteraction();
  }, []);
  // #endregion

  if(isLoadingInteraction) return <Loading/>
  else if (userError) {
    return <p>{
      TextOnly(userError==='User does not exist' ? 'userNotExist' : 'errorTryAgain')
      }</p>
  } else return (
    <div className="u-padding-bottom-50px">
      <Header title={`${TextOnly('userInteraction')} - ${userName}`} comp={<GoBackArrow/>} />
      <div className='l-display-inline-flex alignitems-baseline u-width-100-percent'>
        <div className='u-width-100-percent l-flex-between'>
          <div className="c-field width-auto">
            <div
              className="c-select width-fitcontent u-cursor-pointer"
              onClick={() => {
                document.getElementById('sort')?.click();
              }}
            >
              <select className='zindex-1' id="sort" onChange={handleSetTable}>
                <option value="" disabled>
                  {'Sort by...'}
                </option>
                <option value={TextOnly('threads')}>{TextOnly('threads')}</option>
                <option value={TextOnly('comments')}>{TextOnly('comments')}</option>
                <option value="Likes" disabled>
                  {'Coming Soon: Likes'}
                </option>
                <option value="Dislikes" disabled>
                  {'Coming Soon: Dislikes'}
                </option>
              </select>
            </div>
          </div>
          { isUser &&
            <HelpTooltip
              label={TextOnly('whenIsEmailSend')}
            >
              <div>
                  <LoaderButton
                    type="button"
                    text={ !(userApplication?.stopEmailNotification) ?
                      TextOnly('stopEmailNotification')
                      : TextOnly('enableEmailNotification')}
                    isLoading={false}
                    loadingText={TextOnly('loading')+'...'}
                    onClick={()=>setShowSENModal(true)}
                  />
              </div>
            </HelpTooltip>
          }
        </div>
        { (userName!==user.userName && (userRole === 'MODERATOR' || userRole === 'ADMIN') && (userApplication?.mbRole !== 'ADMIN' && userApplication?.mbRole !== 'MODERATOR'))  &&
          <>
            { isLoading ?
                <div className='height-80px'><Loading /></div>
              :
              userApplication?.mbRole !== 'MUTED' ?
                <button
                  className='red-btn marginleft-15px'
                  onClick={()=>setShowConfirmModal(true)}
                  disabled={isLoading}
                >Block User
                </button>
                :
                <button
                  className='red-btn marginleft-15px'
                  onClick={()=>setShowConfirmModal(true)}
                  disabled={isLoading}
                >Unblock User
                </button>
            }
          </>
        }
      </div>
      {userApplication?.mbRole === 'MUTED' &&
        <p
          className="u-margin-bottom-large"
          dangerouslySetInnerHTML={{
            __html: TextOnly('currentlyMuted', {userName: `<span class="color-aa-red u-font-weight-bold">${userName}</span>`}),
          }}
        />
      }
      <div>
        <h2>{`${userTable}:`}</h2>
        <ReactTable
          columns={
            userTableData.length > 0
              ? tableCol.filter(column => column.isVisible !== false)
              : noContCol.filter(column => column.isVisible !== false)
          }
          data={
            userTableData.length > 0
              ? userTableData
              : [
                  {
                    title: `${TextOnly(props.user.userName === userName ? 'youNoThreads' : 'userNoThreads', {'userName': userName})}`,
                    body: `${TextOnly(props.user.userName === userName ? 'youNoComments' : 'userNoComments', {'userName': userName})}`,
                  },
                ]
          }
          className="-highlight u-margin-top-large tablelayout"
          showPaginationTop={true}
          previousText={TextOnly('previousPage')}
          nextText={TextOnly('nextPage')}
          pageText={TextOnly('page')}
          ofText={TextOnly('of')}
          rowsText={TextOnly('rows')}
          noDataText={TextOnly('noUsersAssociated')}
          defaultPageSize={5}
          getTheadThProps={() => {
            return {
              style: {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              },
            };
          }}
        />
      </div>
      <Dialog
        isOpen={showModal}
        className="c-modal"
        aria-label="ama"
        onDismiss={()=>setShowModal(false)}
      >
        { isLoading ?
          <div className='height-200px'><Loading /></div> :
          <>
            <button
              className="c-btn-icon c-modal__close-aux"
              onClick={()=>setShowModal(false)}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <h1 className="c-modal__heading">
              {userTable}:
            </h1>
            <div className="c-modal__body">
              {(currentThread && userTable===TextOnly('threads')) &&
                <ThreadComponent
                  thread={currentThread as Thread}
                  user={user}
                  userVote={userVotes[currentThread.threadID]}
                  userRole={props.userRole}
                  setIsDeleting={setIsDeleting}
                  showBody={true}
                  currentShop={currentShop}
                />
              }
              {(currentPost && userTable===TextOnly('comments')) &&
                <PostComponent
                  post={currentPost as Post}
                  user={user}
                  userVotes={userVotes}
                  setUserVotes={setUserVotes}
                  currentShop={currentShop}
                />
              }
              <br/>
              <div className='display-flex-justify-content-space-around'>
                <LoaderButton
                  type="button"
                  text={TextOnly('delete')}
                  isLoading={false}
                  loadingText=''
                  onClick={()=>null}
                />
                <LoaderButton
                  type="button"
                  text={
                    userTable === TextOnly('threads') ?
                      currentThread?.blocked !== true ?
                        TextOnly('block')
                        : TextOnly('unblock')
                    : currentPost?.blocked !== true ?
                        TextOnly('block')
                        : TextOnly('unblock')
                  }
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleAdmoFunction('BLOCK')}
                />
                <LoaderButton
                  type="button"
                  text={
                    userTable === TextOnly('threads') ?
                      currentThread?.locked !== true ?
                        TextOnly('lock')
                        : TextOnly('unlock')
                    : currentPost?.locked !== true ?
                        TextOnly('lock')
                        : TextOnly('unlock')
                  }
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleAdmoFunction('LOCK')}
                />
              </div>
            </div>
          </>
        }
      </Dialog>
      <Dialog
        isOpen={showSENModal}
        className="c-modal"
        aria-label="confirmDelete"
        onDismiss={() => setShowSENModal(false)}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={() => setShowSENModal(false)}
          disabled={isLoadingSEN}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          { !(userApplication?.stopEmailNotification) ?
            <Text tid='stopEmailNotification' />
          : <Text tid='enableEmailNotification' />
          }
        </h1>
        <div className='c-modal__body'>
          <p>
            {!(userApplication?.stopEmailNotification) ?
              TextOnly('noLongerEN')
              : TextOnly('whenIsEmailSend')}
          </p>
          <p>
            <button
              className="c-btn-outline"
              onClick={() => setShowSENModal(false)}
              disabled={isLoadingSEN}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={isLoadingSEN}
              disabled={isLoadingSEN}
              text={TextOnly('confirm')}
              loadingText={TextOnly('loading')}
              onClick={()=>handleStopEmailNotification(!userApplication?.stopEmailNotification)}
              className="c-btn"
            />
          </p>
        </div>
      </Dialog>
      <ConfirmModal
        showConfirmModal={showConfirmModal}
        closeModal={()=>setShowConfirmModal(false)}
        textModal={
          userApplication?.mbRole !== 'MUTED' ?
          `Are you sure you want to BLOCK ${userApplication?.userName}?`
          : `Are you sure you want to UNBLOCK ${userApplication?.userName}?`
        } //This text is only for Admin/Moderator
        onConfirm={userApplication?.mbRole !== 'MUTED'
          ? ()=>handleAdmoBlocking('BLOCK')
          : ()=>handleAdmoBlocking('UNBLOCK')
        }
      />
    </div>
  );
};
