import React from 'react';
import './MessageBoard.css'
import { TextOnly } from '../../components/Text';

export const DropSort: React.FC<any> = (props) => {
  const {
    threadQuery,
    setThreadQuery,
    setLekQuery,
    lekRated,
    lekNewest,
    setThreadList,
    mainRatedThreadList,
    mainNewestThreadList,
    setRefetchThreadList,
  } = props;
  // #region State Variables
  // #endregion
  // #region Handle Functions
  const handleSetQuery = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setThreadQuery(event.target.value);
    switch (event.target.value) {
      case 'likes':
        setLekQuery(lekRated);
        if (mainRatedThreadList.length === 0)
          setRefetchThreadList((prevState: any) => !prevState);
        else setThreadList(mainRatedThreadList);
        break;

      case 'time':
        setLekQuery(lekNewest);
        if (mainNewestThreadList.length === 0)
          setRefetchThreadList((prevState: any) => !prevState);
        else setThreadList(mainNewestThreadList);
        break;

      case 'noteworthyIndex':
        //! Coming Soon...
        break;

      default:
        break;
    }
  };
  // #endregion

  // #region UseEffect
  // #endregion

  return (
    <div className="c-field width-auto">
      <div
        className="c-select width-fitcontent u-cursor-pointer"
        onClick={() => {
          document.getElementById('sort')?.click();
        }}
      >
        <select
          id="sort"
          onChange={handleSetQuery}
          value={threadQuery}
          className='zindex-1'
        >
          <option value="" disabled>
            {TextOnly('sortBy')}
          </option>
          <option value="time">{TextOnly('newest')}</option>
          <option value="likes">{TextOnly('highestRated')}</option>
          <option value="noteworthyIndex" disabled>
            {'Coming Soon: '+TextOnly('noteworthy')}
          </option>
        </select>
      </div>
    </div>
  );
};
