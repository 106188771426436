import React, { useState } from "react";
import LoaderButton from '../LoaderButton';
import { Text, TextOnly } from "../Text";
import ToolTip from "../ToolTip";
import { sendPhoneVerificationCode, submitPhoneVerificationCode } from "../../libs/db-lib";
import { toast } from 'react-toastify';
import BasicPhoneInput from "../BasicPhoneInput";


interface PhoneInputWithVerificationProps {
  user: any;
  currentShop: any;
  phoneNumber: string;
  setPhoneNumber: any;
  optOut?: boolean;
  needsVerification?: boolean;
  fetchUser?: Function;
}

const PhoneInputWithVerification: React.FC<PhoneInputWithVerificationProps> = ({ user, currentShop, phoneNumber, setPhoneNumber, optOut=false, fetchUser=(()=>null) }) => {
  const [isValidPhone, setIsValidPhone] = useState <boolean> (false);
  const [isSendingCode, setIsSendingCode] = useState <boolean> (false);
  const [isSubmittingCode, setIsSubmittingCode] = useState <boolean> (false);
  const [codeSent, setCodeSent] = useState <boolean> (false);
  const [verificationCode, setVerificationCode] = useState <string> ("");

  const sendVerificationCode = async () => {
    setIsSendingCode(true);
    const result = await sendPhoneVerificationCode(phoneNumber);
    if (result.error) {
      toast.error(result.error, {
        containerId: 'standard',
      });
    } else {
      setCodeSent(true);
      toast.success(TextOnly('phoneVerificationCodeSent', {phone: phoneNumber}),
        { containerId: 'standard' }
      );
    }
  }

  const submitVerificationCode = async () => {
    setIsSubmittingCode(true);
    const code = verificationCode;
    const result = await submitPhoneVerificationCode(code);
    if (result.error) {
      setIsSubmittingCode(false);
      toast.error(result.error, {
        containerId: 'standard',
      });
    } else {
      setIsSubmittingCode(false);
      setCodeSent(false);
      fetchUser();
      toast.success(TextOnly('phoneHasBeenVerified', {phone: phoneNumber}),
        { containerId: 'standard' }
      );
    }
  }

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
  };

  return (
    <div className="form-field">
      <ToolTip text={(optOut && isValidPhone) ? TextOnly('phoneIsOptOut') : ''}>
        <BasicPhoneInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setIsValidPhone={setIsValidPhone}
          country={currentShop?.country}
        />
        <div className={`verify u-text-warning ${(isValidPhone && ((phoneNumber !== user?.phoneNumber) || !user?.phoneVerified)) ? 'display' : 'hide'}`}>
          { !codeSent ?
            <>
              <Text tid="toCompletePhoneNumberVerification" />
              <div>
                <LoaderButton
                  type="button"
                  isLoading={isSendingCode}
                  loadingText={TextOnly('sendingCode')}
                  text={TextOnly('sendCode')}
                  disabled={!isValidPhone || isSendingCode}
                  onClick={ (e) => {
                    e.preventDefault();
                    sendVerificationCode();
                  }}
                />
              </div>
            </>
          :
            <>
              <div className={`margin-bottom-2rem u-margin-top`}>
                <input
                  id="verifyCode"
                  type="text"
                  className={'c-input'}
                  maxLength={10}
                  placeholder={TextOnly('verificationCode')}
                  onChange={(e) => {
                    handleChangeCode(e);
                  }}
                />
              </div>
              <div>
                <LoaderButton
                  type="button"
                  isLoading={isSubmittingCode}
                  loadingText={TextOnly('submittingCode')}
                  text={TextOnly('submitCode')}
                  disabled={!isValidPhone}
                  onClick={ (e) => {
                    e.preventDefault();
                    submitVerificationCode();
                  }}
                />
              </div>
            </>
          }
        </div>
        { (optOut && isValidPhone) &&
          <span className="u-text-error">
            {TextOnly('pleaseOptIn')}
          </span>
        }
      </ToolTip>
    </div>
  );
};

export default PhoneInputWithVerification;
