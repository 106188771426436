import React, { Component } from 'react';
import './NotFound.css';
import { Text } from '../components/Text';

export default class OEMSignup extends Component {
  render() {
    return (
      <>
        <h2 className="u-text-center">
          <Text tid="comingSoon" />
        </h2>
      </>
    );
  }
}
