import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

export default class FileUpload extends Component {
  render() {
    return (
      <Dropzone multiple={false} onDrop={this.props.handleOnDrop}>
        {({ getRootProps, getInputProps }) => (
          <div className="c-upload-box" {...getRootProps()}>
            <input {...getInputProps()} className="c-upload-box__input" />
            <span className="c-upload-box__p">{this.props.description}</span>
          </div>
        )}
      </Dropzone>
    );
  }
}
