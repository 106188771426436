// src/Tabs.tsx

import React, { useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
interface TabsProps {
  children: ReactNode;
  className?: string;
  listClassName?: string;
  contentClassName?: string;
  defaultActiveKey?: string;
}

const Tabs: React.FC<TabsProps> = ({ children, className, defaultActiveKey, contentClassName, listClassName }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeKey: string = queryParams.get('activeKey') || '';

  const handleClick = (index: number) => {
    setActiveTab(index);
  };

  const getIndexByKey = (key: string) => {
    let defaultIndex;
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child) && key === child.props?.eventKey) {
        defaultIndex = index;
      }
    })
    return defaultIndex;
  }

  const [activeTab, setActiveTab] = useState(
    activeKey ? getIndexByKey(activeKey) :
    defaultActiveKey ? getIndexByKey(defaultActiveKey) :
    0
  );

  return (
    <div className={`tabs ${className ?? ''}`}>
      <div className={`tab-list ${listClassName ?? ''}`}>
        {React.Children.map(children, (child, index) =>
          React.isValidElement(child) ? (
            <button
              className={`tab ${child.props?.className ?? ''} ${activeTab === index ? 'active' : ''}`}
              onClick={() => handleClick(index)}
            >
              <div>{child.props.label}</div>
            </button>
          ) : null
        )}
      </div>
      <div className={`tab-content ${contentClassName ?? ''}`}>
        {React.Children.map(children, (child, index) =>
          React.isValidElement(child) ? (
            <div className={`tab-panel ${activeTab === index ? 'active' : ''}`}>
              {child.props.children}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Tabs;