import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../libs/utils';
import {
  createPost,
  deleteContent,
  createVote,
  admoFunction
} from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import { Loading } from '../../components/Loading';
import { CHECKBOX_MIN_LENGTH, POST_BODY_MAX_LENGTH, POST_BODY_MIN_LENGTH, Post, REPORT_BODY_MAX_LENGTH, REPORT_BODY_MIN_LENGTH } from './types';
import { toast } from 'react-toastify';
import { Dialog } from '@reach/dialog';
import { TextOnly } from '../../components/Text';
import OffensiveContentModal from './OffensiveContentModal';
import loading from '../../assets/icons/loading.svg'
import './Thread.css';
import './MessageBoard.css';


type Checkbox = {
  label: string;
  checked: boolean;
};


export const PostComponent = ({
  post,
  user,
  userVotes,
  setUserVotes,
  handleUpdateThreadPosts,
  removeReply,
  currentShop,
  isResponse = false,
  isNotification = false,
}: {
  post: Post;
  user: any;
  userVotes: any;
  setUserVotes: Function;
  handleUpdateThreadPosts?: Function;
  removeReply?: Function;
  currentShop: any;
  isResponse?: boolean;
  isNotification?: boolean;
}) => {
  const postID = post.postID;
  const userID = user.userID;
  const shopID = currentShop.shopID;
  const userRole = user.application.MESSAGE_BOARD.mbRole;

  const initialCheckboxes: Checkbox[] = [
    { label: TextOnly('mbSpam'), checked: false },
    { label: TextOnly('mbOffensive'), checked: false },
    { label: TextOnly('mbViolation'), checked: false },
    { label: TextOnly('mbTrolling'), checked: false },
    { label: TextOnly('mbMisinformation'), checked: false },
    { label: TextOnly('mbInappropriate'), checked: false },
    { label: TextOnly('mbDuplicate'), checked: false },
    { label: TextOnly('mbPrivacy'), checked: false },
    { label: TextOnly('mbOther'), checked: false },
  ];

  // #region State Variables
  const [checkboxes, setCheckboxes] = useState<Checkbox[]>(initialCheckboxes);
  const [postLocal, setPostLocal] = useState<Post>(post);
  const [postBody, setPostBody] = useState<string>('');
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [gotDeleted, setGotDeleted] = useState<boolean>(false);
  const [showResponses, setShowResponses] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [postPosts, setpostPosts] = useState<Post[]>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showModalReport, setShowModalReport] = useState<boolean>(false);
  const [reportBody, setReportBody] = useState<string>('');
  const [isLoadingVote, setIsLoadingVote] = useState<boolean>(false);
  const [showConfirmedReport, setShowConfirmedReport] = useState<boolean>(false);
  const [showOffensiveModal, setShowOffensiveModal] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deleteReason, setDeleteReason] = useState<string>('');
  const [showOriginal, setShowOriginal] = useState<boolean>(false);
  // #endregion

  // #region Ref Variables
  // #endregion

  // #region handle functions
  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
  };
  const validateReportCheckboxes = (checkboxes: any) =>{
    if(!Array.isArray(checkboxes)) return false;
    const areAllCheckboxes = checkboxes.every(
      (checkbox): checkbox is Checkbox => {
        return (
          typeof checkbox === 'object' &&
          'label' in checkbox &&
          'checked' in checkbox
        );
      }
    );
    if(!areAllCheckboxes) return false;
    const selectedOptions = checkboxes
      .filter((checkbox: Checkbox) => checkbox.checked)
      .map((checkbox: Checkbox) => checkbox.label);
    return selectedOptions.length >= CHECKBOX_MIN_LENGTH;
  }
  const validateReportBody = (body: any) =>{
    return (typeof(body) === 'string' &&
      body.length >= REPORT_BODY_MIN_LENGTH &&
      body.length <= REPORT_BODY_MAX_LENGTH);
  }
  const validatePostBody = (body: any) =>{
    return (typeof(body) === 'string' &&
      body.length >= POST_BODY_MIN_LENGTH &&
      body.length <= POST_BODY_MAX_LENGTH);
  }
  const handleShowResponses = async () => {
    setLoadingFetch(true);
    setpostPosts(post.repliesCollection ?? []);
    setLoadingFetch(false);
  };
  const handleCreatePost = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(!validatePostBody(postBody)) return;
    setLoadingCreate(true);
    const response = await createPost(
      postBody,
      postLocal.contentID,
      postLocal.postID, //We need postID and contentID if we are creating a post in a Post because to get/update post we need postID and contentID
      postLocal.threadID,
      'post',
      shopID,
    );
    if (!response?.error) {
      setpostPosts([response.post, ...postPosts ?? []])
      setPostBody('');
      setShowForm(false);
      if(handleUpdateThreadPosts) handleUpdateThreadPosts(response.post);
    } else {
      if(response.error==='offensiveContent'){
        setShowOffensiveModal(true);
      } else {
        toast.error(
          response.error,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
    setLoadingCreate(false);
  };
  const handleChangeReportBody = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newReportBody = event.target.value;
    setReportBody(newReportBody);
  };
  const handleCancelReport = () =>{
    setShowModalReport(false);
    setReportBody('');
  }
  const handleDeleteContent = async (contentID: string, postID: string) => {
    setLoadingDelete(true);
    const response = await deleteContent(
      contentID,
      postID,
      'post',
      shopID,
      deleteReason,
    );
    setLoadingDelete(false);
    if (!response?.error) {
      setShowModalDelete(false);
      post.deleted = true;
      post.deletedBy = post.userID===userID ? 'BY_OWNER' : `BY_ADMO_${user.userName}`;
      if(post.deletedBy !== 'BY_OWNER') {
        post.deleteReason = deleteReason;
      } else post.body = `**${TextOnly('contentDeleted')}**`;
      if(post.replies===0&&post.deletedBy==='BY_OWNER'){
        if(handleUpdateThreadPosts) handleUpdateThreadPosts(post,true);
        if(removeReply) removeReply(post);
      } else {
        setGotDeleted(true);
        setPostLocal(post);
      }
      toast.success(
        TextOnly(
          'contentSuccessfullyDeleted',
          {content: TextOnly(isResponse ? 'reply' : 'comment')}
        ),
        {
          containerId: 'standard',
        }
      );
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    };
  };
  const handleChangePostBody = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newPostBody = event.target.value;
    // add some validation
    setPostBody(newPostBody);
  };
  const handleChangeDeleteReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newDeleteReason = event.target.value;
    if(newDeleteReason.length > POST_BODY_MAX_LENGTH) return
    setDeleteReason(newDeleteReason);
  };
  const handleVote = async (userRating: boolean, reportAbuse: boolean) => {
    if(reportAbuse===true && (!validateReportBody(reportBody)|| !validateReportCheckboxes(checkboxes)) || isLoadingVote) return;
    setIsLoadingVote(true)
    const selectedOptions = checkboxes
      .filter((checkbox: Checkbox) => checkbox.checked)
      .map((checkbox: Checkbox) => checkbox.label);
    const optionsString = selectedOptions.join('. ') + '.';
    const reportReason = reportBody ? optionsString + '\n User Description: ' + reportBody : undefined;
    const response = await createVote(
      post.contentID,
      post.postID, //We need postID and contentID if we voting in a post because to get/update post we need postID and contentID
      userRating,
      reportAbuse,
      reportReason,
      'post',
      shopID,
    );
    if (!response?.error) {
      setShowModalReport(false);
      setReportBody('');
      if(reportAbuse) setShowConfirmedReport(true);
      const postLocalAux = { ...postLocal };
      postLocalAux.likes = response.Attributes.likes;
      postLocalAux.dislikes = response.Attributes.dislikes;
      setPostLocal(postLocalAux);
      const aux = { ...userVotes };
      if (
        aux[post.postID] &&
        aux[post.postID].userRating === userRating &&
        aux[post.postID].reportAbuse === reportAbuse
      )
        delete aux[post.postID];
      else
        aux[post.postID] = {
          contentID: post.postID,
          userRating,
          reportAbuse,
          userID,
        };
      aux.list = [];
      Object.keys(aux).forEach((voteKey) => aux.list.push(aux[voteKey]));
      setUserVotes(aux);
    } else {
      if(response.error==='offensiveContent'){
        setShowOffensiveModal(true);
      } else {
        toast.error(
          response.error,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
    setIsLoadingVote(false);
  };
  const handleRemoveReplyFromParent = (reply:Post) =>{
    const replyAux:Post[] = [];
    postPosts?.forEach(post=>{
      if(post.postID!==reply.postID) replyAux.push(post);
    });
    setpostPosts(replyAux as Post[]);
  }
  const handleAdmoFunction = async (functionType:string, newValue:boolean) => {
    if(!postLocal) return
    setIsLoading(true)
    const response = await admoFunction(
      postLocal.contentID,
      postLocal.postID,
      'post',
      functionType,
      shopID,
      newValue,
    );
    if (!response?.error) {
      handleUpdate(response.Attributes);
      const toastMessage = `Post ${
        functionType === 'BLOCK' ?
          response.Attributes.blocked ? 'blocked' : 'unblocked'
        : response.Attributes.locked ? 'locked' : 'unlocked'
      }`
      toast.success(toastMessage,
      {
        containerId: 'standard',
      });
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoading(false);
  }
  const handleUpdate = (content:any) =>{
    setPostLocal(content)
  }
  // #endregion

  // #region UseEffect
  useEffect(() => {
    if (!showResponses) {
      handleShowResponses()
    };
    if(showForm===true) setShowForm(false);
    setShowResponses((prevState) => !prevState);
  }, []);
  // #endregion

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loadingDelete)
    return (
      <div className="loading-wrapper">
        <Loading />
      </div>
    );
  return (
    <div
      className={`postCont\
 ${window.innerWidth < 650 ? 'smaller-screen-padding' :''}\
 ${post.role === 'ADMIN' ? 'admin' : post.role === 'MODERATOR' ? 'moderator' : ''}\
 ${isResponse ? `post-isresponse ${window.innerWidth < 650 ? 'response-mobile' : ''}` : ''}\
 ${post.newlyCreated === true || isNotification ? `col-tra-animation${isResponse ? '-r' : ''}${isNotification ? '-3s' : ''} color-transition${isResponse ? '-r' : ''}` :''}`
      }
    >
      <div
        className="post-user-name"
        style={window.innerWidth < 650 ? { flexFlow: 'column', alignItems: 'flex-start' } : {}}
      >
        <div className="l-flex flex-space-between">
          {gotDeleted === true
            ? null
            : (post.userID === userID &&
              !(post.deletedBy==='BY_OWNER') ) && (
                <button
                  className="mb-btn-icon c-btn__inner"
                  onClick={() => setShowModalDelete(true)}
                >
                  <span
                    className="color-red c-btn__icon fal fa-trash-alt"
                    title={'Delete Post'}
                  />
                </button>
              )}
          {(userRole === 'MODERATOR' || userRole === 'ADMIN') ? (
            <button
              className="mb-btn-icon c-btn__inner"
              onClick={() => setShowModal(true)}
            >
              <span
                className="c-btn__icon post-gear fa fa-gear"
              />
            </button>
          ) : null}
          {postLocal?.role && (
            <div>
              <span
                className={`color-aa-blue c-btn__icon fas ${
                  postLocal.role === 'ADMIN' ?
                    'fa-check-double'
                    : postLocal.role === 'MODERATOR' ?
                    'fa-check'
                    : ''
                }`}
                title={postLocal.role === 'MODERATOR' ? 'Moderator' : 'Admin'}
              />
            </div>
          )}
          <Link to={`/messageBoard/userName/${post.userName}`} className='post-username'>
            {post.userName}:{'\u00a0'}
          </Link>
        </div>
        { post?.deleted ?
          <span
            className={`color-darkgray body-deleted`}
          >
            {post.locked === true ? TextOnly('lockedThreadMessage') : ''}
            **{TextOnly('contentDeleted')}**
          </span>
          :
          <span
            className={`color-darkgray prewrap-breakword`}
          >
            {post.locked === true ? TextOnly('lockedThreadMessage') : ''}
            {post.body?.split('\n').map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < post.body.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        }
      </div>
      <p className="post-created-on" >
        {formatDateTime(post.createdOn)}.
      </p>
      { (post?.userID === userID || userRole === 'MODERATOR' || userRole === 'ADMIN') && (post.deleteReason) &&
        <>
          <div className='delete-reason margin-1rem-0'>
            <h1 className='margin-none'>Delete Reason: <span className='color-aagray'>{post.deleteReason}</span></h1>
            { (userRole === 'MODERATOR' || userRole === 'ADMIN') &&
              <h2 className='color-aagray margin-none'>Deleted by:
                <Link
                  to={`/messageBoard/userName/${post?.deletedBy?.split('BY_ADMO_')[1]}`}
                >
                  {' '+post?.deletedBy?.split('BY_ADMO_')[1]}
                </Link>
              </h2>
            }
            <button
              className="mb-btn-icon c-btn__inner u-padding-none margin-bottom-05rem u-cursor-pointer"
              onClick={()=>setShowOriginal(true)}
            >{TextOnly('showOriginalContent')}</button>
          </div>
        </>
      }
      <div className='post-interaction-wrapper'>
        { isLoadingVote ?
          <div>
            <img src={loading} alt="Loading"
              style={{height: '25.6px', width: '94px'}}
            />
          </div>
          :
          <div className='display-flex'>
            <div className="mb-btn-icon c-btn__inner padding-sides-8">
              <span
                className={`c-btn__icon u-padding-none ${
                  userVotes[postID]?.userRating === true ? 'fas' : 'fal'
                } fa-thumbs-up`}
                title={'Likes'}
                style={{
                  cursor: (postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? 'auto' : 'pointer',
                }}
                onClick={() => ((postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? null : handleVote(true, false))}
              />
              <p className={'mb-vote-count'}>&nbsp;{postLocal.likes}</p>
            </div>
            <div className="mb-btn-icon c-btn__inner padding-sides-8">
              <span
                className={`c-btn__icon u-padding-none ${
                  userVotes[postID]?.userRating === false ? 'fas' : 'fal'
                } fa-thumbs-down`}
                title={'Dislikes'}
                style={{
                  cursor: (postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? 'auto' : 'pointer',
                }}
                onClick={() =>
                  (postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? null : handleVote(false, false)
                }
              />
              <p className={'mb-vote-count'}>&nbsp;{postLocal.dislikes}</p>
            </div>
            <button
              className="mb-btn-icon c-btn__inner padding-sides-8"
              onClick={() =>
                (postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? null : setShowModalReport(true)}>
              <span
                className={`c-btn__icon u-padding-none color-red fas fa-exclamation`}
                style={{
                  cursor: (postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? 'auto' : 'pointer',
                }}
                title={'Report'}
                onClick={() =>
                  (postLocal.deleted || postLocal.locked || userRole==='READONLY' || userRole==='MUTED') ? null : setShowModalReport(true)}
              />
            </button>
          </div>
        }
        {!isResponse && (
          <>
            <button
              className="mb-btn-icon c-btn__inner padding-sides-8 u-cursor-pointer"
              onClick={async () => {
                if (!showResponses) handleShowResponses();
                setShowResponses((prevState) => !prevState);
              }}
            >
              <span
                className="c-btn__icon u-padding-none fal fa-reply-all"
                title={'Responses'}
              />
              <p>&nbsp;{postPosts?.length || post.replies}</p>
            </button>
            <div className='display-flex'>
              { (!postLocal.locked && !postLocal.deleted && userRole!=='READONLY' && userRole!=='MUTED') &&
                <button
                  className="mb-btn-icon c-btn__inner padding-sides-8 u-cursor-pointer"
                  onClick={async () => {
                    if (!showResponses) handleShowResponses();
                    setShowResponses(true);
                    setShowForm((prevState) => !prevState)
                  }}
                >
                  <span>
                    {showForm ? '' : TextOnly('addReply')}
                  </span>
                </button>
              }
              <button
                className="mb-btn-icon c-btn__inner padding-sides-8 u-cursor-pointer"
                onClick={async () => {
                  if (!showResponses) {
                    handleShowResponses()
                  };
                  if(showForm===true) setShowForm(false);
                  setShowResponses((prevState) => !prevState);
                }}
              >
                <span>
                  {showResponses ? TextOnly('closeReplies') : TextOnly('showReplies')}
                </span>
              </button>
            </div>
          </>
        )}
      </div>
      {!isResponse && (
        <>
          {showResponses && !post.deleted && !post.locked && userRole!=='READONLY' && userRole!=='MUTED' ? (
            <>
              <div className={showForm ? 'marginbottom-30px' : 'hide-element'}>
                <form
                  className="thread-form paddingbottom-0"
                  onSubmit={handleCreatePost}
                >
                  <div className="c-btn__inner">
                    <span
                      onClick={() => setShowForm((prevState) => !prevState)}
                      className='marginleft-15px u-cursor-pointer'
                    >
                      {showForm ? TextOnly('cancelReply') : TextOnly('addReply')}
                    </span>
                  </div>
                  <div className="form-group-detail">
                    <textarea
                      className="text-area-detail"
                      id="post"
                      name="post"
                      value={postBody}
                      onChange={handleChangePostBody}
                      placeholder={TextOnly('addReply')+'...'}
                      rows={5}
                      cols={40}
                      required
                    />
                    <LoaderButton
                      type="submit"
                      isLoading={loadingCreate || loadingFetch}
                      disabled={!validatePostBody(postBody)}
                      text={TextOnly('reply')+'!'}
                      loadingText={TextOnly('loading')}
                      className='height-fitcontent'
                    />
                  </div>
                </form>
              </div>
            </>
          ) : null}
          {showResponses === true ? (
            loadingFetch === false ? (
              (postPosts?.length ?? 0) > 0 ? (
                postPosts
                  ?.sort((a: Post, b: Post) => {
                    return (
                      new Date(a.createdOn).getTime() -
                      new Date(b.createdOn).getTime()
                    );
                  })
                  ?.map((response) => {
                    return (
                      <PostComponent
                        key={response.postID}
                        post={response}
                        user={user}
                        userVotes={userVotes}
                        setUserVotes={setUserVotes}
                        currentShop={currentShop}
                        isResponse={true}
                        handleUpdateThreadPosts={handleUpdateThreadPosts}
                        removeReply={handleRemoveReplyFromParent}
                      />
                    );
                  })
              ) : (
                <span>
                  <br />
                  {TextOnly('postNoComments')}
                </span>
              )
            ) : (
              <Loading className='spinner-small'/>
            )
          ) : null}
        </>
      )}
      <Dialog
        isOpen={showModalDelete}
        className="c-modal"
        aria-label="confirmDelete"
        onDismiss={() => setShowModalDelete(false)}
      >
        <h1>{TextOnly('confirm')}</h1>
        <h2 className="c-modal__heading">
          {TextOnly(
              'confirmDeleteContent',
              {content: TextOnly(isResponse ? 'reply' : 'comment')
              }
            )}
        </h2>
        { (userRole === 'MODERATOR' || userRole === 'ADMIN') &&
          <div
            className="c-modal__body display-flex-justify-content-center"
          >
            <textarea
              className="text-area-detail maxwidth-100"
              value={deleteReason}
              onChange={handleChangeDeleteReason}
              placeholder={TextOnly('deleteReason')}
              rows={5}
              cols={40}
            />
          </div>
        }
        <div
          className="c-modal__body display-flex-justify-content-center"
        >
          <LoaderButton
            type="submit"
            isLoading={false}
            disabled={loadingDelete}
            text={TextOnly('cancel')}
            loadingText={TextOnly('cancel')}
            onClick={() => setShowModalDelete(false)}
          />
          <LoaderButton
            type="submit"
            isLoading={loadingDelete}
            disabled={loadingDelete}
            text={TextOnly('confirm')}
            loadingText={TextOnly('loading')}
            onClick={() => {
              handleDeleteContent(post.contentID, postID)
            }}
            className="confirm-del-thread"
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={showModal}
        className="c-modal"
        aria-label="admo"
        onDismiss={()=>setShowModal(false)}
      >
        { isLoading ?
          <div className='height-200px'><Loading /></div> :
          <>
            <button
              className="c-btn-icon c-modal__close-aux"
              onClick={()=>setShowModal(false)}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <h1 className="c-modal__heading">
              {TextOnly(isResponse ? 'reply' : 'comment')}:
            </h1>
            <div className="c-modal__body">
              <PostComponent
                post={postLocal}
                user={user}
                userVotes={userVotes}
                currentShop={currentShop}
                setUserVotes={setUserVotes}
                handleUpdateThreadPosts={handleUpdateThreadPosts}
              />
              <br/>
              <div className='display-flex-justify-content-space-around'>
                <LoaderButton
                  type="button"
                  disabled={!!postLocal?.deleted}
                  text={TextOnly('delete')}
                  isLoading={loadingDelete}
                  loadingText={TextOnly('loading')}
                  onClick={() => setShowModalDelete(true)}
                />
                <LoaderButton
                  type="button"
                  text={
                      postLocal?.blocked !== true ?
                        TextOnly('block')
                        : TextOnly('unblock')
                  }
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleAdmoFunction('BLOCK', !postLocal?.blocked)}
                />
                <LoaderButton
                  type="button"
                  text={
                    postLocal?.locked !== true ?
                      TextOnly('lock')
                      : TextOnly('unlock')
                  }
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleAdmoFunction('LOCK', !postLocal?.locked)}
                />
              </div>
            </div>
          </>
        }
      </Dialog>
      <Dialog
        isOpen={showConfirmedReport}
        className="c-modal"
        aria-label="confirmedReported"
      >
        <h2 className="c-modal__heading">{TextOnly(isResponse ? 'replyReported' : 'commentReported')}</h2>
        <div
          className="c-modal__body display-flex-justify-content-center"
        >
          <LoaderButton
            type="submit"
            isLoading={false}
            disabled={false}
            text={TextOnly('confirm')}
            loadingText={TextOnly('loading')}
            onClick={() => setShowConfirmedReport(false)}
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={showModalReport}
        className="c-modal"
        aria-label="report"
        onDismiss={()=>handleCancelReport()}
      >
        <button
          className="c-btn-icon c-modal__close-aux"
          onClick={()=>handleCancelReport()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          {TextOnly('report')}:
        </h1>
        <div className={`c-modal__body paddingtop-0 ${isLoadingVote ? 'height-200px' :''}`}
        >
        { (userVotes[postID]?.reportReason?.length > 0) ?
        <>
          <h2>{TextOnly('postAlreadyReported')}</h2>
          <div className='display-flex-justify-content-space-around u-width-100-percent alignitems-baseline'>
            <div>
              <LoaderButton
                isLoading={false}
                disabled={false}
                text={TextOnly('confirm')}
                loadingText={TextOnly('loading')}
                onClick={(e)=>{
                  e.preventDefault()
                  handleCancelReport()
                }}
              />
            </div>
          </div>
        </>
        : isLoadingVote ? <Loading/> :
          <>
            <h2>{TextOnly('contentReportReason', {content: TextOnly(isResponse ? 'reply' : 'comment')})}</h2>
            <form className="thread-form margintop-0 paddingtop-0" onSubmit={()=>null}>
              { !validateReportCheckboxes(checkboxes) &&
                <div className='c-field__error marginbottom-10'>
                  <span>* {TextOnly('selectOneCheckboxError')}</span>
                </div>
              }
              <div
                className='c-field'
              >
                {checkboxes.map((checkbox, index) => (
                  <div key={index}>
                    <label className="tag-checkbox mb-label">
                      <input
                        type="checkbox"
                        checked={checkbox.checked}
                        onChange={() => handleCheckboxChange(index)}
                        style={{ marginRight: '10px', width: '18x', height: '18x' }}
                      />
                      {checkbox.label}
                    </label>
                  </div>
                ))}
              </div>
              { !validateReportBody(reportBody) &&
                <div className='c-field__error marginbottom-10'>
                  <span>* {TextOnly('reportValidateError', {num: REPORT_BODY_MIN_LENGTH.toString()})}</span>
                </div>
              }
              <div
                className='c-field'
              >
                <textarea
                  className={`text-area-detail c-input ${!validateReportBody(reportBody) ? 'error' : ''}`}
                  id="body"
                  name="body"
                  placeholder={TextOnly('describeReport')}
                  value={reportBody}
                  onChange={handleChangeReportBody}
                  rows={5}
                  cols={40}
                  required
                />
              </div>
              <div className='display-flex-justify-content-space-around u-width-100-percent alignitems-baseline'>
                <div>
                  <LoaderButton
                    isLoading={false}
                    disabled={!validateReportBody(reportBody) || !validateReportCheckboxes(checkboxes)}
                    text={TextOnly('report')}
                    loadingText={TextOnly('loading')}
                    onClick={(e: any) => {
                      e.preventDefault()
                      handleVote(false, true)
                    }}
                    className='red-btn'
                  />
                </div>
                <div>
                  <LoaderButton
                    isLoading={false}
                    disabled={false}
                    text={TextOnly('cancel')}
                    loadingText={TextOnly('loading')}
                    onClick={(e)=>{
                      e.preventDefault()
                      handleCancelReport()
                    }}
                  />
                </div>
              </div>
            </form>
          </>
        }
        </div>
      </Dialog>
      <Dialog
        isOpen={showOriginal}
        className="c-modal background-color-transparent"
        aria-label="showOriginal"
        onDismiss={() => setShowOriginal(false)}
      >
        <div
          className={`c-box ${windowWidth < 650 ? 'smaller-screen-padding small-top' :''} ${post.locked ? 'locked hoverable' : ''}`}
        >
          <div className='prewrap-breakword'>
            {post?.body?.split('\n')?.map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < post?.body.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
          <div className='display-flex-justify-content-space-around'>
            <LoaderButton
              type="submit"
              isLoading={false}
              disabled={false}
              text={TextOnly('close')}
              loadingText=''
              onClick={() => setShowOriginal(false)}
            />
          </div>
        </div>
      </Dialog>
      <OffensiveContentModal {...{showOffensiveModal, setShowOffensiveModal}}/>
    </div>
  );
};
