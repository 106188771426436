import React from "react";
import LoaderButton from "../LoaderButton";

interface BasicModalProps {
  isOpen: boolean;
  handleCancelModal: Function;
  title: string;
  bodyContent: Function;
  className?: string;
  subClass?: string;
  isLoading?: boolean;
}

// Body Content must be a function so we can pass handleCloseModal
//   function as a prop to the jsx element that is returned
//   from Body Content function.

const BasicModal: React.FC<BasicModalProps> = ({
  isOpen,
  handleCancelModal,
  title,
  bodyContent,
  className='c-modal',
  subClass='',
  isLoading=false }) => {

  const handleCloseModal = (callback?: Function) => {
    if (!isLoading) {
      document.querySelector(`.basic-modal-content.${subClass || className}`)?.classList.add('closed');
      setTimeout(() => {
        handleCancelModal(callback);
      }, 350);
    }
  };

  return !isOpen ? null : (
    <div className="basic-modal-base">
      <div className={`basic-modal-content ${subClass} ${className}`} >
        <LoaderButton
          className={`c-btn-icon ${className}__close-basic-modal`}
          text=""
          onClick={() => handleCloseModal()}
          icon={<i className="c-btn__icon fal fa-times" />}
        />
        <div className="c-modal__heading textwrap-nowrap">
          <span className="h1">{title}</span>
        </div>
        <div className="c-modal__body">
          {bodyContent(handleCloseModal)}
        </div>
      </div>
    </div>
  );
};

export default BasicModal;