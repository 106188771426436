import React, { Component } from 'react';
import { getToolTypes } from '../libs/db-lib';
import { Text, TextOnly } from '../components/Text';

export default class ToolPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufacturerToolsList: [],
      manufacturers: [],
      toolList: [],
      currentManufacturer: {},
      currentModel: '',
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      let toolManList = this.props.toolTypes;
      let self = this;
      let manufacturers = toolManList?.filter(
        (manufacturer) => {
          let regionsInfo = manufacturer?.regions?.find((r) => { return r.regionCode === self.props.region});
          return (!self.props.region || (regionsInfo && regionsInfo !== -1));
        }
      ).map((man) => {
        let regionsInfo = man.regions?.find((r) => { return r.regionCode === self.props.region});
        if (regionsInfo?.displayName) {
          return { manufacturer: man.toolManufacturer, displayName: regionsInfo.displayName };
        } else {
          return { manufacturer: man.toolManufacturer, displayName: man.toolManufacturer };
        }
      });
      let tools = [];
      if (toolManList) {
        this.setState({
          manufacturerToolsList: toolManList,
          manufacturers: manufacturers,
          toolList: tools,
          currentManufacturer: {},
          currentModel: {},
          isLoading: false,
        });
      }
    } catch (e) {
      alert(e);
    }
  }

  handleManufacturerChange(event) {
    // Change the tools List
    let newManufacturer = event.target.value;

    let manRec = this.state.manufacturerToolsList.filter((man) => {
      return man.toolManufacturer === newManufacturer;
    })[0];
    let tools = manRec.toolModelList.map((tool) => {
      return tool.toolModel;
    });

    this.setState({
      toolList: tools,
      currentManufacturer: { toolManufacturer: newManufacturer, toolManufacturerUUID: manRec.toolManufacturerUUID },
      currentModel: {},
    });

    if (this.props.handleManufacturerChange) {
      this.props.handleManufacturerChange(newManufacturer);
    }
  }

  handleToolChange(event) {
    let newMan = this.state.currentManufacturer.toolManufacturer;
    let newModel = event.target.value;

    let manRec = this.state.manufacturerToolsList.filter((man) => {
      return man.toolManufacturer === newMan;
    })[0];
    let modelRec = manRec.toolModelList.find((tool) => {
      return tool.toolModel === newModel;
    });

    let newTool = { toolModel: newModel, toolModelUUID: modelRec.toolModelUUID };
    this.setState({
      currentModel: newTool,
    });
    if (this.props.handleToolChange) {
      this.props.handleToolChange(this.state.currentManufacturer, newTool);
    }
  }

  render() {
    let manufacturersOptions = [];
    manufacturersOptions.push(
      <option key="-1" className="inactive" value="" disabled>
        <span>{TextOnly('noneSelected')}</span>
      </option>
    );
    manufacturersOptions.push(
      this.state.manufacturers.map((man, i) => {
        return <option key={i} value={man.manufacturer}>{man.displayName}</option>;
      })
    );
    let toolOptions = [];
    toolOptions.push(
      <option key="-1" className="inactive" value="" disabled>
        <span>{TextOnly('noneSelected')}</span>
      </option>
    );
    toolOptions.push(
      this.state.toolList.map((tool, i) => {
        return <option key={i}>{tool}</option>;
      })
    );

    let currentTool = this.state.currentModel.toolModel || '';

    return !this.state.isLoading ? (
      <>
        <div className="c-field">
          <label htmlFor="" className="c-field__label">
            <Text tid="manufacturer" />
          </label>
          <div className="c-select">
            <select
              onChange={this.handleManufacturerChange.bind(this)}
              id={this.props.idLabel + 'manufacturers'}
              defaultValue=""
            >
              {manufacturersOptions}
            </select>
          </div>
        </div>

        <div className="c-field">
          <label
            htmlFor={this.props.idLabel + 'tools'}
            className="c-field__label"
          >
            <Text tid="toolModel" />
          </label>
          <div className="c-select">
            <select
              value={currentTool}
              onChange={this.handleToolChange.bind(this)}
              id={this.props.idLabel + 'tools'}
            >
              {toolOptions}
            </select>
          </div>
        </div>
      </>
    ) : (
      <span>
        <i
          className="fal fa-spinner-third spinning fa-2x u-margin-bottom"
          title={TextOnly('loading')}
        />
      </span>
    );
  }
}
