import React from 'react';
import ToolTip from './ToolTip';

interface TechAvatarProps {
  tech: any,
  size?: string,
}

export const TechAvatar: React.FC<TechAvatarProps> = ({
  tech,
  size='',
}) => {

  const colorClassName = tech?.avatarColor || 'tech-color-no-color-assigned';
  const firstInitial = tech?.firstName[0];
  const lastInitial = tech?.lastName[0];

  return (
    <ToolTip text={`${tech?.firstName} ${tech?.lastName}`}>
      <div className={`tech-profile-avatar ${colorClassName} ${size}`}>
        {`${firstInitial}${lastInitial}`}
      </div>
    </ToolTip>
  );
};
