import React from 'react';
import { ShopState } from '../types';
import { TextOnly } from './Text';

interface LedProps {
  status: ShopState;
}

export const Led = ({ status }: LedProps) => {
  const shopState = getLedState(status);
  return (
    // @ts-ignore
    <span className="c-status-led" title={`${TextOnly(status) || ''}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`c-status-led__light c-status-led__light--${shopState}`}
      >
        <circle cx="8" cy="8" r="8" />
      </svg>
      <span className="c-status-led__label">{TextOnly(status)}</span>
    </span>
  );
};

const LED_STATES = {
  active: 'active',
  error: 'error',
  inactive: 'inactive',
  warning: 'warning',
};

function getLedState(status: ShopState) {
  switch (status) {
    case 'TERMINATED':
    case 'INACTIVE':
    case 'PAST_DUE':
      return LED_STATES.error;
    case 'PENDING':
      return LED_STATES.warning;
    case 'ACTIVE':
    case 'VALID':
      return LED_STATES.active;
    default:
      return LED_STATES.inactive;
  }
}
