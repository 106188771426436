import React from 'react';
import LoaderButton from './LoaderButton';

type EmptyCardType = {
  title: string;
  description: string;
  buttons: {
    name: string;
    onClick: () => void;
    disabled: boolean;
    isLoading?: boolean;
    loadingText?: string;
    icon?: JSX.Element;
  }[];
};

export function EmptyCard({ title, description, buttons }: EmptyCardType) {
  return (
    <div className="c-notice u-margin-top-none u-margin-bottom-none">
      <div className="c-notice__content-alt">
        <h2 className="c-notice__title">{title}</h2>
        <p className="c-notice__text-alt">{description}</p>
      </div>
      <div className="c-notice__buttons">
        {buttons.map((button, index) => (
          <LoaderButton
            text={button.name}
            className={index > 0 ? 'u-margin-right-large' : ''}
            onClick={button.onClick}
            disabled={button.disabled}
            isLoading={button.isLoading}
            loadingText={button.loadingText}
            icon={button.icon}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
