import React, { Component } from 'react';
import {
  resetPassword,
  confirmResetPassword,
  signIn,
 } from 'aws-amplify/auth';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../components/LoaderButton';
import { passwordValidate, fieldChangeCase, strip } from '../libs/utils';
import AlertModal from '../components/AlertModal';
import { Text, TextOnly } from '../components/Text';
import './Signup.css';
import { logPasswordResetEmailResend } from '../libs/db-lib';

export default class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      username: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      showConfirmModal: false,
      showModal: false,
      newUser: null,
    };
  }

  validateForm() {
    return this.state.username.length > 0;
  }

  validateConfirmationForm() {
    let passwordValid = passwordValidate(this.state.password);
    return (
      this.state.confirmationCode.length > 0 &&
      passwordValid &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleConfirmCancel = () => {
    this.setState({ showConfirmModal: false });
    this.props.history.push('/');
  };

  handleChange = (event) => {
    if (event.target.id === 'username') {
      fieldChangeCase(this, event.target, 'lower', false);
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };
  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    await logPasswordResetEmailResend(this.state.username, 0);
    await resetPassword({ username: strip(this.state.username) })
      .then((data) => {
        this.setState({
          newUser: true,
        });
      })
      .catch((e) => {

        let alertMsg = e.message;
        if (alertMsg === 'Username/client id combination not found.') {
          alertMsg = TextOnly('usernameEmailNotFound');
        }
        this.setState({
          alertMessage: alertMsg,
          showModal: true,
          isLoading: false,
        });
      });

    this.setState({ isLoading: false });
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    await confirmResetPassword({
      username: this.state.username,
      confirmationCode: this.state.confirmationCode.trim(),
      newPassword: this.state.password
    })
      .then(async (data) => {
        await logPasswordResetEmailResend(this.state.username, 0);
        await signIn({
          username: this.state.username,
          password: this.state.password
        });

        this.props.userHasAuthenticated(true);
        this.setState({
          alertMessage: TextOnly('passwordHasBeenReset'),
          showConfirmModal: true,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          alertMessage:
            e.message === 'Invalid code provided, please request a code again.'
              ? TextOnly('invalidResetCode')
              : e.message,
          showModal: true,
          isLoading: false,
        });
      });
    this.setState({ isLoading: false });
  };

  renderConfirmationForm() {
    return (
      <div className="l-container-med">
        <h1>
          <Text tid="enterNewPassword" />
        </h1>
        <form onSubmit={this.handleConfirmationSubmit}>
          <div className="c-field">
            <label htmlFor="password" className="c-field__label">
              <Text tid="newPassword" />
            </label>
            <input
              className={`c-input ${
                this.state.password.length > 0 &&
                !passwordValidate(this.state.password)
                  ? 'error'
                  : ''
              }`}
              id="password"
              maxLength="50"
              placeholder={TextOnly('newPassword')}
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
            {this.state.password.length > 0 &&
            !passwordValidate(this.state.password) ? (
              <div className="c-field__error">
                <Text tid="passwordHint" />
              </div>
            ) : null}
          </div>
          <div className="c-field">
            <label htmlFor="confirmPassword" className="c-field__label">
              <Text tid="confirmNewPassword" />
            </label>
            <input
              className={`c-input ${
                this.state.confirmPassword.length > 0 &&
                this.state.password !== this.state.confirmPassword
                  ? 'error'
                  : ''
              }`}
              id="confirmPassword"
              maxLength="50"
              placeholder={TextOnly('confirmNewPassword')}
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              type="password"
            />
            {this.state.confirmPassword.length > 0 &&
            this.state.password !== this.state.confirmPassword ? (
              <div className="c-field__error">
                <Text tid="confirmPasswordHint" />
              </div>
            ) : null}
          </div>
          <div className="c-field">
            <label htmlFor="confirmationCode" className="c-field__label">
              <Text tid="confirmationCode" />
            </label>
            <input
              id="confirmationCode"
              className="c-input"
              placeholder={TextOnly('confirmationCode')}
              maxLength="20"
              type="text"
              value={this.state.confirmationCode}
              onChange={this.handleChange}
            />
            <div className="c-field__note">
              <Text tid="pleaseCheckEmailForCode" />
            </div>
          </div>
          <LoaderButton
            disabled={!this.validateConfirmationForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text={TextOnly('verify')}
            loadingText={TextOnly('verifying')}
          />
        </form>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="l-container-med">
        <form onSubmit={this.handleSubmit}>
          <h1>
            <Text tid="resetPassword" />
          </h1>
          <div className="c-field">
            <label htmlFor="username" className="c-field__label">
              <Text tid="usernameOrEmail" />
            </label>
            <input
              id="username"
              className="c-input"
              placeholder={TextOnly('usernameOrEmail')}
              maxLength="100"
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </div>
          <LoaderButton
            type="submit"
            disabled={!this.validateForm()}
            isLoading={this.state.isLoading}
            text={TextOnly('requestReset')}
            loadingText={TextOnly('sendingRequest')}
          />
        </form>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />
        <Dialog
          isOpen={this.state.showConfirmModal}
          onDismiss={()=>null}
          className="c-modal"
          aria-label={'updateToolCredentials'}
        >
          <h1 className="c-modal__heading">
            {TextOnly('furtherActionRequired')}
          </h1>

          <div className="c-modal__body flex-colum-center">
            <p
              className="instruction-box u-margin-bottom-large"
              dangerouslySetInnerHTML={{
                __html: TextOnly('resetPasswordMessage'),
              }}
            />
            <p>{TextOnly('reachYouToolManufacturer')}</p>
            <br/>
            <div>
              <LoaderButton
                type="button"
                text={TextOnly('confirm')}
                onClick={this.handleConfirmCancel.bind(this)}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
