import React, { useState } from 'react';
import {
  Popover,
  ArrowContainer,
  PopoverAlign,
  PopoverPosition,
} from 'react-tiny-popover';

interface GenericPopoverProps {
  anchorElement: React.ReactElement;
  body: React.ReactElement;
  primaryBtnElement?: React.ReactElement;
  secondaryBtnElement?: React.ReactElement;
  color: string;
  position: string;
  align: string;
  arrowSize: number;
  closeOnClick: boolean;

  primaryAction?: () => void;
}

const GenericPopover = ({
  anchorElement,
  body,
  primaryBtnElement,
  secondaryBtnElement,
  color,
  position,
  align,
  arrowSize,
  closeOnClick,
  primaryAction,
}: GenericPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let anchor = React.cloneElement(anchorElement, {
    onClick: () => {
      if (anchorElement.props.onClick) {
        anchorElement.props.onClick();
      }
      setIsPopoverOpen(!isPopoverOpen);
    },
  });

  let primaryBtn = primaryBtnElement
    ? React.cloneElement(primaryBtnElement, {
        onClick: () => {
          setIsLoading(true);
          setTimeout(() => {
            if (primaryAction !== undefined) {
              primaryAction();
            }
            setIsLoading(false);
            setIsPopoverOpen(false);
          }, 1000);
        },
        isLoading: isLoading
      })
    : null;

  let secondaryBtn = secondaryBtnElement
    ? React.cloneElement(secondaryBtnElement, {
        onClick: () => setIsPopoverOpen(false),
      })
    : null;

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={[position as PopoverPosition]}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      align={align as PopoverAlign}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={color}
            arrowSize={arrowSize}
            arrowStyle={{ opacity: 1 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div
              className="popover-container"
              style={{ border: `1px solid ${color}` }}
              onClick={() => {
                if (closeOnClick) setIsPopoverOpen(false);
              }}
            >
              {body}
              <div className="l-flex-center">
                {secondaryBtn}
                {primaryBtn}
              </div>
            </div>
          </ArrowContainer>
      )}
    >
      {anchor as JSX.Element & React.ReactNode}
    </Popover>
  );
};

export default GenericPopover;
