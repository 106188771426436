import React, { useState, useEffect } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import { isValidEmail, isValidVehicleYear, isStrAValidNumber, nonEmptyStringValidate } from '../../../libs/utils';
import BasicPhoneInput from '../../../components/BasicPhoneInput';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import { crmCreateShopCustomer, crmUpdateShopCustomer } from '../../../libs/db-lib';
import { languageOptions } from '../../../libs/languages';
import { useLanguage } from '../../../libs/Language';
import AddEditVehicleForm from '../ManageVehicles/AddEditVehicleForm';
import { CONTACT_PREFERENCE } from '../CrmConstants';
import ToolTip from '../../../components/ToolTip';


const AddEditCustomerForm: React.FC<any> = (props) => {
  const {
    currentShop,
    setIsLoading,
    isLoading,
    handleCloseModal,
    addNewCustomer,
    setNewCustomerInfo,
    customer,
    updateSingleCustomer,
    isSmallScreen,
    setMissingAttCustomerForm,
  } = props;

  const isEditForm = !!customer;

  const { userLanguage } = useLanguage();

  const langOptions = Object.keys(languageOptions).map((key) => {
    return (
      <option key={key} value={key}>
        {languageOptions[key as keyof typeof languageOptions]}
      </option>
    );
  });

  const [firstName, setFirstName] = useState<string>(customer?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(customer?.lastName ?? '');
  const [emailInput, setEmailInput] = useState<string>(customer?.email ?? '');
  const [phoneNumber, setPhoneNumber] = useState<string>(customer?.phoneNumber ?? '');
  const [contactPref, setContactPref] = useState<
  'EMAIL' | 'PHONE' | 'BOTH' | 'NONE'
  >(customer?.contactPreference ?? 'NONE');
  const [language, setLanguage] = useState<string>(customer?.language ?? userLanguage ?? 'en');
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false);
  const [crmSmsDisclaimer, setCrmSmsDisclaimer] = useState<boolean>(
    customer?.crmSmsDisclaimer ?? false
  );
  const [showVehicleForm, setShowVehicleForm] = useState<boolean>(false);
  const [newVehicleInfo, setNewVehicleInfo] = useState<any>({});
  const [isEmailRequired, setIsEmailRequired] = useState<boolean>(contactPref === 'EMAIL' || contactPref === 'BOTH');
  const [isPhoneRequired, setIsPhoneRequired] = useState<boolean>(contactPref === 'PHONE' || contactPref === 'BOTH');
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [missingAttVehicleForm, setMissingAttVehicleForm] = useState<string[]>([]);

  useEffect(() => {
    if (setNewCustomerInfo) {
      setNewCustomerInfo({
        firstName,
        lastName,
        email: emailInput,
        phoneNumber,
        contactPreference: contactPref,
        language,
        crmSmsDisclaimer,
        'isValidated': validateSubmit(),
      })
    }

    setIsEmailRequired(contactPref === 'EMAIL' || contactPref === 'BOTH');
    setIsPhoneRequired(contactPref === 'PHONE' || contactPref === 'BOTH');
    const missingFieldsArray = [];
    if(!nonEmptyStringValidate(firstName, 50)) missingFieldsArray.push(TextOnly('firstName'));
    if(!nonEmptyStringValidate(lastName, 50))  missingFieldsArray.push(TextOnly('lastName'));
    if(
      (isEmailRequired || emailInput.length) &&
      !isValidEmail(emailInput)
    ) missingFieldsArray.push(TextOnly('email'));
    if(
      (isPhoneRequired || phoneNumber.length) &&
      !isValidPhone
    ) missingFieldsArray.push(TextOnly('phoneNumber'));
    setMissingFields(missingFieldsArray);
    setMissingAttCustomerForm?.(missingFieldsArray);

  }, [
    firstName,
    lastName,
    emailInput,
    phoneNumber,
    contactPref,
    language,
    isValidPhone,
    crmSmsDisclaimer,
    isEmailRequired,
    isPhoneRequired,
    showVehicleForm,
  ]);

  const validateSubmit = () => {
    if (
      nonEmptyStringValidate(firstName, 50) &&
      nonEmptyStringValidate(lastName, 50) &&
      (
        (isEmailRequired || emailInput.length) ?
        isValidEmail(emailInput) : true
      ) &&
      (
        (isPhoneRequired || phoneNumber.length) ?
        isValidPhone : true
      ) &&
      Object.keys(languageOptions).includes(language) &&
      Object.keys(CONTACT_PREFERENCE).includes(contactPref) &&
      (
        showVehicleForm ?
        (
          nonEmptyStringValidate(newVehicleInfo.make, 50) &&
          nonEmptyStringValidate(newVehicleInfo.model, 50) &&
          isValidVehicleYear(newVehicleInfo.year) &&
          (
            newVehicleInfo.odometer.trim().length ?
              isStrAValidNumber(newVehicleInfo.odometer)
              : true
          )
        ) : true
      )
    ) {
      //All input are valid
      if (customer) {
        if (
          firstName !== customer.firstName ||
          lastName !== customer.lastName ||
          emailInput !== customer.email ||
          contactPref !== customer.contactPreference ||
          language !== customer.language ||
          crmSmsDisclaimer !== customer.crmSmsDisclaimer ||
          phoneNumber !== customer.phoneNumber
        ) {
          //At least one input changed
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (validateSubmit()) {
      setIsLoading(true);
      const response: any = isEditForm ?
        await crmUpdateShopCustomer(
          customer.shopID,
          customer.customerID,
          firstName,
          lastName,
          emailInput,
          phoneNumber,
          contactPref,
          language,
          crmSmsDisclaimer,
        ) :
        await crmCreateShopCustomer(
          currentShop.shopID,
          firstName,
          lastName,
          emailInput,
          phoneNumber,
          contactPref,
          language,
          crmSmsDisclaimer,
          newVehicleInfo,
        );
      setIsLoading(false);
      if (!response.error) {
        if (isEditForm) {
          updateSingleCustomer(response);
          toast.success(TextOnly('customerUpdated'), { containerId: 'standard' });
        } else {
          toast.success(TextOnly('newCustomerCreated'), { containerId: 'standard' });
          addNewCustomer(response);
          handleCloseModal();
        }
      } else {
        toast.error(
          `${TextOnly('error')}: ${response.error}`,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
  };

  return (
    <div className='u-width-100-percent'>
      {(!setNewCustomerInfo || isEditForm) && (
        <div className="h2"><Text tid='customerInformation' /></div>
      )}
      <form className={`u-margin-top`}>
        <div className={`display-flex u-width-100-percent ${setNewCustomerInfo ? 'flex-wrap-wrap' : ''}`}>
          <div className={`c-field ${setNewCustomerInfo ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
            <label className={`c-field__label`} htmlFor="firstName">
              <span className="u-text-error">*</span> <Text tid='firstName' />:
            </label>
            <input
              type="text"
              id="firstName"
              className={`c-input${
                (firstName.length > 0) &&
                !nonEmptyStringValidate(firstName, 50)
                  ? '__error'
                  : ''
              }`}
              placeholder={`${TextOnly('firstName')}`}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              autoComplete={'off'}
              disabled={isLoading}
            />
            {(firstName.length > 0) &&
              !nonEmptyStringValidate(firstName, 50) && (
                <div className="c-field__error">
                  <Text tid="firstNameHint" />
                </div>
              )}
          </div>
          <div className={`c-field ${setNewCustomerInfo ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
            <label className={`c-field__label`} htmlFor="lastName">
              <span className="u-text-error">*</span> <Text tid='lastName' />:
            </label>
            <input
              type="text"
              id="lastName"
              className={`c-input${
                (lastName.length > 0) && !nonEmptyStringValidate(lastName, 50)
                  ? '__error'
                  : ''
              }`}
              placeholder={`${TextOnly('lastName')}`}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              autoComplete={'off'}
              disabled={isLoading}
            />
            {(lastName.length > 0) &&
              !nonEmptyStringValidate(lastName, 50) && (
                <div className="c-field__error">
                  <Text tid="lastNameHint" />
                </div>
              )}
          </div>
        </div>
        <div className={`display-flex u-width-100-percent ${(!!setNewCustomerInfo || isSmallScreen) ? 'flex-wrap-wrap' : ''}`}>
          <div className={`c-field ${(!!setNewCustomerInfo || isSmallScreen) ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
            <label className={`c-field__label`} htmlFor="email">
              {isEmailRequired &&
              <>
                <span className="u-text-error">*</span>&nbsp;
              </>
              }
              <Text tid='email' />:
            </label>
            <input
              type="text"
              id="email"
              className={`c-input${
                (emailInput.length > 0) &&
                !isValidEmail(emailInput)
                  ? '__error'
                  : ''
              }`}
              placeholder={`${TextOnly('email')}`}
              value={emailInput}
              onChange={(event) => setEmailInput(event.target.value)}
              autoComplete={'off'}
              disabled={isLoading}
            />
            {(emailInput.length > 0) &&
              !isValidEmail(emailInput) && (
                <div className="c-field__error">
                  <Text tid="emailHint" />
                </div>
              )}
          </div>
          <div className={`c-field ${(!!setNewCustomerInfo || isSmallScreen) ? 'u-width-100-percent' : 'u-width-50-percent'}`} />
        </div>
        <div className={`display-flex u-width-100-percent ${(!!setNewCustomerInfo || isSmallScreen) ? 'flex-wrap-wrap' : ''}`}>
          <div className={`c-field ${(!!setNewCustomerInfo || isSmallScreen) ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
            <div>
              <label className={`c-field__label`} htmlFor="phone">
              <span>
                {isPhoneRequired &&
                  <>
                    <span className="u-text-error">*</span>&nbsp;
                  </>
                }
                <Text tid='phoneNumber' />:
              </span>
              </label>
              <BasicPhoneInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setIsValidPhone={setIsValidPhone}
                country={currentShop?.country}
                disabled={isLoading}
              />
            </div>
            <div className="u-margin-top-small display-flex u-align-center">
              <input
                id="crmSmsDisclaimer"
                type="checkbox"
                checked={crmSmsDisclaimer}
                onChange={()=>setCrmSmsDisclaimer(!crmSmsDisclaimer)}
                className="u-margin-right-large"
                autoComplete={'off'}
                disabled={isLoading}
              />
              <label
                htmlFor="crmSmsDisclaimer"
                className="c-field__label left-padding u-padding-bottom-none"
              >
                <Text tid="crmSmsDisclaimer" />
              </label>
            </div>
          </div>
          <div className={`c-field ${(!!setNewCustomerInfo || isSmallScreen) ? 'u-width-100-percent' : 'u-width-50-percent'}`} />
        </div>
        <div className={`display-flex u-width-100-percent ${setNewCustomerInfo ? 'flex-wrap-wrap' : ''}`}>
          <div className={`c-field ${setNewCustomerInfo ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
            <label className={`c-field__label`}>
              <span className="u-text-error">*</span>{' '}
              <Text tid="contactPreference" />:
            </label>
            <div
              className={`c-select background-white`}
            >
              <select
                id={'contactPref'}
                value={contactPref}
                onChange={(event) =>
                  setContactPref(event.target.value as 'EMAIL' | 'PHONE' | 'BOTH' | 'NONE')
                }
                disabled={isLoading}
              >
                <option value="" disabled>
                  {TextOnly('chooseContactPreference')}
                </option>
                {
                  Object.keys(CONTACT_PREFERENCE).map((contactPref: string) =>
                    <option value={contactPref} key={contactPref}>{CONTACT_PREFERENCE[contactPref]}</option>
                  )
                }
              </select>
            </div>
          </div>
          <div className={`c-field ${setNewCustomerInfo ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
            <label className={`c-field__label`} htmlFor="customerLanguage">
              <span>
                <span className="u-text-error">*</span> <Text tid='language' />:
              </span>
            </label>
            <div
              className={`c-select background-white`}
            >
              <select
                id="customerLanguage"
                onChange={(event)=>setLanguage(event.target.value)}
                value={language}
                autoComplete={'off'}
                disabled={isLoading}
              >
                <option value="" disabled>
                  {TextOnly('selectLanguage')}
                </option>
                {langOptions}
              </select>
            </div>
          </div>
        </div>
        {(!setNewCustomerInfo && !isEditForm) &&
          <div className={`display-flex u-width-100-percent ${isSmallScreen ? 'flex-wrap-wrap' : ''}`}>
            <div className={`c-field ${isSmallScreen ? 'u-width-100-percent' : 'u-width-50-percent'}`}>
              { !showVehicleForm ?
                <div className='clickable'
                  onClick={()=>setShowVehicleForm(true)}
                >
                  <i className="c-btn__icon fal fa-plus" />
                  <span><Text tid='addVehicle' /></span>
                </div> :
                <>
                  <div className='l-flex-start'>
                    <button
                      className="c-btn-icon"
                      onClick={()=>{
                        setShowVehicleForm(false);
                      }}
                      disabled={isLoading}
                    >
                      <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-times" />
                      </div>
                    </button>
                  </div>
                </>
              }
              <div className={`u-child-margin-none create-vehicle-form ${showVehicleForm ? 'show' : 'hide'}`}>
                <AddEditVehicleForm
                  currentShop={currentShop}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  setNewVehicleInfo={setNewVehicleInfo}
                  triggerClearVehicleForm={showVehicleForm}
                  setMissingAttVehicleForm={setMissingAttVehicleForm}

                />
              </div>
            </div>
            <div className={`c-field ${isSmallScreen ? 'u-width-100-percent' : 'u-width-50-percent'}`} />
          </div>
        }
        {!setNewCustomerInfo && (
          <div className='flex-center u-width-100-percent'>
            <ToolTip
              text={(
                !validateSubmit() &&
                ( missingFields.length || showVehicleForm && missingAttVehicleForm.length )
              ) ? `${TextOnly('missingIncorrectFields')} (${[
                ...missingFields,
                ...(showVehicleForm ? missingAttVehicleForm : [])
              ].join(', ')})` : ''}
            >
              <LoaderButton
                text={TextOnly(isEditForm ? 'saveChanges' : 'createCustomer')}
                loadingText={TextOnly(isEditForm ? 'savingChanges' : 'addingCustomer')}
                onClick={(event) => {
                  handleSubmit();
                }}
                isLoading={isLoading}
                disabled={isLoading || !validateSubmit()}
              />
            </ToolTip>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddEditCustomerForm;
