export interface Tag {
  tagID: string,
  body: string,
  name: string,
}

export interface Thread {
  threadID: string,
  tags: Tag[],
  title: string,
  body: string,
  userID: string,
  userName: string,
  likes: number,
  dislikes: number,
  views: number,
  replies: number,
  abuseCount: number,
  createdOn: string,
  featured: string,
  deleted: boolean,
  blocked: boolean,
  locked: boolean,
  score?: number,
  role?: string,
  deleteReason?: string,
  deletedBy?: string,
  postCollection?: Post[],
}

export interface Post {
  contentID: string,
  postID: string,
  threadID: string,
  body: string,
  createdOn: string,
  userID: string,
  userName: string,
  likes: number,
  dislikes: number,
  replies: number,
  abuseCount: number,
  deleted: boolean,
  score?: number,
  role?: string,
  blocked: boolean,
  locked: boolean,
  newlyCreated?: boolean,
  repliesCollection?: Post[],
  isResponse?: boolean,
  deletedBy?: string,
  deleteReason?: string,
}

export interface Vote {
  contentID: string,
  userID: string,
  reportAbuse: boolean,
  userRating: boolean,
}

export const THREAD_TITLE_MAX_LENGTH = 100;
export const THREAD_TITLE_MIN_LENGTH = 1;

export const THREAD_BODY_MAX_LENGTH = 2000;
export const THREAD_BODY_MIN_LENGTH = 1;

export const REPORT_BODY_MAX_LENGTH = 500;
export const REPORT_BODY_MIN_LENGTH = 5;

export const POST_BODY_MAX_LENGTH = 2000;
export const POST_BODY_MIN_LENGTH = 1;

export const CHECKBOX_MIN_LENGTH = 1;