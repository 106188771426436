import DateRangePicker from 'react-bootstrap-daterangepicker';
import React, { Component } from 'react';
import { TextOnly } from '../components/Text';
import { DateTime } from './DateTime';

export default class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 0,
    };
  }

  componentDidMount() {
    document.getElementById(
      (this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'
    ).value = this.calcRangeStr(this.props.value.start, this.props.value.end);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setState({ key: this.state.key + 1 });
    }
  }

  handleRangeChange(range) {
    const startStr = range.start.format('YYYY-MM-DD');
    const endStr = range.end.format('YYYY-MM-DD');
    document.getElementById(
      (this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'
    ).value = this.calcRangeStr(startStr, endStr);
    if (this.props.onChange) {
      this.props.onChange({
        start: startStr,
        end: endStr,
      });
    }
  }

  calcRangeStr(start, end) {
    return (
      <span>
        <span>{start}</span>{' '}
        <i className="fal fa-arrow-right"></i>{' '}
        <span>{end}</span>
      </span>
    );
  }

  render() {

    let today = TextOnly('Today');
    let yesterday = TextOnly('yesterday');
    let last30days = TextOnly('last30Days');
    let thisMonth = TextOnly('thisMonth');
    let lastMonth = TextOnly('lastMonth');

    const currentDate = new DateTime().format('YYYY-MM-DD');
    const yesterdayDate = new DateTime().subtract(1, 'day').format('YYYY-MM-DD');
    const last30StartDate = new DateTime().subtract(30, 'days').format('YYYY-MM-DD');
    const thisMonthStartDate = new DateTime().startOf('month').format('YYYY-MM-DD');
    const lastMonthStartDate = new DateTime().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const lastMonthEndDate = new DateTime().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

    let ranges = {};
    ranges[today] = [currentDate, currentDate];
    ranges[yesterday] = [yesterdayDate, yesterdayDate];
    ranges[last30days] = [last30StartDate, currentDate];
    ranges[thisMonth] = [thisMonthStartDate, currentDate];
    ranges[lastMonth] = [lastMonthStartDate, lastMonthEndDate];

    const locale = {
      language: TextOnly('hl'),
      format: 'YYYY-MM-DD',
      separator: ' - ',
      applyLabel: TextOnly('apply'),
      cancelLabel: TextOnly('cancel'),
      monthNames: [
        TextOnly('January'),
        TextOnly('February'),
        TextOnly('March'),
        TextOnly('April'),
        TextOnly('May'),
        TextOnly('June'),
        TextOnly('July'),
        TextOnly('August'),
        TextOnly('September'),
        TextOnly('October'),
        TextOnly('November'),
        TextOnly('December'),
      ],
      daysOfWeek: [
        TextOnly('Sun'),
        TextOnly('Mon'),
        TextOnly('Tue'),
        TextOnly('Wed'),
        TextOnly('Thu'),
        TextOnly('Fri'),
        TextOnly('Sat'),
      ],
    };

    const startDateStr = this.props.value.start
      && typeof this.props.value.start === 'string'
        ? new DateTime(this.props.value.start).format('MM/DD/YYYY')
        : '';
    const endDateStr = this.props.value.end
      && typeof this.props.value.end === 'string'
        ? new DateTime(this.props.value.end).format('MM/DD/YYYY')
        : '';

    return (
      <DateRangePicker
        key={this.state.key}
        useCurrent={false}
        initialSettings={{
          startDate: this.props.value.start,
          endDate: this.props.value.end,
          ranges: ranges,
          locale,
          containerClass: 'u-width-100-percent',
          showDropdowns: true,
          alwaysShowCalendars: true,
          timePicker: false,
          drops: 'auto',
          showCustomRangeLabel: false,
        }}
        onApply={(event, dateRangePicker) => {
          const { startDate, endDate } = dateRangePicker;
          this.handleRangeChange({
            start: startDate,
            end: endDate,
            name: 'Custom',
          });
        }}
      >
        <div
          className={`Bootstrap__dateRangePicker ${this.props.className}`}
          id={(this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'}
        >
          <span>{startDateStr}</span>{' '}
            <i className="fal fa-arrow-right"></i>{' '}
          <span>{endDateStr}</span>{' '}
        </div>
      </DateRangePicker>
    );
  }
}
