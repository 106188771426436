import React from 'react';
import { TextOnly } from '../../components/Text';
import { Dialog } from '@reach/dialog';
import { en } from '../../lang/en';


interface UserNotifyMessage {
  MUTED: string,
}

const USER_NOTIFY_MESSAGE : UserNotifyMessage = {
  MUTED: 'userMuted',
}


const NotifyUser = ({ showModal, setShowModal, user }: { showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>>, user: any}) => {

  const notifyUser: string = user.application['MESSAGE_BOARD'].notifyUser; // lang key

  if(!notifyUser || notifyUser === 'NONE') return null;

  const handleCloseModal = () => {
    setShowModal(false);
  }


  return (
    <Dialog
      isOpen={showModal as boolean}
      className="c-modal"
      aria-label="NotifyUser"
      onDismiss={()=>handleCloseModal()}
    >
      <button
        className="c-btn-icon c-modal__close-aux"
        onClick={()=>handleCloseModal()}
      >
        <div className="c-btn__inner">
          <i className="c-btn__icon fal fa-times" />
        </div>
      </button>
      <h1 className="c-modal__heading">
        {TextOnly('notification')}:
      </h1>
      <div className={`c-modal__body paddingtop-0`}>
        {TextOnly(USER_NOTIFY_MESSAGE[notifyUser as keyof UserNotifyMessage] as keyof typeof en)}
      </div>
    </Dialog>
  );
};

export default NotifyUser;