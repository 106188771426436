import React, { FC } from 'react';
import Header from '../../../components/Header';
import { TextOnly, Text } from '../../../components/Text';
import { ChildProps } from '../../../types';
import CrmPromoContent from './CrmPromoContent';

interface CalendarPromoProps extends ChildProps {
  history: any,
}

const CalendarPromo: FC<CalendarPromoProps> = (props) => {
  const handleRouteToPlusCrm = () => {
    props.history.push(`/upgradeToPlus/comparePlans`);
  }

  return (
    <>
      <Header title={TextOnly('calendar')} />
      <div className="l-full-width">
        <div className="c-box c-box--promo-plus-crm">
          <div className="c-box__title">
            <Text tid="upgradeToAutoAuthPlusCrm" />
          </div>
          <div className="u-margin-none">
            <Text tid="upgradeNowAndManageCustomerAppointments" />
          </div>
          <button
            className="c-btn-dark-red"
            onClick={handleRouteToPlusCrm}
          >
            <Text tid="upgradeNow" />
          </button>
        </div>
      </div>
      <CrmPromoContent promoType='calendar' />
    </>
  );
};

export default CalendarPromo;