import React, { Component } from 'react';
import CustomDatePicker from '../components/CustomDatePicker';
import { Text, TextOnly } from '../components/Text';
import { DateTime } from '../components/DateTime';
import _ from 'underscore';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  generateFilterRegex,
  formatDateTime2Lines,
  sortTools,
  sortUsers,
  pipe,
} from '../libs/utils';
import { getSavedColumns, getVINDetailsInChunks } from '../libs/utils-ts';
import { getShopAuditLog } from '../libs/db-lib';
import { Loading } from '../components/Loading';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Header from '../components/Header';
import { SHOP_TYPES, TOOL_EVENTS } from '../CONSTANTS';
import { Dialog } from '@reach/dialog';
import { FilterPills } from '../components/FilterPills';
import AlertModal from '../components/AlertModal';
import { ToolErrors } from '../components/ToolErrors/ToolErrors';

const initialFilters = {
  tools: [],
  techs: [],
  actionCodes: [],
  vins: [],
};
export class ToolActions extends Component {
  constructor(props) {
    super(props);

    const shopTools = this.props?.currentShop?.userShopTools ?? [];

    const shopUsers = [
      ...this.props.currentShop.shopUsers,
      {
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        userID: this.props.user.userID,
        userName: this.props.user.userName,
      },
    ];

    this.state = {
      shopTools: shopTools,
      shopUsers: shopUsers,
      selectedTools: [],
      alertTitle: '',
      alertMessage: '',
      showModal: false,
      filterActions: '',
      showFilterSlideIn: false,
      filters: initialFilters,
      tempFilters: initialFilters,
      actions: [],
      actionsFiltered: [],
      startDate: new DateTime().subtract(1, 'week').format('YYYY-MM-DD'),
      endDate: new DateTime().format('YYYY-MM-DD'),
      toolID: null,
      selectedUsers: [],
      isSendingUserRequest: false,
      isLoading: true,
      VINDetails: {},
      isOpenTypeaheadTools: false,
      isOpenTypeaheadVins: false,
      isOpenTypeaheadActions: false,
      isOpenTypeaheadUsers: false,
    };
  }

  async componentDidMount() {
    if (this.props.currentShop.shopType === SHOP_TYPES.STANDARD) {
      this.props.history.push('/myActivity');
    }

    try {
      await this.handleSearch();
    } catch (e) {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  async handleSearch() {
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;

    this.getAuditLog(startDate, endDate);
  }

  handleApplyFilters = () => {
    this.setState({
      showFilterSlideIn: false,
      filters: this.state.tempFilters,
    });
  };

  handleResetFilters = () => {
    this.setState({
      filters: initialFilters,
      tempFilters: initialFilters,
    });
  };

  async getAuditLog(startDate, endDate) {
    const { shopUsers } = this.state;
    const endDateStr = new DateTime(endDate).endOf('day').toISOString();
    const startDateStr = new DateTime(startDate).startOf('day').toISOString();

    // get all shop actions with date range
    const { logEntries } = await getShopAuditLog(
      this.props.currentShop.shopID,
      startDateStr,
      endDateStr
    );

    const toolActions = !logEntries ? [] : logEntries.map((entry) => {
      let user = shopUsers.find((user) => user.userID === entry.userID);
      let name = `${user?.firstName} ${user?.lastName}`;
      let userName = user?.userName;

      if (!user) {
        let lastSlashIndex = entry.userID.lastIndexOf('/');
        if (lastSlashIndex === -1) {
          name = entry.userID;
        } else {
          name = entry.userID.substr(lastSlashIndex + 1);
        }
      }

      return {
        ...entry,
        name: name,
        shopRole: user?.shopUserRole,
        userName: userName
      };
    });

    const vins = toolActions.filter((log) => log.vin).map((log) => log.vin);

    if (vins.length > 0) {
      const uniqueVins = _.uniq(vins);
      const results = await getVINDetailsInChunks(
        this.props.currentShop.shopID,
        uniqueVins
      );
      this.setState({ VINDetails: results });
    }

    // filter down to the tool events (consts)
    const filteredToolActions = toolActions.filter((action) =>
      TOOL_EVENTS.includes(action.actionCode)
    );
    this.setState({
      actions: filteredToolActions,
      isLoading: false,
    });
  }

  updateActionFilteredList = (filter) => {
    const actionTypeField = 'actions';
    const filteredActionTypeField = 'actionsFiltered';
    const actionTypeFilterField = 'filterActions';
    const stateObj = {};

    if (filter.length > 0) {
      const regexStr = generateFilterRegex(filter);
      const actionTypeList = this.state[actionTypeField];
      const actionList = actionTypeList.filter((a) => {
        const addedOnStr = JSON.stringify(formatDateTime2Lines(a.actionDate));
        return (
          regexStr.test(a.actionMessage) ||
          regexStr.test(a.actionCode) ||
          regexStr.test(addedOnStr) ||
          regexStr.test(a.vin) ||
          regexStr.test(a.name) ||
          regexStr.test(a.toolManufacturer) ||
          regexStr.test(a.toolModel) ||
          regexStr.test(a.toolSerial)
        );
      });
      stateObj[filteredActionTypeField] = actionList;
      stateObj[actionTypeFilterField] = filter;
    } else {
      stateObj[filteredActionTypeField] = this.state[actionTypeField];
      stateObj[actionTypeFilterField] = '';
    }
    this.setState(stateObj);
  };

  handleRangeChange = (value) => {
    const setStateObj = {
      startDate: value.start,
      endDate: value.end,
    };
    this.setState(setStateObj);
    this.getAuditLog(value.start, value.end);
  };

  handleChange = (actionType, event) => {
    this.setState({
      tempFilters: { ...this.state.tempFilters, [actionType]: event },
    });
  };

  handleOnBlur = () => {
    this.setState({
      isOpenTypeaheadTools: false,
      isOpenTypeaheadVins: false,
      isOpenTypeaheadActions: false,
      isOpenTypeaheadUsers: false,
    });
  }

  handleOnFocus = (typeaheadType) => {
    this.setState({
      [typeaheadType]: true,
    })
  }

  updateActionFilter = _.debounce((f, actionType) => {
    this.updateActionFilteredList(f, actionType);
  }, 300);

  handleChangeFilter = (actionType, event) => {
    this.updateActionFilter(event.target.value, actionType);
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleOpenFilterSlideIn = () => {
    this.setState({ showFilterSlideIn: true, tempFilters: this.state.filters });
  };

  handleCloseFilterSlideIn = () => {
    const self = this;
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      self.setState({ showFilterSlideIn: false, tempFilters: initialFilters });
    }, 350);
  };

  handleRemoveFilter = ({ field, value }) => {
    const updatedFiltersField = this.state.filters[field].filter(
      (filterItem) => filterItem.key !== value
    );
    this.setState({
      filters: { ...this.state.filters, [field]: updatedFiltersField },
    });
  };

  handleOpenErrorModal = (row) => {
    const {
      apiErrorMessage,
      actionDate,
      toolSerial,
      vin,
      toolManufacturer,
      toolModel,
    } = row.original;
    const message = (
      <ToolErrors
        apiErrorMessage={apiErrorMessage}
        actionDate={actionDate}
        name={row.row.name}
        toolSerial={toolSerial}
        toolManufacturer={toolManufacturer}
        toolModel={toolModel}
        vin={vin}
      />
    );

    this.setState({
      alertTitle: TextOnly('receivedToolError'),
      alertMessage: message,
      showModal: true,
    });
  };

  render() {
    const savedColumnSizes = getSavedColumns('toolActionsColumns');
    const actionsColumnDefs = [];

    actionsColumnDefs.push({
      Header: <Text tid="date" />,
      id: 'actionDate',
      accessor: 'actionDate',
      maxWidth: 200,
      headerClassName: 'hide-second',
      className: 'hide-second',
      sortMethod: (a, b, desc) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row) => {
        const dateObj = formatDateTime2Lines(row.original.actionDate);
        return (
          <div>
            {dateObj.date}
            <br />
            {dateObj.time}
          </div>
        );
      },
      minWidth: savedColumnSizes.actionDate || 150,
    });

    actionsColumnDefs.push({
      Header: <Text tid="tech" />,
      accessor: "name",
      Cell: (row) => {
        const { name, userName } = row.original;
        return (
          <span>
            <span>
              {name}
            </span>
            <br />
            <span>{userName}</span>
          </span>
        );
      },
      minWidth: savedColumnSizes.name || 125,
    });
    actionsColumnDefs.push({
      Header: <Text tid="toolAction" />,
      accessor: 'actionMessage',
      minWidth: savedColumnSizes.actionMessage || 200,
      Cell: (row) => {
        if (row.original.isError) {
          return (
            <div>
              <span className="u-text-error">{row.original.actionMessage ?? row.original.actionCode}</span>
              <br />
              <span className="u-text-error">
                {row.original.apiErrorMessage}
              </span>
            </div>
          );
        } else {
          return (
            <span>{row.original.actionMessage ?? row.original.actionCode}</span>
          );
        }
      },
    });
    actionsColumnDefs.push({
      Header: <Text tid="tool" />,
      accessor: 'toolSerial',
      minWidth: savedColumnSizes.toolSerial || 150,
      Cell: (row) => {
        const { toolManufacturer, toolModel, toolSerial } = row.original;
        return (
          <span>
            <span>
              {toolManufacturer} {toolModel}
            </span>
            <br />
            <span>{toolSerial}</span>
          </span>
        );
      },
    });
    actionsColumnDefs.push({
      Header: <Text tid="vin" />,
      id: 'vin',
      accessor: (row) => {
        if (row.apiErrorMessage?.includes('VIN is invalid')) {
          return row.vin;
        } else {
          const details = this?.state?.VINDetails
            ? this.state.VINDetails[row.vin]
            : {};
          return (
            <span>
              <span>
                {details
                  ? details.year + ' ' + details.make + ' ' + details.model
                  : ''}
              </span>
              <br />
              <span>{row.vin}</span>
            </span>
          );
        }
      },
      minWidth: savedColumnSizes.vin || 200,
    });

    // table data
    const actions = this.state.actionsFiltered?.length
      ? this.state.actionsFiltered
      : this.state.actions;

    // filter table from state.filters
    const { techs, tools, actionCodes, vins } = this.state.filters;
    const hasFilters =
      !!tools.length || !!actionCodes.length || !!techs.length || !!vins.length;

    const filterTechs = (actions) => {
      return actions.filter(
        (action) =>
          !techs?.length ||
          techs.filter((tech) => tech.key === action.userID).length > 0
      );
    };
    const filterToolsIds = (actions) =>
      actions.filter(
        (action) =>
          !tools?.length ||
          tools.filter((tool) => tool.key === action.toolID).length > 0
      );
    const filterActionCodes = (actions) =>
      actions.filter(
        (action) =>
          !actionCodes?.length ||
          actionCodes.filter(
            (actionCode) => actionCode.key === action.actionCode
          ).length > 0
      );
    const filterVins = (actions) =>
      actions.filter(
        (action) =>
          !vins?.length ||
          vins.filter((vin) => vin.key === action.vin).length > 0
      );

    const filteredToolActions = actions.length
      ? pipe(
          filterTechs,
          filterToolsIds,
          filterActionCodes,
          filterVins
        )(actions)
      : [];

    // For user select
    const usersFromActions = [
      ...new Set(actions.map((action) => action.userID)),
    ];
    const shopUsers = this.state.shopUsers
      .filter((user) => usersFromActions.includes(user.userID))
      .sort(sortUsers);
    const userOptions = shopUsers.length
      ? shopUsers.map((user) => ({
          key: user.userID,
          value: user.userID,
          label: `${user.firstName} ${user.lastName}`,
          pillLabel: `${user.firstName} ${user.lastName}`,
        }))
      : [];

    // For tool select
    const toolsFromActions = [
      ...new Set(actions.map((action) => action.toolID)),
    ];
    const shopTools = this.state.shopTools
      .filter((tool) => toolsFromActions.includes(tool.toolID))
      .sort(sortTools);
    const toolOptions = shopTools.length
      ? shopTools.map((tool) => ({
          key: tool.toolID,
          value: tool.toolID,
          label: `${tool.toolManufacturer} ${tool.toolModel} ${tool.toolSerial}`,
          manufacturer: tool.toolManufacturer,
          model: tool.toolModel,
          serial: tool.toolSerial,
          pillLabel: tool.toolSerial,
        }))
      : [];

    // For action select
    const actionOptions = actions?.length
      ? [...new Set(actions?.map((action) => action.actionCode))].map(
          (actionCode) => ({
            key: actionCode,
            value: actionCode,
            label: actionCode,
            pillLabel: actionCode,
          })
        )
      : [];

    // For vin select
    const vinOptions = actions?.length
      ? [
          ...new Set(
            actions?.map((action) => action.vin)?.filter((vin) => !!vin)
          ),
        ].map((vin) => ({
          key: vin,
          value: vin,
          label: vin,
          pillLabel: vin,
        }))
      : [];

    if (this.state.isLoading) return <Loading />;

    return (
      <>
        <Header
          context={TextOnly('viewReports')}
          title={TextOnly('toolActions')}
        />

        <div className="l-flex-wrap">
          <div className="l-flex-wrap">
            <div className="u-margin-right">
              <CustomDatePicker
                lang={this.props.lang}
                idPrefix="tool"
                value={{
                  start: this.state.startDate,
                  end: this.state.endDate,
                  name: 'Last Week',
                }}
                onChange={(value) => this.handleRangeChange(value)}
              />
            </div>
            <div>
              <button
                className="c-btn-outline"
                onClick={this.handleOpenFilterSlideIn}
                disabled={this.state.isLoading}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-filter" />
                  <Text tid="filters" />
                </div>
              </button>
            </div>
          </div>

          <div className="l-flex-wrap">
            <div className="c-field">
              <label
                htmlFor="filterActions"
                className="c-field__label u-is-vishidden"
              >
                <Text tid="filter" />
              </label>
              <input
                maxLength="50"
                placeholder={TextOnly('filter')}
                id="filterActions"
                type="text"
                value={this.state.filterActions}
                onChange={this.handleChangeFilter.bind(this, 'tool')}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
          </div>
        </div>

        {hasFilters ? (
          <div className="l-flex-wrap u-margin-bottom-large">
            <div className="l-flex-wrap">
              <button
                className="c-btn-link u-margin-right"
                onClick={this.handleResetFilters}
              >
                <div className="c-btn__inner">
                  <Text tid="resetFilters" />
                </div>
              </button>
              <FilterPills
                filters={this.state.filters}
                onFilterClick={this.handleRemoveFilter}
              />
            </div>
          </div>
        ) : null}

        {this.state.isLoading ? (
          <span>
            <i className="fal fa-spinner-third spinning u-margin-right-small" />
            <Text tid="loading" /> <Text tid="toolActions" />
            ...
          </span>
        ) : (
          <ReactTable
            columns={actionsColumnDefs}
            data={filteredToolActions}
            className="-highlight"
            showPaginationTop={true}
            previousText={TextOnly('previousPage')}
            nextText={TextOnly('nextPage')}
            pageText={TextOnly('page')}
            ofText={TextOnly('of')}
            rowsText={TextOnly('rows')}
            noDataText={TextOnly('noUserOrToolActions')}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: 'actionDate',
                desc: true,
              },
            ]}
            onResizedChange={(a) =>
              localStorage.setItem('toolActionsColumns', JSON.stringify(a))
            }
          />
        )}

        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          handleCancel={this.handleCancel.bind(this)}
        />

        <Dialog
          isOpen={this.state.showFilterSlideIn}
          onDismiss={this.handleCloseFilterSlideIn}
          className="c-modal-slider"
          aria-label={TextOnly('filters')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCloseFilterSlideIn}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="filters" />
          </h1>
          <div className="c-modal__body">
            <div className="c-field">
              <label
                htmlFor="pickUser"
                className="c-field__label u-is-vishidden"
              >
                <Text tid="pickUser" />
              </label>
              <Typeahead
                id="selectedUsers"
                clearButton
                flip={true}
                options={userOptions}
                onChange={this.handleChange.bind(this, 'techs')}
                placeholder={TextOnly('selectAUser')}
                renderMenuItemChildren={(option, props, index) => {
                  return (
                    <div className="typeahead-menu-item-children">
                      {option.label}
                    </div>
                  )
                }}
                selected={this.state.tempFilters.techs}
                open={this.state.isOpenTypeaheadUsers}
                onBlur={this.handleOnBlur.bind(this)}
                onFocus={this.handleOnFocus.bind(this, 'isOpenTypeaheadUsers')}
                disabled={this.state.isLoading}
              />
            </div>
            <div className="c-field">
              <label className="c-field__label u-is-vishidden">
                <Text tid="pickTool" />
              </label>
                <Typeahead
                  id="selectedTools"
                  clearButton
                  flip={true}
                  options={toolOptions}
                  onChange={this.handleChange.bind(this, 'tools')}
                  placeholder={TextOnly('selectATool')}
                  renderMenuItemChildren={(option, props, index) => {
                    return (
                      <div className="typeahead-menu-item-children">
                        {option.label}
                      </div>
                    )
                  }}
                  selected={this.state.tempFilters.tools}
                  open={this.state.isOpenTypeaheadTools}
                  onBlur={this.handleOnBlur.bind(this)}
                  onFocus={this.handleOnFocus.bind(this, 'isOpenTypeaheadTools')}
                  disabled={this.state.isLoading}
                />
            </div>
            <div className="c-field">
              <label className="c-field__label u-is-vishidden">
                <Text tid="pickAction" />
              </label>
                <Typeahead
                  id="selectedActions"
                  clearButton
                  flip={true}
                  options={actionOptions}
                  onChange={this.handleChange.bind(this, 'actionCodes')}
                  placeholder={TextOnly('selectAAction')}
                  renderMenuItemChildren={(option, props, index) => {
                    return (
                      <div className="typeahead-menu-item-children">
                        {option.label}
                      </div>
                    )
                  }}
                  selected={this.state.tempFilters.actionCodes}
                  open={this.state.isOpenTypeaheadActions}
                  onBlur={this.handleOnBlur.bind(this)}
                  onFocus={this.handleOnFocus.bind(this, 'isOpenTypeaheadActions')}
                  disabled={this.state.isLoading}
                />
            </div>
            <div className="c-field">
              <label className="c-field__label u-is-vishidden">
                <Text tid="pickVin" />
              </label>
                <Typeahead
                  id="selectedVin"
                  clearButton
                  flip={true}
                  options={vinOptions}
                  onChange={this.handleChange.bind(this, 'vins')}
                  placeholder={TextOnly('selectAVin')}
                  renderMenuItemChildren={(option, props, index) => {
                    return (
                      <div className="typeahead-menu-item-children">
                        {option.label}
                      </div>
                    )
                  }}
                  selected={this.state.tempFilters.vins}
                  open={this.state.isOpenTypeaheadVins}
                  onBlur={this.handleOnBlur.bind(this)}
                  onFocus={this.handleOnFocus.bind(this, 'isOpenTypeaheadVins')}
                  disabled={this.state.isLoading}
                />
            </div>

            <button
              className="c-btn-outline u-margin-right"
              onClick={this.handleResetFilters}
            >
              <Text tid="resetFilters" />
            </button>
            <button className="c-btn" onClick={this.handleApplyFilters}>
              <Text tid="applyFilters" />
            </button>
          </div>
        </Dialog>
      </>
    );
  }
}
