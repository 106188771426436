import React, { useState, useEffect } from 'react';
import { Text } from '../components/Text';
import { getSystemConfig } from '../libs/db-lib';

export const ExternalFooterLinks = () => {
  const [config, setConfig] = useState<any>(null);
  useEffect(() => {
    const getConfig = async () => {
      let response = await getSystemConfig();
      setConfig(response);
    };

    getConfig();
  }, []);

  return (
    <div className="l-contianer-lg l-flex-wrap u-margin-top-large u-margin-bottom-large u-justify-space-around">
      <a
        href="https://info.autoauth.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="aboutUs" />
      </a>
      <a
        href="https://info.autoauth.com/contact"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="contactUs" />
      </a>
      <a
        href="https://info.autoauth.com/contact/#faq_section"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="faq" />
      </a>
      <a
        href="https://info.autoauth.com/contact/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="privacyPolicy" />
      </a>
      <a
        href="https://info.autoauth.com/contact/cookies-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="cookiesPolicy" />
      </a>
      <a
        href="https://info.autoauth.com/contact/refund-cancellation-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="refundCancelPolicy" />
      </a>
      <a
        href={`${config && config.baseUrl}/SystemInfo`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text tid="systemInfo" />
      </a>
    </div>
  );
};
