import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import LoaderButton from '../components/LoaderButton';
import { Text } from '../components/Text';
import {
  releaseTool
} from '../libs/db-lib';


export default (props) => {
  console.log("Entering ReleaseTool");
  let { toolReleaseCode } = useParams();
  const [status, setStatus] = useState([]);

  useEffect(() => {
    releaseTool(toolReleaseCode).then((status) => setStatus(status));
  }, [toolReleaseCode]);

  if (!status) return <div>Loading...</div>

  return (
    <div className="parent-center min-height">
      <div className="child-center">
        <Text tid={status} />
      </div>
    </div>
  )
  };
