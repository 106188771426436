import React from 'react';
import ReactTable, { Column } from 'react-table';
import { useQuery } from 'react-query';
import { LangDictKey, Text, TextOnly } from '../../components/Text';
import { expandRows, SHOP_EVENTS } from '../../CONSTANTS';
import { getSelfActivityLog } from '../../libs/db-lib';
import { containsExpandFields, formatDateTime, pipe } from '../../libs/utils';
import {
  findUserById,
  getSavedColumns,
  getUserName,
} from '../../libs/utils-ts';
import { Action, IError, User } from '../../types';
import { LogSubComponent } from '../../components/subComponents';
import { DateTime } from '../../components/DateTime';

interface genericObj {
  [key: string]: any,
}

interface MyShopActionsTableProps {
  shop: genericObj;
  user: genericObj;
  shopUsers: User[];
  timeframe: string;
  oemRegions: [];
}

export const MyShopActionsTable = ({
  shop,
  user,
  shopUsers,
  timeframe,
  oemRegions,
}: MyShopActionsTableProps) => {
  const savedColumnSizes = getSavedColumns('myShopActionColumns');
  const userId = user.userID;

  let startDate = new DateTime().subtract(7, 'days').startOf('day').toISOString();
  let endDate = new DateTime().endOf('day').toISOString();

  switch (timeframe) {
    case 'last30Days':
      startDate = new DateTime().subtract(30, 'days').startOf('day').toISOString();
      break;
    case 'Today':
      startDate = new DateTime().startOf('day').toISOString();
      break;
    case 'last7Days':
    default:
      startDate = new DateTime().subtract(7, 'days').startOf('day').toISOString();
      break;
  }

  const { isLoading, error, data } = useQuery<any, IError, any>(
    `getSelfActivityLog-${startDate}-${endDate}`,
    () => getSelfActivityLog(userId, startDate, endDate)
  );

  const actionsColumnDefs: Column[] = [
    {
      Header: <Text tid="date" />,
      id: 'actionDate',
      accessor: 'actionDate',
      headerClassName: 'hide-second',
      className: 'hide-second',
      sortMethod: (a, b, desc) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row) => {
        return <span>{formatDateTime(row.original.actionDate, { stacked: true })}</span>
      },
      minWidth: savedColumnSizes?.actionDate || 200,
    },
    {
      Header: <Text tid="user" />,
      accessor: 'name',
      minWidth: savedColumnSizes?.name || 200,
      Cell: (row) => {
        return <span>{row.original.name}</span>
      }
    },
    {
      Header: <Text tid="action" />,
      accessor: 'actionMessage',
      minWidth: savedColumnSizes?.actionMessage || 200,
      Cell: (row) => {
        return <span>{row.original.actionMessage}</span>
      }    },
  ];

  if (isLoading)
    return (
      <span>
        <i className="fal fa-spinner-third spinning u-margin-right-small" />
        <Text tid="loading" /> <Text tid="shopActions" />
        ...
      </span>
    );
  if (error) return <p>{error.message}</p>;
  if (data?.error)
    return (
      <p className="error">
        <Text tid="error" />: <span>{data.error}</span>
      </p>
    );

  const addActionProperties = (actions: Action[]) =>
    actions.map((action: Action) => ({
      ...action,
      name: getUserName(action.userID, shopUsers),
      shopRole: TextOnly(
        findUserById(action.userID, shopUsers)?.shopRole as LangDictKey
      ),
    }));

  const filterForMyActions = (actions: Action[]) =>
    actions.filter((action) => action.userID === userId);

  const filterForShopActions = (actions: Action[]) =>
    actions.filter((action) => SHOP_EVENTS.includes(action.actionCode));

  const filteredShopActions = data?.logEntries
    ? pipe(
        filterForShopActions,
        addActionProperties,
        filterForMyActions
      )(data?.logEntries)
    : [];

  return (
    <>
      <ReactTable
        columns={[
          {
            expander: true,
            Header: '',
            accessor: 'expander',
            Expander: ({ isExpanded, ...row }) => {
              if (
                  expandRows.includes(row.original.actionCode)
                  && containsExpandFields(row.original)
                ) {
                return (
                  <div>
                    <span>
                      <i
                        className={`fa fa-chevron-right ${
                          isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                        }`}
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                );
              } else {
                return (
                  <span>
                    <div className="u-cursor-none"></div>
                  </span>
                );
              }
            },
          },
          ...actionsColumnDefs
        ]}
        data={filteredShopActions}
        className="-highlight"
        showPaginationTop={true}
        previousText={TextOnly('previousPage')}
        nextText={TextOnly('nextPage')}
        pageText={TextOnly('page')}
        ofText={TextOnly('of')}
        rowsText={TextOnly('rows')}
        noDataText={TextOnly('noShopActions')}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'actionDate',
            desc: true,
          },
        ]}
        onResizedChange={(a) =>
          localStorage.setItem('shopActionsColumns', JSON.stringify(a))
        }
        SubComponent={row => {
          if (
              expandRows.includes(row.original.actionCode)
              && containsExpandFields(row.original)
            ) {
            return (
              <LogSubComponent
                oemRegions={oemRegions}
                row={row}
                shop={shop}
                currentUser={user}
                className="accordion__content"
              />
            );
          } else {
            return null;
          }
        }}
      />
    </>
  );
};
