import React, { useEffect, useState } from 'react';
import { TextOnly } from '../../components/Text';
import { getDriverVerificationsForShop } from '../../libs/db-lib';
import ReactTable from 'react-table';
import { formatDateTime } from '../../libs/utils';
import LoaderButton from '../../components/LoaderButton';
import { Loading } from '../../components/Loading';
import { toast } from 'react-toastify';

const CurrentVerificationRequests = (props: any) => {
  //State variables
  const { shopRequests, setShopRequests, history, currentShop } = props;
  const [hideCompleted, setHideCompleted] = useState<boolean>(false);
  const [showOwnerSignOnly, setShowOwnerSignOnly] = useState<boolean>(false);
  const [hideRejected, setHideRejected] = useState<boolean>(true);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const [filterInput, setFilterInput] = useState<string>('');
  const [filteredShopRequests, setFilteredShopRequests] = useState<any[]>([]);

  //Handle Functions
  const handleGetRequest = async () => {
    setLoadingRequest(true)
    const response = await getDriverVerificationsForShop(
      currentShop.shopID
    );
    if(!response.error) {
      setShopRequests(response)
    } else {
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setLoadingRequest(false)
  };
  const handleRowClick = (row:any) => {
    history.push(`/detailedOwnerVerification/${row.verificationID}`)
  };

  //UseEffects
  useEffect(() => {
    handleGetRequest();
  }, []);

  useEffect(() => {
    // Filter the shopRequests array based on filterInput
    // Adding formatDateTime to all instances so user can filter based on the month like "September..."
    const shopVerifications = shopRequests.map((verif:any)=>{
      verif.createdDate = formatDateTime(verif.createdOn)
      return verif;
    });
    //Applying filter
    let filtered = shopVerifications.filter(
      (item: any) => JSON.stringify(item).toLowerCase().includes(filterInput.toLowerCase())
    );
    //Applying hideCompleted
    if(hideCompleted) {
      filtered = filtered.filter(
        (item: any) => item.verificationStatus !== 'COMPLETED'
      );
    }
    //Applying hideRejected
    if(hideRejected) {
      filtered = filtered.filter(
        (item: any) => !item.verificationStatus.includes('REJECTED') && !item.verificationStatus.includes('CANCELED_BY_TECH')
      );
    }
    //Applying showOwnerSignOnly
    if(showOwnerSignOnly) {
      filtered = filtered.filter(
        (item: any) => item.verificationStatus.includes('OWNER_SIGN_REQUIRED')
      );
    }
    setFilteredShopRequests(filtered);
  }, [shopRequests, filterInput, hideCompleted, hideRejected, showOwnerSignOnly]);

  //Column definition
  const tableCol = [
    {
      Header: TextOnly('status'),
      accessor: 'verificationStatus',
      id: 'verificationStatus',
      Cell: (row: any) => {
        let statusKey = row.original.verificationStatus;
        let textClass = '';
        if(statusKey.includes("REJECTED") || statusKey.includes("CANCELED")) {
          textClass = 'u-text-error';
          statusKey = TextOnly('rejectedBy',
            {
              'rejectedBy': statusKey.includes("DRIVER") ? TextOnly('vehicleOwner') : TextOnly('technician')
            }
          );
        } else if(statusKey === 'PENDING_DRIVER_ACCEPTANCE') {
          textClass = '';
        } else if(statusKey === 'COMPLETED') {
          textClass = 'u-text-success';
        } else {
          textClass = 'u-text-warning';
        }
        return (
          <div className={`ui-info text-bold ${textClass}`}>
            {TextOnly(statusKey)}
          </div>
        );
      },
    },
    {
      Header: TextOnly('service'),
      accessor: 'servicePerformed',
      id: 'servicePerformed',
      Cell: (row: any) => {

        return (
          <div className='ui-info'>
            {TextOnly(row.original.servicePerformed)}
          </div>
        );
      },
    },
    {
      Header: TextOnly('date'),
      accessor: 'createdOn',
      id: 'createdOn',
      Cell: ({ value }: { value: any }) => {
        const createdOn = formatDateTime(value);

        const [date, time] = createdOn.split(' at ');
        return (
          <div className='ui-info' title={time}>
            {date}
          </div>
        );
      },
    },
    {
      Header: TextOnly('vehicleOwner'),
      accessor: 'driverEmail',
      id: 'driverEmail',
      Cell: (row: any) => {
        return (
          <div className='ui-info'>
            {row.original.driverName}
          </div>
        );
      },
    },
    {
      Header: TextOnly('contact'),
      accessor: 'driverContactPreference',
      id: 'driverContactPreference',
      Cell: (row: any) => {
        const contactPref = row.original.driverContactPreference;
        const contact = (
          <div>
            {contactPref === 'BOTH' ?
              (
                <div>
                {row.original.driverEmail}
                <br />
                {row.original.driverPhone}
                </div>
              )
            : contactPref === 'EMAIL' ?
              <div>
                {row.original.driverEmail}
              </div>
            : //contactPref === 'PHONE'
            <div>
              {row.original.driverPhone}
            </div>
            }
          </div>
        );
        return (
          <div className='ui-info'>
            {contact}
          </div>
        );
      },
    },
    {
      Header: TextOnly('vehicle'),
      accessor: 'vin',
      id: 'vin',
      Cell: (row: any) => {
        const make = row.original.vehicleMake;
        const model = row.original.vehicleModel;
        const year = row.original.vehicleYear;
        return (
          <div className='ui-info'>
            {make} {model} {year}
          </div>
        );
      },
    },
  ];


  return (
    <>
      <div className="header-table flex-wrap-wrap">
        <div className={`display-flex u-align-center ${window.innerWidth <= 500 ? 'flexwrap-wrap' : ''}`}>
          <div>
            <LoaderButton
              text={TextOnly('refresh')}
              onClick={()=>handleGetRequest()}
              isLoading={loadingRequest}
              icon={<i className="c-btn__icon fa fa-refresh" />}
            />
          </div>
          <div className={`c-field no-spacing display-flex u-margin-left-large ${window.innerWidth <= 500 ? 'u-margin-top' : ''}`}>
            <div className={"l-inline-flex u-margin-right"}>
              <input
                className='u-cursor-pointer'
                id='hideCompleted'
                type="checkbox"
                checked={hideCompleted}
                onChange={()=>{
                  if(!hideCompleted) setShowOwnerSignOnly(false);
                  setHideCompleted(!hideCompleted)}
                }
              />
              <label className="c-field__label left-padding u-padding-bottom-none u-cursor-pointer" htmlFor='hideCompleted'>
                {TextOnly('hideCompleted')}
              </label>
            </div>
            <div className={"l-inline-flex u-margin-left-large"}>
              <input
                className='u-cursor-pointer'
                id='hideRejected'
                type="checkbox"
                checked={hideRejected}
                onChange={()=>{
                  if(!hideRejected) setShowOwnerSignOnly(false);
                  setHideRejected(!hideRejected)}
                }
              />
              <label className="c-field__label left-padding u-padding-bottom-none u-cursor-pointer" htmlFor='hideRejected'>
                {TextOnly('hideRejected')}
              </label>
            </div>
            <div className={"l-inline-flex u-margin-right u-margin-left-large"}>
              <input
                className='u-cursor-pointer'
                id='showOwnerSignOnly'
                type="checkbox"
                checked={showOwnerSignOnly}
                onChange={()=>{
                  if(!showOwnerSignOnly) {
                    setHideCompleted(false);
                    setHideRejected(false);
                  }
                  setShowOwnerSignOnly(!showOwnerSignOnly)}
                }
              />
              <label className="c-field__label left-padding u-padding-bottom-none u-cursor-pointer" htmlFor='showOwnerSignOnly'>
                {TextOnly('showOwnerSignOnly')}
              </label>
            </div>
          </div>
        </div>
        <div className="c-field no-spacing u-width-100-percent u-margin-top-large">
          <input
            id="filterActions"
            type="text"
            maxLength={50}
            className="c-input"
            placeholder={TextOnly('filter')}
            value={filterInput}
            onChange={(e)=>setFilterInput(e.target.value)}
          />
          <i className="c-field__input-icon fal fa-search" />
        </div>
      </div>
      { loadingRequest ?
      <div className='u-margin-top-xlarge'>
        <Loading className='display-flex-justify-content-center' />
      </div>
        :
        <ReactTable
          columns={tableCol}
          data={filteredShopRequests}
          className="-highlight u-margin-top-large tablelayout"
          previousText={TextOnly('previousPage')}
          nextText={TextOnly('nextPage')}
          pageText={TextOnly('page')}
          ofText={TextOnly('of')}
          rowsText={TextOnly('rows')}
          noDataText={TextOnly('noVerificationRequests')}
          defaultPageSize={5}
          defaultSorted={[
            {
              id: 'createdOn',
              desc: true,
            },
          ]}
          getTheadThProps={() => {
            return {
              style: {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              },
            };
          }}
          getTrProps={(state: any, rowInfo: any) => {
            // Attach a click event handler to each row
            return {
              onClick: () => {
                handleRowClick(rowInfo?.original); // Pass the clicked row data to your custom function
              },
              className: `${rowInfo?.original ? 'u-cursor-pointer' : ''}`, // Add a class to the selected row for styling
            };
          }}
        />
      }
    </>
  );
};

export default CurrentVerificationRequests;
