import React, { Component } from 'react';
import CustomDatePicker from '../../components/CustomDatePicker';
import { DateTime } from '../../components/DateTime';
import { addShopSignupLink } from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import { Text, TextOnly } from '../../components/Text';
import 'react-table/react-table.css';
import { SHOP_TYPES } from '../../CONSTANTS';

export default class AddSignupLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      currentShop: props.currentShop,
      filter: '',
      validityCriteria: SHOP_TYPES.STANDARD,
      name: '',
      isLoading: false,
      startDate: new DateTime().utc().startOf('month').format('YYYY-MM-DD'),
      endDate: new DateTime().utc().endOf('month').format('YYYY-MM-DD'),
      noOfAdditionalFields: 0,
      additionalFields: [],
    };
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    return (
      this.state.name.length > 0 && !this.state.additionalFields.includes('')
    );
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleRangeChange = (value) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
  };

  handleAddFieldsChange = (index, event) => {
    let af = this.state.additionalFields;
    af[index] = event.target.value;
    this.setState({
      additionalFields: af,
    });
  };

  handleRemoveFieldsChange = (index, event) => {
    let af = this.state.additionalFields;
    af.splice(index, 1);
    this.setState({
      additionalFields: af,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleAddField = () => {
    let newNoOfFields = this.state.noOfAdditionalFields + 1;
    let additionalFields = this.state.additionalFields;
    additionalFields.push('');
    this.setState({
      noOfAdditionalFields: newNoOfFields,
      additionalFields: additionalFields,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    let data = this.state;
    this.setState({
      isLoading: true,
    });

    for (let field of data.additionalFields) {
      if (field.trim().length === 0) {
        data.additionalFields = data.additionalFields.filter(
          (element) => element !== field
        );
      }
    }

    const startDateTime = new DateTime(data.startDate).utc().startOf('day');
    const endDateTime = new DateTime(data.endDate).utc().endOf('day');

    let result = await addShopSignupLink(
      data.currentShop.shopID,
      data.name,
      data.validityCriteria,
      data.additionalFields,
      startDateTime,
      endDateTime
    );
    if (result?.error) {
      this.props.handleShowAlert(TextOnly('error'), result.error, true);
      this.setState({ isLoading: false });
    } else {
      this.props.handleAddSignupLink(result);
      this.setState({ isLoading: false });
      this.props.onDismiss();
    }
  };

  render() {
    let startDate = this.state.startDate
      ? this.state.startDate
      : new DateTime().utc().startOf('month').format('YYYY-MM-DD');
    let endDate = this.state.endDate
      ? this.state.endDate
      : new DateTime().utc().endOf('month').format('YYYY-MM-DD');

    let additionalFieldsInput = [];
    additionalFieldsInput.push(
      this.state.additionalFields.map((field, index) => {
        return (
          <>
            {index === 0 ? <dt>Custom Fields</dt> : <dt />}
            <dd className="l-inline-flex u-margin-top-large">
              <input
                type="text"
                id="additionalFields"
                className="c-input"
                autoFocus
                maxLength="100"
                placeholder={TextOnly('fieldName')}
                value={this.state.additionalFields[index]}
                onChange={this.handleAddFieldsChange.bind(this, index)}
              />
              <button
                className="c-btn-icon"
                onClick={this.handleRemoveFieldsChange.bind(this, index)}
              >
                <div className="c-btn__inner">
                  <i
                    className="c-btn__icon fal fa-times"
                    title={TextOnly('removeCustomField')}
                  />
                </div>
              </button>
            </dd>
            {this.state.additionalFields[index].length === 0 ? (
              <>
                <dt />
                <dd>
                  <div className="c-field__error">
                    <Text tid="fieldNameCannotBeBlank" />
                  </div>
                </dd>
              </>
            ) : null}
          </>
        );
      })
    );

    return (
      <div className="c-signup-link u-margin-top">
        <form onSubmit={this.handleSubmit}>
          <div className="c-signup-link__fields">
            <dl className="u-align-center">
              <dt>Link Name</dt>
              <dd>
                {
                  <input
                    type="text"
                    id="name"
                    className="c-input"
                    maxLength="100"
                    placeholder={TextOnly('name')}
                    value={this.state.name}
                    onChange={this.handleChange}
                    autoFocus
                  />
                }
              </dd>
            </dl>
            <dl className="u-align-center u-margin-top-large">
              <dt>Validity Criteria</dt>
              <dd>
                <div className="c-select">
                  <select
                    id="validityCriteria"
                    value={this.state.validityCriteria}
                    onChange={this.handleChange}
                  >
                    <option key="1" value="STANDARD">
                      {TextOnly('standard')}
                    </option>
                    <option key="2" value="TIME">
                      {TextOnly('time')}
                    </option>
                  </select>
                </div>
              </dd>
              {this.state.validityCriteria === 'TIME' ? (
                <>
                  <dt>Start/End Time</dt>
                  <dd className="u-margin-top-large">
                    <CustomDatePicker
                      className="u-margin-top"
                      value={{
                        start: startDate,
                        end: endDate,
                        name: 'Custom',
                      }}
                      onChange={this.handleRangeChange}
                    />
                  </dd>
                </>
              ) : null}
            </dl>
            <dl className="u-align-center u-margin-top-large">
              {additionalFieldsInput}
              <dt />
              <dd className="u-margin-top">
                <LoaderButton
                  type="button"
                  isLoading={this.state.isLoading}
                  onClick={this.handleAddField.bind(this)}
                  text={<Text tid="addCustomField" />}
                  className="c-btn-outline"
                />
              </dd>
            </dl>
          </div>
          <div className="c-signup-link__footer">
            <div />
            <div className="c-signup-link__footer-right">
              <button
                className="c-btn u-margin-right"
                onClick={this.props.onDismiss}
              >
                Cancel
              </button>
              <LoaderButton
                className="c-btn u-margin-right"
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('add')}
                loadingText={TextOnly('addingLink')}
              >
                Add
              </LoaderButton>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
