import React, { FC } from 'react';
import { TextOnly, Text } from './Text';
import logoPlusCRM from '../assets/images/aa-logo-pluscrm.svg';
import { useUser } from '../context/User';
import { useScreenSize } from '../context/ScreenSize';
import { CrmPromoType } from '../types';

interface PromoCardProps {
  promoType: CrmPromoType,
}

const PromoCard: FC<PromoCardProps> = (props) => {
  const { promoType } = props;
  const { currentShop } = useUser();
  const { windowWidth } = useScreenSize();

  return (
    <div className={`${windowWidth > 1500 && 'l-container-sm'} u-margin-none u-min-width-350`}>
      <div className="c-card u-margin-right-none">
        <div className="c-logo u-margin-top-large">
          <img src={logoPlusCRM} className="c-logo__image" alt="logo" />
        </div>

        { promoType === 'manageOrders' && (
          <>
            <h2 className="c-card__title">
              <Text tid={currentShop?.shopType === 'PLUS' ? "signUpAndStreamlineYourShopOperations" : "upgradeToAutoAuthPlusCrmAndStreamline"} />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
                <span>{TextOnly('enhanceShopsEfficiencyWithOrderManagementExplain')}:</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("allServiceOrdersAtAGlanceExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("quicklyCreateAndLogOrdersExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("convenientlyTrackStatusOfOrdersExplain")}</span>
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("attachServiceOrdersToCalendarExplain")}</span>
              </div>
            </div>
          </>
        )}

        { promoType === 'calendar' && (
          <>
            <h2 className="c-card__title">
              <Text tid={currentShop?.shopType === 'PLUS' ? "signUpAndUnlockBenefits" : "upgradeAndSignUpAndUnlockBenefits"} />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
              <span>{TextOnly('leverageTheAutoAuthPlusCrmCalendar')}:</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("manageAppointmentsAndTimeOffExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("seeOverViewOfScheduleExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("gainValuableInsightsIntoYourShopsBusiestTimes")}</span>
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("sendAutomatedTextEmailNotificationsExplain")}</span>
              </div>
            </div>
          </>
        )}

        { promoType === 'manageCustomers' && (
          <>
            <h2 className="c-card__title">
              <Text tid={
                currentShop?.shopType === 'PLUS'
                  ? "signUpForPlusCrmAndGetAccessToCustomerDirectory"
                  : "upgradeAndSignUpForPlusCrmAndGetAccessToCustomerDirectory"
                }
              />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
              <span>{TextOnly('keepInfoOrganizedWithCustomerDirectory')}:</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("addNewCustomersExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("findCustomerContactInfoExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("referenceCustomersServiceHistoryExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("attachCustomerToAppointmentExplain")}</span>
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("sendTextEmailRemindersExplain")}</span>
              </div>
            </div>
          </>
        )}

        { promoType === 'manageVehicles' && (
          <>
            <h2 className="c-card__title">
              <Text tid={
                currentShop?.shopType === 'PLUS'
                  ? "signUpForPlusCrmAndGetAccessToVehicleDirectory"
                  : "upgradeAndSignUpForPlusCrmAndGetAccessToVehicleDirectory"
                }
              />
            </h2>
            <div className="c-card__description">
              <div className="u-padding-bottom-2rem">
                <span>{TextOnly('keepInfoOrganizedWithVehicleDirectory')}:</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("addNewVehiclesExplain")}</span>
              </div>
              <div className="l-flex-row u-padding-bottom-2rem">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("quicklyGlanceAtVehicleInfoExplain")}</span>
              </div>
              <div className="l-flex-row">
                <i className="fa fa-plus u-text-success u-padding-top-half-rem u-padding-right-small" />
                <span>{TextOnly("editVehicleInfoExplain")}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PromoCard;