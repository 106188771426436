import React, { Component } from 'react';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import ShopInfo from './ShopInfo';
import ManageBilling from './ManageBilling';
import Subscriptions from './Subscriptions';

import {
  getOEMRegionList,
  getRegionOemToolCompatibility,
} from '../../libs/db-lib';
import { TextOnly } from '../../components/Text';
import Header from '../../components/Header';
import 'react-phone-number-input/style.css'
import countries from 'i18n-iso-countries';
import { SHOP_TYPES } from '../../CONSTANTS';
import _ from 'underscore';

export default class ShopProfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emailChanged: false,
      username: this.props.user.userName || '',
      shopPaymentInfo: {},
      paymentInfo: null,
      user: this.props.user,
      numToAdd: null,
      isEnterprise: this.props?.currentShop?.shopType === SHOP_TYPES.ENTERPRISE,
      isLoading: false,
      isLoadingIncreaseMaxUsers: false,
      isLoadingShopName: false,
      isLoadingCancelSubscription: false,
      isLoadingConfirmAddress: false,
      isLoadingChangingOEMRegions: false,
      isLoadingRepayDisputedPayment: false,
      isLoadingChargingRepayment: false,
      isLoadingCancelDowngradeSubscription: false,
      loadingAddress: '',
      paymentAmount: 0,
      showReceiptModal: false,
      currentReceipt: null,
      showCancelSubscriptionModal: false,
      showDowngradeSubscriptionModal: false,
      showPaymentHistoryModal: false,
      addressesToVerify: [],
      internationalAddressesToVerify: [],
      showOEMRegionRemovalModal: false,
      showOEMRegionReinstateModal: false,
      showRepayDisputedPaymentModal: false,
      oemIDs: null,
      oemIDsSelected: new Set(),
      oemIDsAdded: new Set(),
      confirmOemRegionModal: false,
      oemIDsRemoved: new Set(),
      regionCode: this.props.currentShop?.regionCode,
      showPriceBreakdown: false,
      perTechCertRate: 0,
      chartData: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentShop?.phone !== prevProps.currentShop?.phone) {
      this.setState({
        shopPhoneNumber: this.props.currentShop?.phone,
      });
    }

    if (this.props.currentShop?.oemIDs !== prevProps.currentShop?.oemIDs) {
      if(this.props.currentShop.oemIDs) this.props.currentShop.oemIDs.forEach((oemID) => {
        this.state.oemIDsSelected.add(oemID);
      });
    }
  }

  async componentDidMount() {
    let self = this;
    let config = this.props.config;

    const oemRegions = await getOEMRegionList();
    const oemIDsSelected = new Set();
    if(this.props?.currentShop?.oemIDs?.length) this.props.currentShop.oemIDs.forEach((oemRegionID) => {
      oemIDsSelected.add(oemRegionID);
    });

    this.setState({
      config: config,
      oemRegions: oemRegions,
      oemIDsSelected: oemIDsSelected,
    });

    let countryISO2Codes = Object.keys(
      countries.getNames('en', { select: 'official' })
    );
    if (countryISO2Codes.indexOf(this.state.country) === -1) {
      this.setState({
        country: '',
      });
    }

    // Set timeout to get the compatibility data in the background
    setTimeout(async () => {
      const response = await getRegionOemToolCompatibility();
      if (!response.error) {
        self.setState({
          chartData: response.data
        });
      }
    }, 350);

    if (this?.typeaheadRef?.current?._instance?.state) {
      this.typeaheadRef.current._instance.state.text =
        this.props.currentShop?.streetAddress1 || '';
    }
  }

  translateStatus(status) {
    if (status === 'submitted_for_settlement') {
      return (
        <b className="u-text-success">{TextOnly('submittedForSettlement')}</b>
      );
    } else if (status === 'settled') {
      return <b className="u-text-success">{TextOnly('settled')}</b>;
    } else if (
      status === 'gateway_rejected' ||
      status === 'processor_declined' ||
      status === 'settlement_declined'
    ) {
      return <b className="u-text-error">{TextOnly('failedCharge')}</b>;
    } else {
      return status;
    }
  }

  compareDates(a, b, desc){
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (desc) {
      if (dateA > dateB) {
        return 1;
      } else  {
        return -1;
      }
    } else {
      if (dateA < dateB) {
        return -1;
      } else {
        return 1;
      }
    }
  };


  render() {
    const isEnterprise = this.state.isEnterprise;

    return (
      <div className="home">
        <Header title={TextOnly('shopProfile')} />

          <div className={!this.props.user?.shops || !this.state?.oemRegions ? 'hide-element' : ''}>
            <div className="c-box--white u-padding-none">
              <Tabs
                defaultActiveKey={
                  (this.props.viewReceipt && this.props.transactionId) ?
                    'manageBilling' :
                    'shopInfo'
                }
              >
                <Tab
                  eventKey={'shopInfo'}
                  label={TextOnly('shopInfo')}
                  className="tab-shop-profile"
                >
                  <ShopInfo
                    currentShop={this.props?.currentShop}
                    config={this.state.config}
                    fetchUser={this.props.fetchUser}
                    />
                </Tab>
                { !isEnterprise ?
                  <Tab
                    className="tab-shop-profile"
                    eventKey={'subscriptions'}
                    label={TextOnly('subscriptions')}
                  >
                    <Subscriptions
                      user={this.props?.user}
                      config={this.state.config}
                      compatiblityChart={this.state.chartData}
                      currentShop={this.props?.currentShop}
                      oemRegions={this.state.oemRegions}
                      fetchUser={this.props.fetchUser}
                    />
                  </Tab>
                : '' }
                { !isEnterprise ?
                  <Tab
                    className="tab-shop-profile"
                    eventKey={'manageBilling'}
                    label={TextOnly('manageBilling')}
                  >
                    <ManageBilling
                      user={this.props?.user}
                      currentShop={this.props?.currentShop}
                      oemRegions={this.state.oemRegions}
                      config={this.state.config}
                      fetchUser={this.props.fetchUser}
                      viewReceipt={this.props.viewReceipt}
                      transactionId={this.props.transactionId}
                    />
                  </Tab>
                : '' }
            </Tabs>
          </div>
        </div>
        <div className={this.props.user?.shops && this.state?.oemRegions ? 'hide-element' : 'loading-panel'}>
          <span>
            <i
              className="fal fa-spinner-third spinning"
              title={TextOnly('loading')}
            />
          </span>
        </div>

      </div>
    );
  }
}
