import React, { Component } from 'react';
import { resendSignUpCode } from 'aws-amplify/auth';
import LoaderButton from '../components/LoaderButton';
import { usernameValidate, fieldChangeCase } from '../libs/utils';
import './Signup.css';
import { logPasswordResetEmailResend } from '../libs/db-lib';
import { Text, TextOnly } from '../components/Text';
import { toast } from 'react-toastify';

export default class ResendConfirmCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: '',
      newUser: null,
    };
  }

  validateForm() {
    return usernameValidate(this.state.username);
  }

  handleChange = (event) => {
    if (event.target.id === 'username') {
      fieldChangeCase(this, event.target, 'lower', false);
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };
  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await logPasswordResetEmailResend(this.state.username, 1);
      await resendSignUpCode({ username: this.state.username });
      const codeSentToast = toast.success(
        `${TextOnly('accountValidationLinkSent')} ${
          this.state.username
        }. ${TextOnly('afterValidatingLogin')}`,
        { autoClose: false, containerId: 'standard' }
      );
      this.props.history.push({
        pathname: '/Login',
        state: {
          previousToast: codeSentToast,
        },
      });
    } catch (e) {
      let errorMsg = e.message;
      if (errorMsg === 'Username/client id combination not found.') {
        const codeSentToast = toast.success(
          `${TextOnly('accountValidationLinkSent')} ${
            this.state.username
          }. ${TextOnly('afterValidatingLogin')}`,
          { autoClose: false, containerId: 'standard' }
        );
        this.props.history.push({
          pathname: '/Login',
          state: {
            previousToast: codeSentToast,
          },
        });
      } else {
        alert(errorMsg);
      }
    }
    this.setState({
      isLoading: false,
      newUser: null,
    });
  };

  render() {
    return (
      <>
        {this.state.newUser === null ? (
          <div className="l-container-med">
            <form onSubmit={this.handleSubmit}>
              <h1>
                <Text tid="resendConfirmLink" />
              </h1>
              <div className="c-field">
                <label htmlFor="username" className="c-field__label">
                  <Text tid="username" />:
                </label>
                <input
                  id="username"
                  className={`c-input ${
                    this.state.username.length > 0 &&
                    !usernameValidate(this.state.username)
                      ? 'error'
                      : ''
                  }`}
                  maxLength="50"
                  type="text"
                  placeholder={TextOnly('username')}
                  value={this.state.username}
                  onChange={this.handleChange}
                />
                {this.state.username.length > 0 &&
                !usernameValidate(this.state.username) ? (
                  <div className="c-field__error">
                    <Text tid="usernameHint" />
                  </div>
                ) : null}
              </div>
              <LoaderButton
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('resendConfirmLink')}
                loadingText={TextOnly('sendingLink')}
              />
            </form>
          </div>
        ) : null}
      </>
    );
  }
}
