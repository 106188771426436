import React, { Component } from 'react';
import { verifyEmail } from '../libs/db-lib';
import { Loading } from '../components/Loading';

export default class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { code, username, clientId, email, language } =
      this.props?.location?.state;

    const response = await verifyEmail(
      code,
      username,
      clientId,
      email,
      language
    );
    this.props.history.push({
      pathname: '/Login',
      state: {
        emailVerified: response?.error ? response.error : response.message,
        success: response?.error ? false : true,
        username: username,
        language: language,
      },
    });
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <div className="l-container-lg l-full-height">
        <div className="verifyEmail">
          <Loading />
        </div>
      </div>
    );
  }
}
