import { Dialog } from '@reach/dialog';
import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';

export default class Banner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      textModal: '',
    };
  }

  render() {

    const bannerList = this.props.bannerInfo;

    if (!bannerList) return '';

    return (
      <>
        {
          bannerList.map((bannerInfo, idx) => {
              if (!bannerInfo) return null;
              const {message, severity, link} = bannerInfo;
              if (!message) return null;

              let backgroundBanner = '';
              switch (severity) {
                case 'ERROR':
                  backgroundBanner = 'awsError';
                  break;
                case 'WARNING':
                  backgroundBanner = 'awsWarning';
                  break;
                case 'GOOD':
                  backgroundBanner = 'awsGood';
                  break;
                default:
                  backgroundBanner = 'awsError';
                  break;
              }

              let messageText = severity === "ERROR" ? message.toUpperCase() : message;
              let modalElement = (
                <div className={`modal-banner-element  ${backgroundBanner}`}>
                  <div className='display-flex flex-space-between'>
                    {severity === 'ERROR' &&
                      <div className='aws-banner-triangle-color'>
                        <i className="c-btn__icon fa fa-exclamation-triangle"/>
                      </div>
                    }
                    {messageText}
                    {severity === 'ERROR' &&
                      <div className='aws-banner-triangle-color'>
                        <i className="c-btn__icon fa fa-exclamation-triangle"/>
                      </div>
                    }
                  </div>
                  {link &&
                    <div className='bottom-button-banner'>
                      <LoaderButton
                        type="submit"
                        isLoading={false}
                        disabled={false}
                        text={'Go to Link'}
                        onClick={()=>{
                          this.setState({
                            showModal: false,
                            textModal: '',
                          })
                          this.props.history.push(link)
                        }}
                        loadingText=''
                      />
                    </div>
                  }
                </div>
              )
              let messageTxtHtml = <span
                className='aws-banner-text'
                onClick={()=>{
                  this.setState({
                    showModal: true,
                    textModal: modalElement,
                  })
                }}
              >
                {messageText}
              </span>;
              return (
                <div className={`awsBanner${bannerList[0]?.message ? idx : 0} ${backgroundBanner}`}
                    key={idx}>
                  {severity === 'ERROR' &&
                    <div className='aws-banner-triangle-color'>
                      <i className="c-btn__icon fa fa-exclamation-triangle"/>
                    </div>
                  }
                  {messageTxtHtml}
                  {severity === 'ERROR' &&
                    <div className='aws-banner-triangle-color'>
                      <i className="c-btn__icon-right fa fa-exclamation-triangle"/>
                    </div>
                  }
                </div>
              );
            }
          )
        }
        <Dialog
          isOpen={this.state.showModal}
          onDismiss={()=>this.setState({
            showModal: false,
            textModal: '',
          })}
          className="c-modal"
          aria-label="ShowBanner"
        >
          {this.state.textModal}
        </Dialog>
      </>
    )
  }
}
