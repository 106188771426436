import React from 'react';
import { TextOnly, Text } from '../../../components/Text';
import { HIGHLIGHTER_COLORS_OBJ, HIGHLIGHTER_OPTIONS } from '../CrmConstants';


const HighlighterColorComp: React.FC<any> = (props) => {
  const {
    isLoading,
    highlighterColor,
    setHighlighterColor,
  } = props;


  return (
    <div className="c-field">
      <label className={`c-field__label`}>
        <span className="u-text-error">*</span>{' '}
        <Text tid="appointmentDisplayColor" />:
      </label>
      <div
        className={`c-select ${highlighterColor ? `${HIGHLIGHTER_COLORS_OBJ[highlighterColor]} highlighter` : ''}`}
      >
        <select
          value={highlighterColor}
          onChange={(event) =>
            setHighlighterColor(event.target.value)
          }
          disabled={isLoading}
          className={'select-highlightercolor ' + (highlighterColor ? 'u-text-shadow u-text-white' : '')}
        >
          <option value="" disabled>
            {TextOnly('chooseHighlighterColor')}
          </option>
          {
            HIGHLIGHTER_OPTIONS.map(((highlighterColor:any) => {
              return (
                <option value={highlighterColor.value} key={highlighterColor.value}>
                  {highlighterColor.label}
                </option>
              )
            }))
          }
        </select>
      </div>
      { !highlighterColor &&
        <div className="c-field__error">
          <Text tid="highlighterColorHint" />
        </div>
      }
    </div>
  );
};

export default HighlighterColorComp;
