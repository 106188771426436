import React, { useState, useEffect } from 'react';
import KanbanCard from './KanbanCard';
import { Droppable } from './Droppable';

interface ColumnProps {
  title: string;
  value: string;
  columnNum: number;
  data: any[];
  currentShop: any;
  shopCustomers: any[];
  handleOrderChange: any;
  user: any;
  isAccordion?: boolean;
}

const KanbanColumn: React.FC<ColumnProps> = (props) => {
  const {
    title,
    value,
    columnNum,
    data,
    currentShop,
    handleOrderChange,
    shopCustomers,
    user,
    isAccordion,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandColumn = () => {
    if (isAccordion) {
      setIsExpanded(!isExpanded ? true : false);
    }
  }

  return (
    <div className="kanban-column">
      <div
        className={`
          kanban-column${(isAccordion && !isExpanded) ? '--accordion' : '--title'}
          status-border-color-${columnNum}
          ${isAccordion ? 'u-height-50px u-cursor-pointer' : ''}
        `}
        onClick={handleExpandColumn}
      >
        {isAccordion && (
          <div className="h2 u-justify-self-start">
            <i className={`fa fa-caret-right kanban-expand-icon ${isExpanded ? 'expand-icon-rotate' : ''}`} aria-hidden="true"></i>
            &nbsp; &nbsp;
          </div>
        )}
        <div className="h2">{title}</div>
        {isAccordion && (
          <div className="h2">
            &nbsp;
            ({data.length ?? 0})
          </div>
        )}
      </div>

      {isAccordion ? (
        isExpanded && (
          <div className={`c-box--kanban u-height-min-100`}>
            {data.sort((a, b) => b.orderNumber - a.orderNumber).map(order => (
              <KanbanCard
                key={order.orderID}
                data={order}
                currentShop={currentShop}
                shopCustomers={shopCustomers}
                onChange={handleOrderChange}
                user={user}
                isAccordion={true}
              />
            ))}
          </div>
        )
      ) : (
        <Droppable id={value} className={'u-height-100-percent'}>
          <div className={"c-box--kanban u-height-min-800"}>
            {data.sort((a, b) => b.orderNumber - a.orderNumber).map(order => (
              <KanbanCard
                key={order.orderID}
                data={order}
                currentShop={currentShop}
                shopCustomers={shopCustomers}
                onChange={handleOrderChange}
                user={user}
              />
            ))}
          </div>
        </Droppable>
      )}
    </div>
  );
};

export default KanbanColumn;