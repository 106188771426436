import React from 'react';

interface Filters {
  [key: string]: {
    key: string;
    value: string;
    label: string;
    pillLabel: string;
  }[];
}

interface FilterPills {
  filters: Filters;
  onFilterClick: ({ field, value }: { field: string; value: string }) => void;
}

export const FilterPills = ({ filters, onFilterClick }: FilterPills) => {
  const filterKeys = Object.keys(filters);
  return filterKeys.map((filter, index) => (
    <div key={filterKeys[index]}>
      {filters[filter].map((item) => (
        <button
          key={item.value}
          className="c-btn-filter u-margin-right"
          onClick={() =>
            onFilterClick({
              field: filterKeys[index],
              value: item.key,
            })
          }
        >
          <div className="c-btn__inner">
            <span>{item.pillLabel}</span>
            <i className="c-btn__icon fal fa-times-circle" />
          </div>
        </button>
      ))}
    </div>
  ));
};
