import { DateTime } from '../DateTime';
import React from 'react';
import { capitalize } from '../../libs/utils-ts';
import { Text, TextOnly } from '../Text';

interface ToolErrorsProps {
  apiErrorMessage: string;
  actionDate: string;
  name: string;
  toolSerial: string;
  toolManufacturer: string;
  toolModel: string;
  vin: string;
}

export const ToolErrors = ({
  apiErrorMessage,
  actionDate,
  name,
  toolSerial,
  toolManufacturer,
  toolModel,
  vin,
}: ToolErrorsProps) => {
  return (
    <p>
      <dl>
        <dt>
          <Text tid="error" />
        </dt>
        <dd>{apiErrorMessage}</dd>
      </dl>
      <dl>
        <dt>
          <span>{TextOnly('date')}/{capitalize(TextOnly('time'))}</span>
        </dt>
        <dd>{new DateTime(actionDate).format('MM/DD/YYYY hh:mmA')}</dd>
      </dl>
      <dl>
        <dt>
          <Text tid="tech" />
        </dt>
        <dd><span>{name}</span></dd>
      </dl>
      <dl>
        <dt>
          <Text tid="serialNumber" />
        </dt>
        <dd><span>{toolSerial}</span></dd>
      </dl>
      <dl>
        <dt>
          <Text tid="manufacturer" />
        </dt>
        <dd><span>{toolManufacturer}</span></dd>
      </dl>
      <dl>
        <dt>
          <Text tid="model" />
        </dt>
        <dd><span>{toolModel}</span></dd>
      </dl>
      <dl>
        <dt>
          <Text tid="vin" />
        </dt>
        <dd><span>{vin}</span></dd>
      </dl>
    </p>
  );
};
