import React, { useState, createContext, useEffect } from 'react';
import { languageOptions, dictionaryList } from './languages';
import { getUIStringTableForLanguage } from './db-lib';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
  userLanguageChange: (newLang) => {},
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState(getDefaultLanguage());
  const [dictionary, setDictionary] = useState(getDefaultDictionary());
  const [provider, setProvider] = useState(null);

  const userLanguageChange = async (newLang) => {
    document.querySelector('.l-container')?.classList.add('u-blur');
    document.querySelector('.spinner-blur')?.classList.remove('u-is-hidden');
    const newLanguage = languageOptions[newLang]
      ? newLang
      : getDefaultLanguage();
    getUIStringTableForLanguage(newLang, true).then((dictionary) => {
      window.localStorage.setItem('rcml-lang-dict', JSON.stringify(dictionary));
      window.localStorage.setItem('rcml-lang', newLang);
      const currentTime = (new Date()).getTime();
      window.localStorage.setItem('rcml-lang-dict-age', currentTime.toString());
      document.querySelector('.spinner-blur')?.classList.add('u-is-hidden');
      document.querySelector('.l-container')?.classList.remove('u-blur');
      setUserLanguage(newLanguage);
      setDictionary(dictionary);
    });
  }

  const fetchAndSetLanguage = async () => {
    const savedDict = window.localStorage.getItem('rcml-lang-dict');
    // We need to check for the string "undefined" because Opera browser returns this when the localStorage
    // variable doesn't exist
    let langDict = (savedDict && savedDict !== "undefined") ?
      JSON.parse(window.localStorage.getItem('rcml-lang-dict'))
      : null;
    const langDictAge = window.localStorage.getItem('rcml-lang-dict-age')
                          ? parseInt(window.localStorage.getItem('rcml-lang-dict-age'))
                          : null;
    const currentTime = (new Date()).getTime();
    const lang = window.localStorage.getItem('rcml-lang') || window.navigator.language.substring(0, 2);

    // If the user's language or dictionary is undefined, or if the dictionary was fetched over a week ago, refetch
    if (!lang || ! langDict || !langDictAge || (currentTime - langDictAge > (3600000 * 24 * 7))) {
      langDict = await getUIStringTableForLanguage(lang, true);
      window.localStorage.setItem('rcml-lang-dict', JSON.stringify(langDict));
      window.localStorage.setItem('rcml-lang', lang);
      window.localStorage.setItem('rcml-lang-dict-age', currentTime.toString());
      setUserLanguage(lang);
      setDictionary(langDict);
    } else {
      setProvider({
        userLanguage: lang,
        dictionary: langDict,
        userLanguageChange,
      });
    }
  }

  useEffect(()=>{
    if (!provider) fetchAndSetLanguage();
    else {
      setProvider({
        userLanguage,
        dictionary,
        userLanguageChange,
      })
    }
  }, [userLanguage, dictionary]);

  return (
    !provider ? null :
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}

function getDefaultLanguage() {
  let defaultLanguage = window.localStorage.getItem('rcml-lang');
  // We need to check for the string "undefined" because Opera browser returns this when the localStorage
  // variable doesn't exist
  if (!defaultLanguage || defaultLanguage === "undefined") {
    defaultLanguage = window.navigator.language.substring(0, 2);
    window.localStorage.setItem('rcml-lang', defaultLanguage);
  }
  return defaultLanguage;
}

function getDefaultDictionary() {
  const savedDict = window.localStorage.getItem('rcml-lang-dict');
  return (savedDict && savedDict !== "undefined") ? JSON.parse(savedDict) : dictionaryList['en']
}

export function useLanguage() {
  return React.useContext(LanguageContext);
}
