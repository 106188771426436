import React, { useEffect } from 'react';
import { useUser } from '../context/User';
import { SHOP_STATES } from '../CONSTANTS';
import _sortBy from 'lodash/sortBy';
import { TextOnly } from '../components/Text';
import { useHistory } from 'react-router-dom';

interface SelectShopProps {
  setSelection: any;
}

const SelectShop: React.FC<SelectShopProps> = (props) => {
  const { setSelection } = props;
  const { user, currentShop, updateCurrentShop } = useUser();
  let history = useHistory();

  useEffect(() => {
    setSelection(currentShop);
  }, [currentShop]);

  function handleShopChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    let newShop = event.target.value;
    updateCurrentShop(newShop);
    history.push('/myActivity');
  }

  const filteredShops = user?.shops.filter(
    (shop) =>
      shop.state !== SHOP_STATES.PENDING &&
      shop.shopUserState !== SHOP_STATES.PENDING
  );
  const shops = filteredShops?.map((shop) => {
    return { name: shop.shopName, id: shop.shopID };
  });
  const sortedShops = _sortBy(shops, 'name');

  const hasManyShops = shops && shops.length > 1;
  const shopOptions = hasManyShops
    ? sortedShops?.map(({ name, id }) => (
        <option key={id} value={id}>
          {TextOnly('shop')} : {name}
        </option>
      ))
    : [];

  return (
    <>
      {hasManyShops ? (
        <div className="c-select">
          <select
            value={currentShop?.shopID || undefined}
            onChange={handleShopChange}
            id="tools"
          >
            {shopOptions}
          </select>
        </div>
      ) : null}
    </>
  );
}

export default SelectShop;