import React from 'react';
import { useHistory } from 'react-router-dom';
import { TextOnly } from '../../components/Text';


const GoBackArrow = () => {
  const history = useHistory();

  const handleGoBack = () => {
    const currentPath = window.location.pathname;
    window.scrollTo(0, 0);
    window.location.hash = '';
    if (window.history.length > 1) {
      history.goBack();
    } else if (currentPath.includes('messageBoard')) {
      history.push('/messageBoard');
    } else {
      history.push('/myActivity');
    }
    window.scrollTo(0, 0);
    window.location.hash = '';
  };

  return (
    <div className="c-btn__inner marginleft-15px">
      <span
        className="c-btn__icon color-aa-darkblue u-cursor-pointer fas fa-chevron-left"
        onClick={()=>handleGoBack()}
        title={TextOnly('goBack')}
      />
    </div>
  );
};

export default GoBackArrow;