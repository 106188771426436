import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';
import braintree from 'braintree-web-drop-in';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { createShop } from '../libs/db-lib';
import { getBraintreeConfig } from '../libs/utils-ts';
import { Text, TextOnly } from '../components/Text';

export default class AddServiceCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
      city: '',
      state: '',
      zip: '',
      shop: '',
    };

    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    braintree.create(getBraintreeConfig('sandbox'),
      function (createErr, instance) {
        var button = document.getElementById('submit-button');
        button.addEventListener('click', function () {
          instance.requestPaymentMethod(function (
            requestPaymentMethodErr,
            payload
          ) {
            //console.log("braintree payload: ", payload);
            // Submit payload.nonce to your server
          });
        });
      }
    );
  }

  validateForm() {
    return (
      this.state.shop.length > 0 &&
      this.state.address.length > 0 &&
      this.state.city.length > 0 &&
      this.state.state.length > 0 &&
      this.state.zip.length > 0
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleRegionChange = (value) => {
    this.setState({
      state: value,
      region: value,
    });
  };

  handleCountryChange = (value) => {
    this.setState({
      country: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      createShop(this.state.shop);
      this.props.history.push('/myActivity');
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    const { country, region } = this.state;

    return (
      <div>
        <div>
          <form onSubmit={this.handleSubmit}>
            <h2 className="u-text-center">
              <Text tid="addServiceCenter" />
            </h2>
            <div controlId="shop" className="c-field">
              <label htmlFor="serviceCenterName" className="c-field__label">
                <Text tid="serviceCenterName" />
              </label>
              <input
                autoFocus
                id="serviceCenterName"
                placeholder={TextOnly('serviceCenterName')}
                type="text"
                value={this.state.shop}
                onChange={this.handleChange}
              />
            </div>
            <div controlId="address" className="c-field">
              <label htmlFor="address" className="c-field__label">
                <Text tid="address" />
              </label>
              <input
                autoFocus
                type="text"
                id="address"
                placeholder={TextOnly('address')}
                value={this.state.address}
                onChange={this.handleChange}
              />
            </div>
            <div controlId="city" className="c-field">
              <label htmlFor="city" className="c-field__label">
                <Text tid="city" />
              </label>
              <input
                autoFocus
                id="city"
                type="text"
                placeholder={TextOnly('city')}
                value={this.state.city}
                onChange={this.handleChange}
              />
            </div>
            <div controlId="region" className="c-field">
              <RegionDropdown
                country={country}
                value={region}
                onChange={this.handleRegionChange}
              />
            </div>
            <div controlId="zip" className="c-field">
              <label htmlFor="zip" className="c-field__label">
                <Text tid="zipCode" />
              </label>
              <input
                autoFocus
                type="text"
                id="zip"
                placeholder={TextOnly('zipCode')}
                value={this.state.zip}
                onChange={this.handleChange}
              />
            </div>
            <div controlId="country" className="c-select c-field">
              <CountryDropdown
                value={country}
                onChange={this.handleCountryChange}
              />
            </div>
            <div controlId="phone" className="c-field">
              <label htmlFor="phone" className="c-field__label">
                <Text tid="phone" />
              </label>
              <input
                autoFocus
                type="text"
                id="phone"
                placeholder={TextOnly('phoneNumber')}
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <div id="braintree-container"></div>
            </div>

            <div className="btn-container">
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!this.validateForm()}
                text={TextOnly('signup')}
                loadingText={TextOnly('signingUp')}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
