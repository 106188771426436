import { Dialog } from '@reach/dialog';
import React, { FC } from 'react';
import { TextOnly } from '../Text';

interface ConfirmReadyForPickUpNotificationProps {
  isOpen: boolean;
  onSubmit: Function;
}

const ConfirmReadyForPickUpNotification: FC<ConfirmReadyForPickUpNotificationProps> = ({
  isOpen,
  onSubmit,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      className={ "c-modal send-notification-modal"}
      aria-label="ConfirmModal"
    >
      <div className='h1 margin-2rem'><span>{TextOnly('sendCustomerNotification')}</span></div>
      <div className="h2 c-modal__heading">
        <span>{TextOnly("confirmCustomerNotificationMessage")}</span>
      </div>
      <div className='display-flex-justify-content-center alignitems-baseline margin-16px'>
        <button
          className="c-btn"
          onClick={() => {
            onSubmit(true);
          }}
        ><span>{TextOnly('send')}</span>
        </button>
        {'\u00A0'}
        <button
          className="c-btn-outline"
          onClick={() => {
            onSubmit(false);
          }}
        >
          <span>{TextOnly('dontSend')}</span>
        </button>
      </div>
    </Dialog>
  );
};

export default ConfirmReadyForPickUpNotification;