import React, { useState, useEffect } from 'react';
import _ from 'underscore';
var QRCode = require('qrcode');

import { Dialog } from '@reach/dialog';
import { Loading } from "../../components/Loading";
import PhoneInputWithVerification from "../../components/PhoneInputWithVerification";
import {
  createVerificationSession,
  verifyUser,
  requestCertification,
  checkVerificationSessionStatus,
} from '../../libs/db-lib';
import { Text, TextOnly, LangDictType } from '../../components/Text';
import LoaderButton from '../../components/LoaderButton';
import AlertModal from '../../components/AlertModal';


import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import { useUser } from '../../context/User';
import { ChildProps } from '../../types';

let timeoutId: any, intervalId: any;
interface TechVerificationProps extends ChildProps {
  setCertStatus: Function
}

const TechVerification: React.FC<TechVerificationProps> = (props) => {

  const { user } = useUser();
  const [ showVerifyModal, setShowVerifyModal ] = useState <boolean> (false);
  const [ becomingCertified, setBecomingCertified ] = useState <boolean> (false);
  const [ idVerified, setIdVerified ] = useState <boolean> (user ? user.idVerified : false);
  const [ verifyingID, setVerifyingID ] = useState <boolean> (false);
  const [ updatingPhone, setUpdatingPhone ] = useState <boolean> (false);
  const [ phoneNumber, setPhoneNumber ] = useState <string> (user?.phoneNumber || '');
  const [ alertTitle, setAlertTitle ] = useState <string> ('');
  const [ alertMessage, setAlertMessage ] = useState <string> ('');
  const [ showAlertModal, setShowAlertModal ] = useState <boolean> (false);

  const searchParams = new URLSearchParams(window.location.search);
  const IDSubmitComplete = (searchParams.get('IDSubmitComplete'))?.replace(/ /g, '+');

  useEffect(() => {
    if (IDSubmitComplete) {
      const asyncCheckIDStatus = async (processInstanceId: any) => {
        checkIDStatus(processInstanceId);
      }
      const processInstanceId = window.localStorage.getItem('processInstanceId');
      setVerifyingID(true);
      asyncCheckIDStatus(processInstanceId);
    }

    // componentWillUnmount
    return () => {
      if (intervalId) clearInterval(intervalId);
      if (timeoutId) clearTimeout(timeoutId);
      intervalId = null;
      timeoutId = null;
    }
  }, []);

  useEffect(() => {
    setUpdatingPhone(false);

    let certifiedStatus;
    let certifiedStatusColor;
    if (user?.idVerified && user?.phoneVerified) {
      if (!user?.certifiedTech) {
        certifiedStatus = 'awaitingSubmitVerification';
        certifiedStatusColor = 'u-text-warning';
      } else {
        certifiedStatus = 'certificationComplete';
        certifiedStatusColor = 'u-text-success';
      }
    } else if (user?.idVerified) {
      certifiedStatus = 'awaitingPhoneVerification';
      certifiedStatusColor = 'u-text-warning';
    } else if (user?.phoneVerified) {
      certifiedStatus = 'awaitingIDVerification';
      certifiedStatusColor = 'u-text-warning';
    } else {
      certifiedStatus = 'awaitingIDPhoneVerification';
      certifiedStatusColor = 'u-text-error';
    }

    props.setCertStatus({
      certifiedStatus,
      certifiedStatusColor
    })
  }, [user]);

  const checkIDStatus = async (processInstanceId: string) => {
    const result = await checkVerificationSessionStatus(processInstanceId);
    if (result === "Accepted") {
      setIdVerified(true);
      setShowVerifyModal(false);
      setVerifyingID(false);
      toast.success(TextOnly('idVerified'), { containerId: 'standard' });
      if (intervalId) clearInterval(intervalId);
      if (timeoutId) clearTimeout(timeoutId);
      props.fetchUser();
    } else if (result === "Abandoned" ||
              result === "Declined" ||
              result === "Error") {
      setVerifyingID(false);
      setShowVerifyModal(false);
      toast.error(TextOnly('errorVerifyingId'), { containerId: 'standard' });
      if (intervalId) clearInterval(intervalId);
      if (timeoutId) clearInterval(intervalId);
      props.fetchUser();
    }
  }

  const stopIdVerifyCheck = () => {
    clearInterval(intervalId);
    setVerifyingID(false);
    setShowVerifyModal(false);
    toast.error(TextOnly('timeoutVerifyingId'), { containerId: 'standard' });
    clearInterval(intervalId);
  }

  const handleBecomeCertified = async () => {
    setBecomingCertified(true);
    const result: any = await requestCertification(props.currentShop.shopID);
    let errorMsg = result.error;
    if (errorMsg) {
      if (errorMsg === 'No TechCerts Available') {
        errorMsg = <Text
          tid='purchaseTechCerts'
          sub={{ shopProfileLink: <a href='/ShopProfile'>{TextOnly('shopProfile')}</a> }} />
        setAlertTitle(TextOnly("noTechsAvailable"));
      } else {
        setAlertTitle(TextOnly("error"));
      }
      setAlertMessage(errorMsg);
      setShowAlertModal(true);
      setBecomingCertified(false);
    } else {
      props.fetchUser();
      toast.success(TextOnly('youAreCertified', {phone: phoneNumber}),
        { containerId: 'standard' }
      );
    }
  }

  const handleCancelModal = () => {

    setShowVerifyModal(false);
    setVerifyingID(false);
    setShowAlertModal(false);
  };

  const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  const startDLVerification = async () => {

    setVerifyingID(true);

    if (isMobile()) {
      // We are running on a mobile device
      let verifySession = await createVerificationSession(true);
      window.localStorage.setItem('processInstanceId', verifySession.processInstanceId);
      window.open(verifySession.redirectUrl, '_self');

    } else {
      // We are running on a browser
      let verifySession = await createVerificationSession(props.currentShop.shopID);

      if (verifySession.error) {
        let errorMsg = verifySession.error;
        if (errorMsg === 'No TechCerts Available') {
          errorMsg = <Text
            tid='purchaseTechCerts'
            sub={{ shopProfileLink: <a href='/ShopProfile'>{TextOnly('shopProfile')}</a> }} />
          setAlertTitle(TextOnly("noTechsAvailable"));
        } else {
          setAlertTitle(TextOnly("error"));
        }
        setAlertMessage(errorMsg);
        setShowAlertModal(true);
      } else {
        setShowVerifyModal(true);
        intervalId = setInterval(checkIDStatus, 15000, verifySession.processInstanceId);
        // This timeout is to stop the interval checked after 3 minutes
        timeoutId = setTimeout(stopIdVerifyCheck, 180000);
        setTimeout(() => {
          var canvas = document.getElementById('canvas');
          QRCode.toCanvas(canvas, verifySession.redirectUrl, (err: any) => {
            if (err) {
              console.log("Error: ", err);
            }
          });
        }, 5);
      }
  }
  };



  return (
    <>
      <div className="l-container">
        <div className={!user?.certifiedTech ? "flex-column-space-between-certify-tech-box" : ""}>
          { user?.certifiedTech ?
            <h2>
              <Text tid='AACertifiedTech' />
              <img src={'/AA-cert-tech.png'} className="c-logo__image" alt="AutoAuth Certified Technician" />
            </h2>
          : <label className="u-margin-bottom-large">
              <Text tid="verificationHint" />
            </label> }
          <>
            <div className={`c-field`}>
              <label htmlFor="phone" className="c-field__label">
                { !idVerified ?
                <>
                  <span className="u-text-error">*</span>
                  <Text tid='idDocument' />
                </>
                : '' }
              </label>
              { !idVerified ?
                <LoaderButton
                  type="button"
                  className={''}
                  isLoading={verifyingID}
                  text={idVerified ? TextOnly('idVerified') : TextOnly('verifyId')}
                  loadingText={TextOnly('verifyingId')}
                  disabled={idVerified}
                  onClick={ (e) => {
                    e.preventDefault();
                    startDLVerification();
                  }}
                />
                :
                  <div>
                    <label>
                      <span className="u-text-success u-font-weight-bold">
                      { user?.idDocumentType ? user.idDocumentType : <Text tid="idDocument" /> }
                      </span>
                      <i
                        className="u-margin-left font-size-large text-bold u-text-success fa-regular fa-check"
                        title="fa-check"
                      />
                    </label>
                  </div>
              }
            </div>
            <div className={`c-field`}>
              { !user?.phoneVerified || updatingPhone ?
                <>
                  <label htmlFor="phone" className="c-field__label">
                    <span className="u-text-error">*</span>{' '}
                    <Text tid="phoneNumber" />
                  </label>
                  <PhoneInputWithVerification
                    fetchUser={props.fetchUser}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    user={user}
                    currentShop={props.currentShop}
                  />
                </>
                :
                <div className="l-flex-between l-flex-align-center">
                  <div>
                    <label>
                      <span className="u-text-success u-font-weight-bold">
                        { user?.phoneNumber }
                      </span>
                      <i
                        className="u-margin-left font-size-large text-bold u-text-success fa-regular fa-check"
                        title="fa-check"
                      />
                    </label>
                  </div>
                  <button
                    className="c-btn-outline c-btn"
                    onClick={() => {
                      setUpdatingPhone(true);
                    }}
                  >
                    <Text tid="edit" />
                  </button>
                </div>
              }
            </div>
            { !user?.certifiedTech ?
              <div className={'bottom-btn-container'}>
                <LoaderButton
                  type="button"
                  isLoading={becomingCertified}
                  text={TextOnly('becomeCertified')}
                  loadingText={TextOnly('becomingCertified')}
                  disabled={(!user?.phoneVerified) || !idVerified}
                  onClick={ (e) => {
                    e.preventDefault();
                    handleBecomeCertified();
                  }}
                />
              </div>
              : '' }
          </>
        </div>
      </div>
      <Dialog
        isOpen={showVerifyModal}
        className="c-modal"
        aria-label={TextOnly('verifyId')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="verifyId" />
        </h1>

        <div className="c-modal__body">
          <p>
            <Text tid="scanQRCode" />
          </p>
          <p>
          <div className="u-text-center">
            <canvas id="canvas"></canvas>
          </div>
          </p>
        </div>

      </Dialog>

      <AlertModal
        title={alertTitle}
        message={alertMessage}
        showModal={showAlertModal}
        size="small"
        handleCancel={handleCancelModal}
      />
    </>
  );
}

export default TechVerification;
