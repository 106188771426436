import React from 'react';

import { languageOptions } from '../libs/languages';
import { useLanguage } from '../libs/Language';

export default function LanguageSelector(props) {
  const { userLanguage, userLanguageChange } = useLanguage();

  // set selected language by calling context method
  const handleLanguageChange = (e) => {
    userLanguageChange(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div className="c-select u-margin-bottom-large background-white">
      <select
        className="aa-select"
        onChange={handleLanguageChange}
        value={userLanguage}
      >
        {Object.entries(languageOptions).map(([id, name]) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}
