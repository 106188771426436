import React, { useEffect, useState, ChangeEvent } from 'react';
import LoaderButton from '../../components/LoaderButton';
import { TextOnly, Text } from '../../components/Text';
import { isPossiblePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { isValidEmail, nameValidate, validateVIN } from '../../libs/utils';
import { toast } from 'react-toastify';
import { checkAutoVin, createDriverVerification } from '../../libs/db-lib';
import { statesUSA } from '../../components/StatePicker';
import ConfirmModal from '../../components/ConfirmModal';
import BasicPhoneInput from '../../components/BasicPhoneInput';

const RequestVerification = (props: any) => {
  const { shopRequests, setShopRequests } = props;
  const stateOptions = Object.keys(statesUSA).sort();

  const [showRequestForm, setShowRequestForm] = useState<boolean>(false);
  const [loadingDriver, setLoadingDriver] = useState<boolean>(false);
  const [loadingInfoVin, setLoadingInfoVin] = useState<boolean>(false);
  const [checkVinCalled, setCheckVinCalled] = useState<boolean>(false);
  const [showAutoInputs, setShowAutoInputs] = useState<boolean>(false);
  const [verifyIdentityInPerson, setVerifyIdentityInPerson] = useState<boolean>(false);
  const [checkBoxViip, setCheckBoxViip] = useState<boolean>(false);
  const [showVIIPModal, setShowVIIPModal] = useState<boolean>(false);

  const [driverName, setDriverName] = useState<string>('');
  const [emailInput, setEmailInput] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [driverStreetAddress, setDriverStreetAddress] = useState<string>('');
  const [driverCity, setDriverCity] = useState<string>('');
  const [driverZipCode, setDriverZipCode] = useState<string>('');
  const [driverLicenseNumber, setDriverLicenseNumber] = useState<string>('');
  const [driverLicenseState, setDriverLicenseState] = useState<string>('');
  const [driverLicenseExpirationDate, setDriverLicenseExpirationDate] = useState<string>('');
  const [vinInput, setVinInput] = useState<string>('');
  const [vehicleMake, setVehicleMake] = useState<string>('');
  const [vehicleModel, setVehicleModel] = useState<string>('');
  const [vehicleYear, setVehicleYear] = useState<string>('');
  const [servicePerformed, setServicePerformed] = useState<string>('');
  const [selectService, setSelectService] = useState<'KEYCUT' | 'IMMO' | 'OTHER' | ''>('');
  const [contactPref, setContactPref] = useState<'EMAIL' | 'PHONE' | 'BOTH' | ''>('');
  const [odometerNotAvailable, setOdometerNotAvailable] = useState<boolean>(false);
  const [showOdometerError, setShowOdometerError] = useState<boolean>(false);
  const [vehicleStateRegistration, setVehicleStateRegistration] = useState<string>('');
  const [vehicleOdometer, setVehicleOdometer] = useState<string>('');
  const [vehicleColor, setVehicleColor] = useState<string>('');
  const [vehicleLPN, setVehicleLPN] = useState<string>('');
  const [pendingValues, setPendingValues] = useState<any[]>([]);

  const handleChangeDriverName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDriverName(event.target.value);
  }
  const handleChangeEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
  }
  const handleChangePhoneNumber = (number: string) => {
    setPhoneNumber(number);
  }
  const handleChangeVinInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckVinCalled(false);
    setShowAutoInputs(false);
    setVehicleMake('');
    setVehicleModel('');
    setVehicleYear('');
    setVinInput(event.target.value.toUpperCase());
  }
  // const handleChangeVehicleMake = (event: React.ChangeEvent<HTMLInputElement>) =>
  //   setVehicleMake(event.target.value);
  // const handleChangeVehicleModel = (event: React.ChangeEvent<HTMLInputElement>) =>
  //   setVehicleModel(event.target.value);
  // const handleChangeVehicleYear = (event: React.ChangeEvent<HTMLInputElement>) =>
  //   setVehicleYear(event.target.value);
  const handleChangeContactPref = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setContactPref(event.target.value as 'EMAIL' | 'PHONE' | 'BOTH');
  }
  const handleChangeSelectServicePerformed = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const serviceSelected = event.target.value;
    setSelectService(serviceSelected as 'KEYCUT' | 'IMMO' | 'OTHER');
    if(serviceSelected === 'KEYCUT' || serviceSelected === "IMMO") {
      setServicePerformed(serviceSelected);
    } else setServicePerformed('')
  }
  const handleClearForm = () => {
    setCheckVinCalled(false);
    setVehicleMake('');
    setVehicleModel('');
    setVehicleYear('');
    setServicePerformed('');
    setSelectService('');
    setDriverName('');
    setShowRequestForm(false);
    setEmailInput('');
    setPhoneNumber('');
    setVinInput('');
    setContactPref('');
    setVehicleColor('');
    setVehicleLPN('');
    setVehicleOdometer('');
    setOdometerNotAvailable(false);
    setShowOdometerError(false);
    setVehicleStateRegistration('');
  }
  const handleValidate = () => {
    if(
      !nameValidate(driverName) ||
      !isValidEmail(emailInput) ||
      !(phoneNumber.length > 0) ||
      !['EMAIL', 'PHONE', 'BOTH'].includes(contactPref) ||
      !checkVinCalled ||
      vehicleMake.length === 0 ||
      vehicleModel.length === 0  ||
      vehicleYear.length === 0 ||
      servicePerformed.length === 0  ||
      !validateVIN(vinInput) ||
      (
        verifyIdentityInPerson &&
        (
          !driverStreetAddress ||
          !driverCity ||
          !driverZipCode ||
          !driverLicenseNumber ||
          !driverLicenseState ||
          !driverLicenseExpirationDate ||
          !vehicleStateRegistration ||
          !vehicleOdometer ||
          !vehicleColor ||
          !checkBoxViip ||
          !vehicleLPN
        )
      )
    ) return false;
    else return true;
  };
  const handleGetVinInfo = async () => {
    setLoadingInfoVin(true);
    const response = await checkAutoVin(vinInput);
    if(!response.error) {
      setShowAutoInputs(false);
      setVehicleMake(response.Make);
      setVehicleModel(response.Model);
      setVehicleYear(response.Year);
    } else {
      setShowAutoInputs(true);
      setVehicleMake('');
      setVehicleModel('');
      setVehicleYear('');
      toast.error(response.error, {
        containerId: 'standard',
      });
    }
    setCheckVinCalled(true);
    setLoadingInfoVin(false);
  }
  const handleSubmitQuery = async () => {
    setLoadingDriver(true);
    if(!handleValidate()) return;
    const response = await createDriverVerification(
      driverName,
      emailInput,
      phoneNumber,
      vinInput,
      contactPref,
      vehicleMake,
      vehicleModel,
      vehicleYear,
      servicePerformed,
      props.currentShop.shopID,
      {
        vehicleStateRegistration,
        vehicleOdometer,
        vehicleColor,
        vehicleLPN,
      },
      {
        driverStreetAddress,
        driverCity,
        driverZipCode,
        driverLicenseNumber,
        driverLicenseState,
        driverLicenseExpirationDate,
      },
    )
    if(response.error){
      toast.error('Error: '+ TextOnly(response.error) ?? response.error, {
        containerId: 'standard',
      })
    } else {
      setShopRequests([response, ...shopRequests])
      handleClearForm();
      toast.success(TextOnly(verifyIdentityInPerson ? 'requestCreated' : 'requestSent'), {
        containerId: 'standard',
      });
    }
    setLoadingDriver(false);
  }
  const handleUpdateVehicleOdometer = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value.trim();
    if(isNaN(newValue)) {
      setShowOdometerError(true);
      return;
    } else {
      setShowOdometerError(false);
      setVehicleOdometer(newValue);
    }
  };
  const handleUpdateOdometerNotAvailable = (event: ChangeEvent<HTMLInputElement>) => {
    setOdometerNotAvailable(event.target.checked);
    setShowOdometerError(false);
    if(event.target.checked) {
      setVehicleOdometer('N/A');
    } else {
      setVehicleOdometer('');
    }
  };

  useEffect(() => {
    const auxPend = [];
    if (!nameValidate(driverName)) {
      auxPend.push('Vehicle Owner Name');
    }

    if (!isValidEmail(emailInput)) {
      auxPend.push('Vehicle Owner Email');
    }

    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      auxPend.push('Vehicle Owner Phone Number');
    }

    if (!['EMAIL', 'PHONE', 'BOTH'].includes(contactPref)) {
      auxPend.push('Vehicle Owner Contact Preference');
    }

    if(verifyIdentityInPerson) {
      if(driverStreetAddress.length === 0) {
        auxPend.push('Vehicle Owner Street Address');
      }
      if(driverCity.length === 0) {
        auxPend.push('Vehicle Owner City');
      }
      if(driverZipCode.length === 0) {
        auxPend.push('Vehicle Owner Zip Code');
      }
      if(driverLicenseState.length === 0) {
        auxPend.push(`Vehicle Owner Driver's State`);
      }
      if(driverLicenseNumber.length === 0) {
        auxPend.push(`Driver's License Number`);
      }
      if(driverLicenseExpirationDate.length === 0) {
        auxPend.push(`Driver's License Expiration Date`);
      }
    }

    if (!validateVIN(vinInput)) {
      auxPend.push('Vehicle VIN');
    }

    if (!checkVinCalled) {
      auxPend.push('Validate Vin');
    }

    if (servicePerformed.length === 0) {
      auxPend.push('Service Performed');
    }

    if(verifyIdentityInPerson) {
      if(vehicleStateRegistration.length === 0) {
        auxPend.push('Vehicle State of Registration');
      }
      if(vehicleOdometer.length === 0) {
        auxPend.push('Vehicle Odometer');
      }
      if(vehicleColor.length === 0) {
        auxPend.push('Vehicle Color');
      }
      if(vehicleLPN.length === 0) {
        auxPend.push('Vehicle License Plate Number');
      }
      if(!checkBoxViip) auxPend.push(TextOnly('requiredViipCheck'));
    }

    setPendingValues(auxPend);
  }, [
    driverName, emailInput, contactPref, checkVinCalled, servicePerformed,
    vinInput, verifyIdentityInPerson, driverStreetAddress,
    driverCity, driverZipCode, driverLicenseNumber, driverLicenseState,
    driverLicenseExpirationDate, vehicleStateRegistration, vehicleOdometer,
    vehicleColor, vehicleLPN, checkBoxViip
  ]);

  return (
    <>
      <LoaderButton
        text={TextOnly(
          showRequestForm ? 'cancelRequest' : 'requestVerification'
        )}
        onClick={() => {
          setShowRequestForm(!showRequestForm);
        }}
        icon={showRequestForm ? undefined : <i className="c-btn__icon fa fa-plus" />}
      />
      <h1>{TextOnly('requestVerification')}</h1>
      <div
        className={`form-updown-animation ${showRequestForm ? 'show' : 'hide'} ${verifyIdentityInPerson ? 'viip' : ''} rounded-light-gray`}
      >
        <div className='u-margin-large'>
          <h1>{TextOnly('vehicleOwnerInformation')}</h1>
          <div className={`c-field`}>
            <LoaderButton
              text={TextOnly('verifyIdentityInPerson')}
              onClick={()=>setShowVIIPModal(true)}
              className={`${verifyIdentityInPerson ? 'display-none' : ''}`}
            />
            <LoaderButton
              text={TextOnly('cancelViip')}
              onClick={()=>setVerifyIdentityInPerson(false)}
              className={`c-btn-outline-bg-white ${verifyIdentityInPerson ? '' : 'display-none'}`}
            />
          </div>
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='ownerName'>
              <span className="u-text-error">*</span>{' '}
              {`${TextOnly('vehicleOwner')} - ${TextOnly('name')}`}:
            </label>
            <input
              type="text"
              id="ownerName"
              className={`c-input${ (driverName.length > 0 && !nameValidate(driverName)) ? '__error' : ''}`}
              placeholder={`${TextOnly('vehicleOwner')} ${TextOnly('name')}`}
              value={driverName}
              onChange={handleChangeDriverName}
            />
          </div>
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='email'>
              <span className="u-text-error">*</span>{' '}
              {`${TextOnly('vehicleOwner')} - ${TextOnly('email')}`}:
            </label>
            <input
              type="text"
              id="email"
              className={`c-input${ (emailInput.length > 0 && !isValidEmail(emailInput)) ? '__error' : ''}`}
              placeholder={`${TextOnly('vehicleOwner')} ${TextOnly('email')}`}
              value={emailInput}
              onChange={handleChangeEmailInput}
            />
          </div>
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='phone'>
              <span className="u-text-error">*</span>{' '}
              {`${TextOnly('vehicleOwner')} - ${TextOnly('phoneNumber')}`}:
            </label>
            {/* <BasicPhoneInput
              phoneNumber={phoneNumber}
              setPhoneNumber={handleChangePhoneNumber}
              setIsValidPhoneNumber={}
            /> */}
          </div>
          <div className="c-field">
            <label className={`c-field__label`}>
              <span className="u-text-error">*</span>{' '}
              <Text tid="contactPreference" />:
            </label>
            <div className={`c-select${ (vinInput.length > 0 && contactPref === '') ? ' error' : ''} background-white`}>
              <select value={contactPref} onChange={handleChangeContactPref}>
                <option value="" disabled>{TextOnly('chooseContactPreference')}</option>
                <option value="EMAIL">{TextOnly('email')}</option>
                <option value="PHONE">{TextOnly('phoneNumber')}</option>
                <option value="BOTH">{TextOnly('both')}</option>
              </select>
            </div>
          </div>
          { verifyIdentityInPerson &&
            <>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='streetAddress'>
                  <span className="u-text-error">*</span>{' '}
                  {`${TextOnly('vehicleOwner')} - ${TextOnly('streetAddress')}`}:
                </label>
                <input
                  type="text"
                  id="streetAddress"
                  className={`c-input`}
                  placeholder={`${TextOnly('vehicleOwner')} - ${TextOnly('streetAddress')}`}
                  value={driverStreetAddress}
                  onChange={(e)=>setDriverStreetAddress(e.target.value)}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='city'>
                  <span className="u-text-error">*</span>{' '}
                  {`${TextOnly('vehicleOwner')} - ${TextOnly('city')}`}:
                </label>
                <input
                  type="text"
                  id="city"
                  className={`c-input`}
                  placeholder={`${TextOnly('vehicleOwner')} - ${TextOnly('city')}`}
                  value={driverCity}
                  onChange={(e)=>setDriverCity(e.target.value)}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='zipCode'>
                  <span className="u-text-error">*</span>{' '}
                  {`${TextOnly('vehicleOwner')} - ${TextOnly('zipCode')}`}:
                </label>
                <input
                  type="text"
                  id="zipCode"
                  className={`c-input`}
                  placeholder={`${TextOnly('vehicleOwner')} - ${TextOnly('zipCode')}`}
                  value={driverZipCode}
                  onChange={(e)=>setDriverZipCode(e.target.value)}
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                <span className="u-text-error">*</span>{' '}
                {`${TextOnly('vehicleOwner')} - ${TextOnly('driversLicenseState')}`}:
                </label>
                <div className='c-select background-white'>
                  <select value={driverLicenseState} onChange={(e)=>setDriverLicenseState(e.target.value)}>
                    <option value="" disabled>{TextOnly('driversLicenseState')}</option>
                    {stateOptions.map((state: any)=> <option value={statesUSA[state]} key={state}>{statesUSA[state]}</option>)}
                  </select>
                </div>
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='driversLicenseNumber'>
                  <span className="u-text-error">*</span>{' '}
                  {`${TextOnly('vehicleOwner')} - ${TextOnly('driversLicenseNumber')}`}:
                </label>
                <input
                  type="text"
                  id="driversLicenseNumber"
                  className={`c-input`}
                  placeholder={`${TextOnly('vehicleOwner')} - ${TextOnly('driversLicenseNumber')}`}
                  value={driverLicenseNumber}
                  onChange={(e)=>setDriverLicenseNumber(e.target.value)}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='driversLicenseExpirationDate'>
                  <span className="u-text-error">*</span>{' '}
                  {`${TextOnly('vehicleOwner')} - ${TextOnly('driversLicenseExpirationDate')}`}:
                </label>
                <input
                  type="text"
                  id="driversLicenseExpirationDate"
                  className={`c-input`}
                  placeholder={`${TextOnly('vehicleOwner')} - ${TextOnly('driversLicenseExpirationDate')}`}
                  value={driverLicenseExpirationDate}
                  onChange={(e)=>setDriverLicenseExpirationDate(e.target.value)}
                />
              </div>
              <div className={`c-field display-flex`}>
                <label className={`c-field__label`} htmlFor='driversLicenseExpirationDate'>
                  <span className="u-text-error">*</span>&nbsp;
                </label>
                <div className="c-checkbox">
                  <input
                    type="checkbox"
                    checked={checkBoxViip}
                    onChange={()=>setCheckBoxViip(!checkBoxViip)}
                  />
                </div>
                <span>{TextOnly('viipCheckBoxDisclosure')}</span>
              </div>
            </>
          }
          <div className='u-margin-top-4'>
            <h1>{TextOnly('vehicleInformation')}</h1>
          </div>
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='vin'>
              <span className="u-text-error">*</span>{' '}
              <Text tid="vin" />:
            </label>
            <div className='display-flex'>
              <input
                type="text"
                id="vin"
                className={`c-input${ (vinInput.length > 0 && !validateVIN(vinInput)) ? '__error' : ''}`}
                placeholder={TextOnly('vin')+'...'}
                value={vinInput}
                onChange={handleChangeVinInput}
              />
            </div>
            { !checkVinCalled &&
              <div className="c-field get-vin-info">
                <LoaderButton
                  disabled={vinInput.length <= 0 || loadingInfoVin || !validateVIN(vinInput)}
                  text={TextOnly("decodeVin")}
                  isLoading={loadingInfoVin}
                  loadingText={TextOnly('loading')}
                  onClick={()=>handleGetVinInfo()}
                />
                  <div>
                    <span className='u-text-error'>* {TextOnly('validateVIN')}</span>
                  </div>
              </div>
            }
            <div className={`auto-info-table-wrapper ${(
                checkVinCalled &&
                !showAutoInputs
                )
                ? 'show' : 'hide'}`}>
              {checkVinCalled &&
                <div className='u-margin-top-large'>
                  <span className='u-text-success'>* {TextOnly('vinHasBeenVerified')}</span>
                </div>
              }
              <table className={`auto-info-table show`}
              >
                <tbody>
                  <tr>
                    <td><strong>Make:</strong></td>
                    <td>{vehicleMake}</td>
                  </tr>
                  <tr>
                    <td><strong>Model:</strong></td>
                    <td>{vehicleModel}</td>
                  </tr>
                  <tr>
                    <td><strong>Year:</strong></td>
                    <td>{vehicleYear}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={`auto-info-inputs ${(
              checkVinCalled &&
              showAutoInputs)
              ? 'show' : 'hide'}`}
            >
              <div className={`c-field`}>
                <div className='c-field__error'>{TextOnly('vinInfoNotFound')}</div>
              </div>
              {/* <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='make'>
                  <span className="u-text-error">*</span>{' '}
                  <Text tid="vehicleMake" />:
                </label>
                <input
                  type="text"
                  id="make"
                  className={`c-input`}
                  placeholder={TextOnly("vehicleMake")+'...'}
                  value={vehicleMake}
                  onChange={handleChangeVehicleMake}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='model'>
                  <span className="u-text-error">*</span>{' '}
                  <Text tid="vehicleModel" />:
                </label>
                <input
                  type="text"
                  id="model"
                  className={`c-input`}
                  placeholder={TextOnly("vehicleModel")+'...'}
                  value={vehicleModel}
                  onChange={handleChangeVehicleModel}
                />
              </div>
              <div className={`c-field`}>
                <label className={`c-field__label`} htmlFor='year'>
                  <span className="u-text-error">*</span>{' '}
                  <Text tid="vehicleYear" />:
                </label>
                <input
                  type="text"
                  id="year"
                  className={`c-input`}
                  placeholder={TextOnly("vehicleYear")+'...'}
                  value={vehicleYear}
                  onChange={handleChangeVehicleYear}
                />
              </div> */}
            </div>
            <div className={`c-field margin-bottom-0`}>
              <label className={`c-field__label`} htmlFor='servicePerformed'>
                <span className="u-text-error">*</span>{' '}
                <Text tid="servicePerformed" />:
              </label>
              <div className={`c-select${ (vinInput.length > 0 && servicePerformed.length === 0) ? ' error' : ''} background-white`}>
                <select value={selectService} onChange={handleChangeSelectServicePerformed}>
                  <option value="" disabled>{TextOnly('selectService')}</option>
                  <option value="KEYCUT">{TextOnly('keyCutFromCode')}</option>
                  <option value="IMMO">{TextOnly('immobilizerReset')}</option>
                  <option value="OTHER">{TextOnly('other')}</option>
                </select>
              </div>
              <div className={`conditional-input ${selectService === 'OTHER' ? 'show' : 'hide'}`}>
                <input
                  type="text"
                  id="servicePerformed"
                  className={`c-input${ (selectService === 'OTHER' && servicePerformed.length === 0) ? '__error' : ''}`}
                  placeholder={TextOnly("servicePerformed")+'...'}
                  value={servicePerformed}
                  onChange={(event)=>setServicePerformed(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="c-field">
            <label className="c-field__label">
              { verifyIdentityInPerson && <><span className="u-text-error">*</span>{' '}</>}
              <Text tid="vehicleColor" />:
            </label>
            <input
              type="text"
              className={`c-input`}
              placeholder={TextOnly('vehicleColor') + '...'}
              autoCapitalize="off"
              value={vehicleColor}
              onChange={(event)=> setVehicleColor(event.target.value)}
            />
          </div>
          <div className="c-field">
            <label className="c-field__label">
              { verifyIdentityInPerson && <><span className="u-text-error">*</span>{' '}</>}
              <Text tid="vehicleLPN" />:
            </label>
            <input
              type="text"
              className={`c-input`}
              placeholder={TextOnly('vehicleLPN') + '...'}
              autoCapitalize="off"
              value={vehicleLPN}
              onChange={(event)=>setVehicleLPN(event.target.value)}
            />
          </div>
          <div className="c-field">
            <label className="c-field__label">
              { verifyIdentityInPerson && <><span className="u-text-error">*</span>{' '}</>}
              <Text tid="vehicleStateRegistration" />:
            </label>
            <div className='c-select background-white'>
              <select value={vehicleStateRegistration} onChange={(event)=>setVehicleStateRegistration(event.target.value)}>
                <option value="" disabled>{TextOnly('vehicleStateRegistration')}</option>
                {stateOptions.map((state: any)=> <option value={statesUSA[state]} key={state}>{statesUSA[state]}</option>)}
              </select>
            </div>
          </div>
          <div className="c-field">
            <div className='l-display-inline-flex'>
              <label className="c-field__label">
                { verifyIdentityInPerson && <><span className="u-text-error">*</span>{' '}</>}
                <Text tid="vehicleOdometer" />:
              </label>
              <input
                type="checkbox"
                className='u-margin-left'
                checked={odometerNotAvailable}
                onChange={handleUpdateOdometerNotAvailable}
              />
              {TextOnly('notAvailable')}
            </div>
            <input
              type="text"
              className={`c-input`}
              placeholder={TextOnly('vehicleOdometer') + '...'}
              autoCapitalize="off"
              value={vehicleOdometer}
              onChange={handleUpdateVehicleOdometer}
              disabled={vehicleOdometer==='N/A'}
            />
            { showOdometerError &&
              <div className='c-field__error'>{TextOnly('odometerInputError')}</div>
            }
          </div>
          <div className="c-field">
            <LoaderButton
              type="submit"
              disabled={loadingDriver || !handleValidate()}
              text={TextOnly('sendRequest')}
              isLoading={loadingDriver}
              loadingText={TextOnly('sendingRequest')}
              onClick={()=>handleSubmitQuery()}
            />
          </div>
          {pendingValues.length > 0 &&
            <div className='u-margin-top-closer-c-field'>
              <span className='c-field__error'>
                {`* `}{`Pending Fields: ${
                pendingValues.join(', ')
              }.`}
              </span>
            </div>
          }
        </div>
      </div>
      <ConfirmModal
        showConfirmModal={showVIIPModal}
        closeModal={()=>setShowVIIPModal(false)}
        onConfirm={()=>setVerifyIdentityInPerson(true)}
        textModal={TextOnly('viipDisclosure')}
      />
    </>
  );
};

export default RequestVerification;
