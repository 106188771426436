import React from 'react';
import { Tooltip } from '@reach/tooltip';

interface HelpTooltipProps {
  label: string | JSX.Element;
  additionalStyles?: object;
  onClick?: () => void;
  children?: JSX.Element;
}

const HelpTooltip = ({
  label,
  additionalStyles,
  onClick,
  children,
}: HelpTooltipProps) => {
  return (
    <Tooltip
      label={label}
      style={{
        ...additionalStyles,
        background: '#fff',
        padding: '1.5rem',
        opacity: '1',
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(138, 148, 153, 0.2), 0 6px 20px 0 rgba(138, 148, 153, 0.19)',
        border: '1px solid #fff',
        color: '#556066',
        whiteSpace: 'normal',
        maxWidth: 'fit-content',
      }}
    >
      {children || (
        <div
          className="c-btn__inner u-margin-left u-cursor-pointer"
          style={{ width: 'fit-content' }}
          onClick={onClick && onClick}
        >
          <span className="c-btn__icon fa fa-question-circle" />
        </div>
      )}
    </Tooltip>
  );
};

export default HelpTooltip;
