import React from 'react';
import { motion } from 'framer-motion';
import { Text } from '../../components/Text';

type BarType = {
  label: string;
  value: number;
};

export type BarWidgetProps = {
  title: string;
  bars: BarType[];
  topOfRange: number;
};

export const BarWidget = ({ title, bars, topOfRange }: BarWidgetProps) => {
  const hasBars = bars?.length > 0;
  const topOfRangeToUse = hasBars ? topOfRange : 100;
  const barsToShow = hasBars ? bars : emptyBars;

  return (
    <div className={`c-bar-widget ${!hasBars ? 'c-bar-widget--empty' : ''}`}>
      <h2 className="c-bar-widget__title">{title}</h2>
      {!hasBars ? (
        <span className="c-bar-widget__empty-text">
          <Text tid="noDataYet" />
        </span>
      ) : null}
      {barsToShow.map((bar) => {
        const percent = (bar.value / topOfRangeToUse) * 100;
        const isPercentOver100 = percent > 100;
        return (
          <div
            className="c-bar-widget__bar"
            key={bar.label + title + bar.label}
          >
            <span className="c-bar-widget__label">{bar.label}</span>
            <span className="c-bar-widget__value">{bar.value}</span>
            <motion.span
              className="c-bar-widget__fill"
              initial={{ width: 0 }}
              animate={{ width: `${isPercentOver100 ? 100 : percent}%` }}
              transition={{ duration: 0.6 }}
            />
          </div>
        );
      })}
    </div>
  );
};

const emptyBars = [
  {
    label: '0',
    value: 100,
  },
  {
    label: '1',
    value: 83,
  },
  {
    label: '2',
    value: 80,
  },
  {
    label: '3',
    value: 32,
  },
  {
    label: '4',
    value: 28,
  },
];
