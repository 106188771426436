import React, { Component } from 'react';
import FileUpload from '../../components/FileUpload';
import { Text, TextOnly } from '../../components/Text';
import { CSVLink } from 'react-csv';
import {
  arrToObject,
  nameValidate,
  isValidEmail,
  isValidRole,
} from '../../libs/utils';
import { importUsersForShop } from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import ReactTable, { Column } from 'react-table';
import { Shop, User } from '../../types';

interface BulkAddUserProps {
  userShops: Shop[];
  currentShop: Shop;
  shopUsers: User[];
  windowWidth: number;
  handleShowAlert: (
    alertTitle: string,
    alertMessage: string | JSX.Element,
    showModal: boolean
  ) => void;
  handleBulkAddUsers: (newUsers: { userList: User[] }, errors: User[]) => void;
}

interface BulkAddUserState {
  userName: string;
  shopUsers: User[];
  newUserId?: unknown;
  newUserFirstName: string;
  newUserLastName: string;
  userPreviewList: User[];
  bulkImportErrors: string;
  isLoading: boolean;
  isSendingRequest: boolean;
}

export default class BulkAddUser extends Component<
  BulkAddUserProps,
  BulkAddUserState
> {
  constructor(props: BulkAddUserProps) {
    super(props);

    this.state = {
      userName: '',
      shopUsers: props.shopUsers,
      newUserId: undefined,
      newUserFirstName: '',
      newUserLastName: '',
      userPreviewList: [],
      bulkImportErrors: '',
      isLoading: false,
      isSendingRequest: false,
    };
  }

  onDrop(files: Blob[]) {
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsText = reader.result;

      let newUsersArr = arrToObject(fileAsText);

      this.setState({ userPreviewList: newUsersArr });
    };

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsText(files[0]);
  }

  handleRemoveButton(index: number) {
    let newUserList = this.state.userPreviewList;
    newUserList.splice(index, 1);
    this.setState({ userPreviewList: newUserList });
  }

  handleRemoveAll = () => {
    this.setState({
      userPreviewList: [],
    });
  };

  validateBulkForm() {
    let newUsers = this.state.userPreviewList ? this.state.userPreviewList : [];
    let newUsersValid = true;
    for (let i = 0; i < newUsers.length; i++) {
      if (
        !nameValidate(newUsers[i].firstName) ||
        !nameValidate(newUsers[i].lastName) ||
        !isValidEmail(newUsers[i].email) ||
        !isValidRole(newUsers[i].userRole)
      ) {
        newUsersValid = false;
        break;
      }
    }

    return newUsers.length > 0 && newUsersValid;
  }

  handleBulkSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let data = this.state;

    this.setState({ isLoading: true });

    let shopRec = this.props.userShops.filter((shop) => {
      return shop.shopName === this.props.currentShop.shopName;
    })[0];
    let userList = data.userPreviewList;

    let users = await importUsersForShop(shopRec.shopID, userList);
    if (users?.error) {
      this.props.handleShowAlert(TextOnly('error'), users.error, true);
    } else {
      let errors: any[] = [];

      this.props.handleBulkAddUsers(users, errors);
      let errorStr = '';
      if (errors.length > 0) {
        errorStr = 'firstName,lastName,email,userRole,errorMsg\n';
        errors.forEach((e) => {
          let statusMsg = e.statusMsg;
          let userRec = userList.filter((u) => {
            return u.email === e.email;
          })[0];
          errorStr +=
            userRec.firstName +
            ',' +
            userRec.lastName +
            ',' +
            userRec.email +
            ',' +
            userRec.userRole +
            ',' +
            statusMsg +
            '\n';
        });
        this.setState({ bulkImportErrors: errorStr });
      }
      const statusMsg = (
        <div className="c-field">
          {users.userList.length > errors.length ? (
            <div>

              {TextOnly('usersWereInvited', {'num': (users.userList.length - errors.length).toString()})}
            </div>
          ) : null}
          <div>
            {TextOnly('userInviteFailed', {'num': (errors.length).toString()})}:
          </div>
          <CSVLink filename="InviteUsersErrors.csv" data={errorStr}>
            <Text tid="clickDownloadFailed" />
          </CSVLink>
        </div>
      );
      this.props.handleShowAlert(TextOnly('bulkAddUsers'), statusMsg, true);
      this.setState({
        isSendingRequest: false,
        isLoading: false,
      });
    }
  };

  render() {
    const { windowWidth } = this.props;

    const newUsersColumnDefs: Column[] = [];

    newUsersColumnDefs.push({
      Header: <Text tid="name" />,
      id: 'name',
      accessor: (u: { firstName: string; lastName: string }) =>
        u.firstName + ' ' + u.lastName,
      headerClassName: 'hide-first',
      className: 'hide-first',
      minWidth: 200,
    });
    newUsersColumnDefs.push({
      Header: <Text tid="role" />,
      accessor: 'userRole',
      headerClassName: 'hide-second',
      className: 'hide-second',
      minWidth: 50,
    });
    newUsersColumnDefs.push({
      Header: <Text tid="email" />,
      accessor: 'email',
      minWidth: 200,
    });

    newUsersColumnDefs.push({
      Header: windowWidth > 500 ? <Text tid="actions" /> : '',
      id: 'index',
      accessor: 'index',
      minWidth: windowWidth > 500 ? 90 : 50,
      Cell: (row: { original: { index: any } }) => {
        return (
          <div>
            <button
              className="c-btn-icon"
              onClick={this.handleRemoveButton.bind(this, row.original.index)}
            >
              <div className="c-btn__inner">
                <span
                  className="c-btn__icon fal fa-times-circle"
                  title={TextOnly('removeFromList')}
                />
              </div>
            </button>
          </div>
        );
      },
      // @ts-ignore
      suppressSorting: true,
      suppressFilter: true,
    });

    return (
      <div className="c-modal__body">
        <form onSubmit={this.handleBulkSubmit.bind(this)}>
          <div className="l-container-sm">
            <div className="u-text-center">
              {this.state.userPreviewList.length > 0 ? (
                <button className="c-btn-light" onClick={this.handleRemoveAll}>
                  <Text tid="clearAllAndReupload" />
                </button>
              ) : (
                <>
                  <span>
                    <Text tid="uploadFileUsersToInvite" />{' '}
                    <i
                      className="fal fa-question-circle"
                      title={TextOnly('userBulkUploadFormat')}
                    />
                  </span>

                  <FileUpload
                    handleOnDrop={this.onDrop.bind(this)}
                    description={TextOnly('fileUpload')}
                  />

                  <div className="c-field u-margin-top-large">
                    <a
                      href="/AutoAuthImportUsersTemplate.csv"
                      download="AutoAuthImportUsersTemplate.csv"
                    >
                      <i className="c-icon fal fa-download" />{' '}
                      <Text tid="downloadTemplateCSVFile" />
                    </a>
                  </div>
                </>
              )}
              {this.state.bulkImportErrors &&
              this.state.bulkImportErrors.length > 0 ? (
                <>
                  <div>
                    <Text tid="downloadLastBulkInviteErrors" />
                  </div>
                  <CSVLink
                    filename="InviteUsersErrors.csv"
                    data={this.state.bulkImportErrors}
                  >
                    <Text tid="clickToDownload" />
                  </CSVLink>
                </>
              ) : null}
            </div>
          </div>
          {this.state.userPreviewList.length > 0 ? (
            <ReactTable
              columns={newUsersColumnDefs}
              data={this.state.userPreviewList}
              className="-highlight u-margin-top"
              showPaginationTop={true}
              previousText={TextOnly('previousPage')}
              nextText={TextOnly('nextPage')}
              pageText={TextOnly('page')}
              ofText={TextOnly('of')}
              rowsText={TextOnly('rows')}
              noDataText={TextOnly('noUsersAssociated')}
              defaultPageSize={10}
              defaultSorted={[
                {
                  id: 'serialNo',
                  desc: false,
                },
              ]}
            />
          ) : null}
          <div className="u-text-center u-margin-top-large">
            <LoaderButton
              type="submit"
              disabled={!this.validateBulkForm()}
              isLoading={this.state.isLoading}
              text={TextOnly('addUsers')}
              loadingText={TextOnly('addingUsers')}
            />
          </div>
        </form>
      </div>
    );
  }
}
