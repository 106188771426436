import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Header from '../../../components/Header';
import { TextOnly } from '../../../components/Text';
import { crmGetShopCustomers } from '../../../libs/db-lib';
import { Loading } from '../../../components/Loading';
import VehicleTable from './VehicleTable';


const ManageVehicles: React.FC<any> = (props) => {
  const { currentShop } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);

  const getAllCustomersVehicles = async () => {
    const response: any = await crmGetShopCustomers(currentShop.shopID);
    if (!response?.error) {
      const allVehiclesList: any[] = [];
      response.forEach((customer: any) => {
        customer.vehicles?.forEach((vehicle: any) => {
          allVehiclesList.push({
            ...vehicle,
            customerID: customer.customerID,
          });
        })
      });
      setCustomers(response);
      setVehicles(allVehiclesList);
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
  };

  useEffect(() => {
    const onMount = async () => {
      await getAllCustomersVehicles();
      setIsLoading(false);
    }
    onMount();
  }, []);

  return (
    <div className="u-padding-bottom">
      <Header title={TextOnly('manageVehicles')} />
      {isLoading ? (
        <Loading />
      ) : (
        <VehicleTable
          handleVehicleChange={getAllCustomersVehicles}
          allCustomers={customers}
          vehicles={vehicles}
          currentShop={currentShop}
        />
      )}
    </div>
  );
};

export default ManageVehicles;
