import React, { useState } from 'react';
import { SHOP_ROLES, SHOP_TYPES } from '../CONSTANTS';
import { User } from '../types';

type DeveloperConsoleProps = {
  updateUserInfo: (arg0: unknown) => void;
  user: User;
};

export function DeveloperConsole({
  updateUserInfo,
  user,
}: DeveloperConsoleProps) {
  const [showConsole, setShowConsole] = useState(
    window.localStorage.getItem('DEVELOPER_CONSOLE_OPEN') === 'true'
  );
  function handleSwitchShopType() { //Should we delete this component?
    switch (user?.shopType) {
      case SHOP_TYPES.ENTERPRISE:
        updateUserInfo({ ...user, shopType: SHOP_TYPES.STANDARD });
        break;

      default:
        updateUserInfo({ ...user, shopType: SHOP_TYPES.ENTERPRISE });
        break;
    }
  }
  function handleSwitchShopRole() {
    switch (user.shopRole) {
      case SHOP_ROLES.OWNER:
        updateUserInfo({ ...user, shopRole: SHOP_ROLES.TECH });
        break;
      default:
        updateUserInfo({ ...user, shopRole: SHOP_ROLES.OWNER });
        break;
    }
  }
  function handleShowConsole() {
    setShowConsole(!showConsole);
    window.localStorage.setItem('DEVELOPER_CONSOLE_OPEN', String(!showConsole));
  }
  return (
    <div
      className={`c-developer-console ${
        showConsole ? 'c-developer-console--is-active' : ''
      }`}
    >
      <i
        onClick={handleShowConsole}
        className="c-developer-console__icon fa fa-gear"
      />
      {showConsole ? (
        <>
          <p className="c-developer-console__title">Developer Console</p>
          <div className="c-developer-console__field">
            <label className="c-developer-console__label">shopType:</label>{' '}
            <button
              className="c-developer-console__button"
              onClick={handleSwitchShopType}
            >
              {user?.shopType}
            </button>
          </div>
          <div className="c-developer-console__field">
            <label className="c-developer-console__label">shopRole:</label>{' '}
            <button
              className="c-developer-console__button"
              onClick={handleSwitchShopRole}
            >
              {user?.shopRole}
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
}
