import React from 'react';
import { Text } from '../components/Text';

export default function UserFAQ() {
  return (
    <div>
      <Text tid="comingSoon" />
    </div>
  );
}
