import React, { useState, useEffect } from 'react';
import { formatDateTime } from '../../libs/utils';
import { Link } from 'react-router-dom';
import './Thread.css';
import { useHistory } from 'react-router-dom';
import { Thread, Vote } from './types';
import { featureThread } from '../../libs/db-lib';
import { toast } from 'react-toastify';
import './MessageBoard.css';
import { TextOnly } from '../../components/Text';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../../components/LoaderButton';
import { Loading } from '../../components/Loading';
import HelpTooltip from '../../components/HelpTooltip';

export const ThreadComponent = ({
  thread,
  user,
  userVote,
  userRole,
  setIsDeleting,
  currentShop,
  showBody,
} : {
  thread: Thread;
  user: any;
  userVote: Vote | undefined;
  userRole: string;
  setIsDeleting: Function;
  currentShop: any;
  showBody?: boolean;
}) => {
  const shopID = currentShop.shopID;
  const userID = user.userID;
  const history = useHistory();
  // #region State Variable
  const [isFeatured, setIsFeatured] = useState<boolean>(
    thread.featured === 'true' ?? 'false'
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showFeatModal, setShowFeatModal] = useState<boolean>(false);
  const [loadingFeat, setLoadingFeat] = useState<boolean>(false);
  const isAdmo = userRole === 'ADMIN' || userRole === 'MODERATOR';
  // #endregion
  // #region handle functions
  const handleDeleteThread = (threadID: string) => {
    setIsDeleting(true);
    history.push({
      pathname: `/messageBoard/thread/${threadID}`,
    });
  };
  const handleFeatureThread = async () => {
    if (!isAdmo) return;
    setLoadingFeat(true);
    const response = await featureThread(
      thread.threadID,
      thread.featured === 'true' ? 'false' : 'true',
      shopID,
    );
    if (!response?.error){
      setIsFeatured((prevState) => !prevState);
      setShowFeatModal(false);
    }
    else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setLoadingFeat(false);
  };
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  // #endregion

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const divThreadInfo = (
    <>
      <div className="thread-views">
        <div className="c-btn__inner padding-sides-8">
          <span className="c-btn__icon u-padding-none fal fa-eye" />
        </div>
        {thread.views}
      </div>
      <div className="thread-likes">
        <div className="c-btn__inner padding-sides-8">
          <span
            className={`c-btn__icon u-padding-none ${
              userVote?.userRating ? 'fas' : 'fal'
            } fa-thumbs-up`}
          />
        </div>
        {thread.likes}
      </div>
      <div className="thread-dislikes">
        <div className="c-btn__inner padding-sides-8">
          <span
            className={`c-btn__icon u-padding-none ${
              userVote?.userRating === false ? 'fas' : 'fal'
            } fa-thumbs-down`}
          />
        </div>
        {thread.dislikes}
      </div>
      <div className="thread-posts">
        <div className="c-btn__inner padding-sides-8">
          <span
            className="c-btn__icon u-padding-none fal fa-reply-all"
          />
        </div>
        {thread.replies}
      </div>
    </>
  );

  return (
    <div className={`c-notice small-margin-bottom padding-thread-small ${thread.role === 'ADMIN' ? 'admin' : thread.role === 'MODERATOR' ? 'moderator' : ''}`}
    >
      <div className='u-width-100-percent'>
        <div className='thread-header'>
          <div className={`thread-body-title-wrapper ${windowWidth < 650 ? 'u-width-100-percent' : ''}`}>
            <h2 className="c-notice__title no-bottom-padding">
              {isAdmo ? (
                <span
                  className={`c-btn__icon thread-pin ${
                    isFeatured ? 'fas' : 'fal'
                  } fa-thumbtack`}
                  style={{
                    cursor: isAdmo ? 'pointer' : 'auto',
                  }}
                  onClick={() => (isAdmo ? setShowFeatModal(true) : null)}
                />
              ) : isFeatured ? (
                <span
                  className={`c-btn__icon thread-pin  fas fa-thumbtack`}
                  style={{
                    cursor: isAdmo ? 'pointer' : 'auto',
                  }}
                  onClick={() => (isAdmo ? setShowFeatModal(true) : null)}
                />
              ) : null}
              { thread.locked &&
                <HelpTooltip
                  label={TextOnly('lockedThreadMessage')}
                >
                  <span
                    className={`c-btn__icon thread-lock fas fa-lock`}
                    style={{
                      cursor: isAdmo ? 'pointer' : 'auto',
                    }}
                  />
                </HelpTooltip>
              }
              <Link
                to={`/messageBoard/thread/${thread.threadID}`}
                className='content-link'
              >
                {thread.deleted ? '**'+TextOnly('contentDeleted')+'**' : thread.title}
              </Link>
              <span className='thread-title'>
                {'by'}&nbsp;
                <Link to={`/messageBoard/userName/${thread.userName}`}>
                  {(thread.role === 'ADMIN' || thread.role === 'MODERATOR') && (
                    <span
                      className={`c-btn__icon thread-role fas fa-check${
                        thread.role === 'ADMIN' ? '-double' : ''
                      }`}
                    />
                  )}
                  {thread.userName}
                </Link>
                .
                {(thread.userID === userID &&
                  !(thread.deletedBy==='BY_OWNER') ) ? (
                  <button
                    className="c-btn-icon-compact marginleft-15px"
                    onClick={() => handleDeleteThread(thread.threadID)}
                  >
                    <div className="c-btn__inner">
                      <span
                        className="c-btn__icon color-red fal fa-trash-alt"
                      />
                    </div>
                  </button>
                ) : null}
              </span>
            </h2>
            { !thread.deleted &&
              <div className='thread-body-wrapper'>
                {thread.body}
              </div>
            }
          </div>

          <div
            className="thread-info"
            style={windowWidth < 650 ? { display: 'none' } : {}}
          >
            {divThreadInfo}
          </div>
        </div>
        <div>
          <div className="thread-time-by">{formatDateTime(thread.createdOn)}</div>
          <div
            className="thread-info margintop-5px"
            style={windowWidth >= 650 ? { display: 'none' } : {}}
          >
            {divThreadInfo}
          </div>
        </div>
        {showBody===true &&
          <div>
            <div>{thread.body}</div>
          </div>
        }
      </div>
      <Dialog
        isOpen={showFeatModal}
        className="c-modal"
        aria-label="confirmDelete"
        onDismiss={() => setShowFeatModal(false)}
      >
        <h1 className="c-modal__heading">{TextOnly('confirm')}</h1>
        { !loadingFeat
          ?
            <>
              <h2 className="c-modal__heading">
                {TextOnly('confirmFeat')}
              </h2>
              <div
                className="c-modal__body display-flex-justify-content-center"
              >
                <LoaderButton
                  type="submit"
                  isLoading={false}
                  text={TextOnly('cancel')}
                  loadingText=''
                  onClick={() => setShowFeatModal(false)}
                />
                <LoaderButton
                  type="submit"
                  isLoading={false}
                  text={TextOnly('confirm')}
                  loadingText={TextOnly('loading')}
                  onClick={() => handleFeatureThread()}
                  className="confirm-del-thread"
                />
              </div>
            </>
          :
          <Loading className='loading-feat' />
        }
      </Dialog>
    </div>
  );
};
